import { apiSlice } from "../services/apiSlice";
import { TMineralOrder } from "../types/mineral_order.type";

export const mineralOrderSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    createMineralOrder: builder.mutation<TMineralOrder, Partial<TMineralOrder>>({
      query(data) {
        return {
          url: `/administrator/v1/everyday/admin_mineral_orders/tech`,
          method: "POST",
          body: data,
        };
      },
      invalidatesTags: ["TMineralOrder"],
      transformResponse: (result: { agri: TMineralOrder }) =>
        result.agri,
    }),
    updateMineralOrder: builder.mutation<TMineralOrder, Partial<TMineralOrder>& Pick<TMineralOrder, 'id'>>({
      query: ({ id, ...patch }) => ({
        url: `/administrator/v1/everyday/admin_mineral_orders/tech/${id}/`,
        method: "PATCH",
        body: patch,
      }),
      invalidatesTags: ["TMineralOrder"],
      transformResponse: (response: { agri: TMineralOrder }) =>
        response.agri,
    }),
    getMineralOrder: builder.query<TMineralOrder, number>({
      query: (id) => ({
        url: `/administrator/v1/everyday/admin_mineral_orders/tech/${id}/`,
      }),
      providesTags: ["TMineralOrder"],
    }),
    getMineralOrderList: builder.query<TMineralOrder[], any>({
      query: ({status, user}) => `/administrator/v1/everyday/admin_mineral_orders/tech/?status=${status}&supplier__id=${user}`,
      transformResponse: (res: TMineralOrder[]) =>
        res.sort((a, b) => b.id - a.id),
        providesTags: (result) =>
        result ? result.map(({ id }) => ({ type: 'TMineralOrder', id })) : ['TMineralOrder'],
      // keepUnusedDataFor: 5,
    }),
    getMineralCustomer: builder.query<TMineralOrder, any>({
      query: ({customer}) => ({
        url: `/administrator/v1/everyday/mineral_customer/${customer}/tech`,
      }),
      providesTags: ["TMineralOrder"],
    }),
    getMineralCustomers: builder.query<TMineralOrder[], any>({
      query: ({supplier, page, searchQuery}) => `/administrator/v1/everyday/admin_mineral_customers/tech/?supplier__id=${supplier}&page=${page}&search=${searchQuery}`,
      // transformResponse: (res: TMineralOrder[]) =>
      //   res.sort((a, b) => b.id - a.id),
      providesTags: ["TMineralOrder"],
      // keepUnusedDataFor: 5,
    }),
    deleteMineralOrder: builder.mutation<TMineralOrder, number>({
      query: (id) => ({
        url: `/administrator/v1/everyday/admin_mineral_orders/tech/${id}/`,
        method: "DELETE",
      }),
      invalidatesTags: ["TMineralOrder"],
    }),
  }),
});
export const {
  useCreateMineralOrderMutation,
  useDeleteMineralOrderMutation,
  useGetMineralOrderListQuery,
  useGetMineralOrderQuery,
  useUpdateMineralOrderMutation,
  useGetMineralCustomerQuery,
  useGetMineralCustomersQuery,
} = mineralOrderSlice;
