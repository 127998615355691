import React from "react";
import UserMessage from "./user_message";
import userIcon from "../../../assets/img/icons/users1.svg";
import BreadCrumbs from "../../widgets/bread_crumbs";


function Messages() {
  React.useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  });
  return (
    <div className="content">
      <BreadCrumbs
        title={"Messages"}
        subTitle={"Manage Messages"}
      />
      {/* <!-- ============================ User Dashboard ================================== --> */}
      <section className="gray pt-0 pb-5">
            <div className="col-xl-12 col-lg-12 col-md-12">
              {/* <!-- Convershion --> */}
              <div className="messages-container margin-top-0">
                <div className="messages-headline">
                  <h4>John Doe</h4>
                  <a href="#" className="message-action">
                    <i className="ti-trash"></i> Delete Conversation
                  </a>
                </div>

                <div className="messages-container-inner">
                  {/* <!-- Messages --> */}
                  <UserMessage />
                  {/* <!-- Messages / End --> */}

                  {/* <!-- Message Content --> */}
                  <div className="dash-msg-content">
                    <div className="message-plunch">
                      <div className="dash-msg-avatar">
                        <img src={userIcon} alt="" />
                      </div>
                      <div className="dash-msg-text">
                        <p>
                          Hello, Contrary to popular belief, Lorem Ipsum is not
                          simply random text. It has roots in a piece of
                          classNameical Latin
                        </p>
                      </div>
                    </div>

                    <div className="message-plunch me">
                      <div className="dash-msg-avatar">
                        <img src={userIcon} alt="" />
                      </div>
                      <div className="dash-msg-text">
                        <p>
                          looked up one of the more obscure Latin words,
                          consectetur, from a Lorem
                        </p>
                      </div>
                    </div>



                    {/* <!-- Reply Area --> */}
                    <div className="clearfix"></div>
                    <div className="message-reply">
                      <textarea
                        cols={40}
                        rows={3}
                        className="form-control with-light"
                        placeholder="Your Message"></textarea>
                      <button type="submit" className="btn btn-primary">
                        Send Message
                      </button>
                    </div>
                  </div>
                  {/* <!-- Message Content --> */}
                </div>
              </div>
              
            </div>
      </section>
      {/* <!-- ============================ User Dashboard End ================================== --> */}
    </div>
  );
}

export default Messages;
