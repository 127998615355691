import React from "react";
import Calendar from "../../calendar/calendar";
import WelcomeDisplay from "../widgets/welcome_display";
import { useAppSelector } from "../../../hooks/redux-hooks";
import moment from "moment";
import LoadingSpinner from "../../../components/widgets/spinner";
import { ErrorWidget } from "../../utils/error_widget";
import Pagination from "../../pagination/pagination_items";
import { TFuelModel, TFuelOrderModel } from "../../../redux/types/fuel_mode.type";
import { useGetFuelListQuery } from "../../../redux/slices/fuel_slice";
import { useGetFuelCustomersListQuery, useGetFuelOrderListQuery } from "../../../redux/slices/fuel_orders_slice";

const FuelHomePage: React.FC = () => {
  const user = useAppSelector((state) => state.auth.user);
  const [query, setQuery] = React.useState("")
  const [currentPage, setCurrentPage] = React.useState(1);
  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value;
    setQuery(newValue);
  }

  const {
    data: pendingOrdersList,
    isLoading,
    error,
  }: TFuelOrderModel | any = useGetFuelOrderListQuery({
    supplier: user?.id, status: "PENDING", searchQuery: query, page: currentPage
  });
  const {
    data: confirmedOrders,
    isLoading: fetchingConfirmed,
  }: TFuelOrderModel | any = useGetFuelOrderListQuery({
    supplier: user?.id, status: "CONFIRMED", searchQuery: query, page: currentPage
  });
  const { data: customers, isLoading: fetchingCustomers }: TFuelOrderModel | any =
    useGetFuelCustomersListQuery({
      company: user?.id,
    });

  const { data: fuelList, isLoading: fetchingFuel }: TFuelModel | any =
    useGetFuelListQuery({supplier: user?.id, status: "", searchQuery: query, page: currentPage});

  if (isLoading || fetchingCustomers || fetchingConfirmed || fetchingFuel) {
    return (
      <div className="text-center mt-5">
       <LoadingSpinner />
      </div>
    );
  }

  if (error) {
    return (
      <ErrorWidget error={error}/>
    );
  }

  let ticketAmount = pendingOrdersList.results.map(
    (item: TFuelOrderModel) => item.productPrice
  );

  const total_amount = ticketAmount.reduce(function (acc: number, val: number) {
    return acc + val;
  }, 0);

  let confirmedAmount = confirmedOrders.results.map(
    (item: TFuelOrderModel) => item.productPrice
  );

  const totalConirmedAmount = confirmedAmount.reduce(function (
    acc: number,
    val: number
  ) {
    return acc + val;
  },
  0);

  return (
    <div className="content">
      <center>
        <h2
          style={{ fontFamily: "monospace", fontWeight: "bolder" }}
          className="text-capitalize"
        >
          Welcome: {user?.company_name}
        </h2>
      </center>
      <WelcomeDisplay
        totalAmount={total_amount}
        reservations={pendingOrdersList.results}
        customers={[]}
        minerals={fuelList.results}
        confirmedReservations={confirmedOrders.results}
        totalConfirmed={totalConirmedAmount}
      />
      <div className="col-md-12">
        <div className="card mb-4 mb-md-0">
          {pendingOrdersList.results.length === 0 ? (
            <center>
              <div
                className="text-center alert alert-info mt-3"
                role="alert"
                style={{ width: "fit-content" }}
              >
                <h1 className="mt-3">NO PENDING SALES FOUND</h1>
              </div>
            </center>
          ) : (
            <div className="card-body table-responsive">
              <table className="table table-hover">
                <thead>
                  <tr>
                    <th>#</th>
                    <th>Product</th>
                    <th>Name</th>
                    <th>E-mail</th>
                    <th>Phone Number</th>
                    <th>Paid</th>
                    <th>Date</th>
                    <th>Status</th>
                  </tr>
                </thead>

                <tbody>
                  {pendingOrdersList.results.map((item: TFuelOrderModel, index: number) => (
                      <tr key={index}>
                        <td>{index + 1}</td>
                        <td className="text-capitalize">{item.product.fuel_name.substring(0, 12)}</td>
                        <td className="text-capitalize">
                          {item.customer.first_name} {item.customer.last_name}
                        </td>
                        <td>{item.customer.email}</td>
                        <td>{item.customer.phoneNumber}</td>
                        <td>ZMW {item.productPrice.toLocaleString()}</td>
                        <td>{moment(item.created_at).format("MMM do YYYY")}</td>
                        <td>
                          <div className="badges bg-lightred text-white text-capitalize">
                            <b>{item.status}</b>
                          </div>
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
              <Pagination
                currentPage={currentPage}
                lastPage={pendingOrdersList.total_pages}
                maxLength={7}
                setCurrentPage={setCurrentPage}
              />
            </div>
          )}
        </div>
      </div>
      <Calendar />
    </div>
  );
};

export default FuelHomePage;
