import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import IPropertyPayments from "../../../models/payments_model";
import { useUpdatePaymentMutation } from "../../../redux/slices/property/property_payments_slice";

interface IActionProps {
  payment: IPropertyPayments;
}

const PaymentActionModal: React.FC<IActionProps> = ({ payment }) => {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [updatePayment, { isLoading }] = useUpdatePaymentMutation();
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  const onSubmit = (data: any) => {
    updatePayment({ id: payment.id, ...data })
      .unwrap()
      .then(() => {
        toast.success("Payment confirmed successfully");
      }).catch((error) => {
        console.log(`CONFIRM PAYMENT ERROR ${error}`);
        toast.error(error.data["message"]);
      });
  };

  return (
    <>
    <button
     onClick={handleShow}
      className="btn btn-sm btn-primary">
      Open <i className="fa fa-ellipsis-v m-2" aria-hidden="true"></i>
    </button>
      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        centered
        keyboard={false}>
        <Modal.Header>
          <Modal.Title>Respond to Payment </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="container">
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="col-lg-12 col-md-12">
                <div className="form-group">
                  <label>Option *</label>
                  <select
                    {...register("status", {
                      required: "Select an Option",
                    })}
                    disabled={isLoading}
                    name="status"
                    id="status"
                    className="form-control">
                    <option value="">Select an Option</option>
                    <option value="CONFIRMED">Confirm Payment</option>
                    <option value="CANCELLED">Reject Payment</option>
                  </select>
                  {errors.status && (
                    <p className="text-danger" style={{ fontSize: 14 }}>
                      {errors.status.message?.toString()}
                    </p>
                  )}
                </div>
                
              </div>
              <Modal.Footer>
                <Button
                  disabled={isLoading}
                  variant="secondary"
                  onClick={handleClose}>
                  Close
                </Button>
                <Button disabled={isLoading} type="submit" variant="primary">
                  {" "}
                  {isLoading && (
                    <i className="fa fa-spinner fa-spin text-danger"></i>
                  )}{" "}
                  Submit
                </Button>
              </Modal.Footer>
            </form>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default PaymentActionModal;
