import React, { useRef } from "react";
import Filters from "../../widgets/filters";
import { useGetMineralListQuery } from "../../../../redux/slices/mineral_slice";
import { TMineral } from "../../../../redux/types/mineral.type";
import moment from "moment";
import searchIcon from "../../../../assets/img/icons/search-whites.svg";
import AddMineral from "./add_mineral";
import { useAppSelector } from "../../../../hooks/redux-hooks";
import LoadingSpinner from "../../../../components/widgets/spinner";
import Pagination from "../../../pagination/pagination_items";
import { useGetCompaniesListQuery } from "../../../../redux/slices/authApiSlice";
import { User } from "../../../../redux/types/user";
import { ErrorWidget } from "../../../utils/error_widget";

const MineralPage: React.FC = () => {
  const divRef = useRef<HTMLDivElement>(null);
  const user = useAppSelector((state) => state.auth.user);
  const [query, setQuery] = React.useState("")
  const [currentPage, setCurrentPage] = React.useState(1);
  const [supplier, setSupplier] = React.useState("")
  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value;
    setQuery(newValue);
  }

  const onChangeSupplier = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const newValue = e.target.value;
    setSupplier(newValue);
  }
  const {
    data: usersList,
    isLoading: loadingUsers,
    error: usersError,
  }: User | any = useGetCompaniesListQuery({ user_type: "MINERAL" });

  const {
    data: minerals,
    isLoading,
    error,
  }: TMineral | any = useGetMineralListQuery({supplier: supplier, status: "ACTIVE", page: currentPage, searchQuery: query});

  if (isLoading || loadingUsers) {
    return (
      <div style={{ marginTop: "20%" }} className="text-center">
        <LoadingSpinner />
      </div>
    );
  }
  return (
    <>
      <div className="content">
        <div className="page-header">
          <div className="page-title">
            <h4>Mineral List</h4>
            <h6>Manage your Minerals</h6>
          </div>
          <div className="page-btn">
            {user?.user_type === "ADMIN" ? (
              <a
                href="/zm/dashboard/pending_mineral_requests"
                className="btn btn-added"
              >
                Pending Requests <i className="fa fa-arrow-right m-2"></i>
              </a>
            
            ) : (
              <>
                <div className="row">
                  <div className="col-md-5">
                    <a
                      href="/zm/dashboard/mineral_sales"
                      className="btn btn-added"
                    >
                      <i className="fa fa-arrow-right"></i>
                      Sales
                    </a>
                  </div>
                  <div className="col-md-7">
                    <a
                      href="/zm/dashboard/mineral_categories"
                      className="btn btn-sm btn-secondary"
                    >
                      <i className="fa fa-arrow-right"></i>
                      Categories
                    </a>
                  </div>
                </div>
                <AddMineral />
              </>
            )}
          </div>
        </div>

        <div className="card">
          <div className="card-body">
            <Filters divRef={divRef} showSearch={false} query={query} onSearchChange={onChange} />

            <div className="card" id="">
                <div className="card-body pb-0">
                  <div className="row">
                    <div className="col-lg col-sm-6 col-12">
                      <div className="form-group">
                        <input type="search" className="form-control" value={query}
                          onChange={onChange} placeholder="Search products" />
                      </div>
                    </div>
                    <div className="col-lg col-sm-6 col-12">
                      <div className="form-group">
                        <select onChange={onChangeSupplier} name="supplier__id" className="select form-control">
                          <option value="">Choose Supplier</option>
                          {usersList.map((item: User, index: number)=>{
                            return <option key={index} value={item.id}>{item.company_name}</option>
                          })}
                        </select>
                      </div>
                    </div>
                    <div className="col-lg col-sm-6 col-12">
                      <div className="form-group">
                        <select className="select form-control">
                          <option>Choose Status</option>
                          <option>Inprogress</option>
                        </select>
                      </div>
                    </div>
                    <div className="col-lg-1 col-sm-6 col-12">
                      <div className="form-group">
                        <a className="btn btn-filters ms-auto">
                          <img
                            src={searchIcon}
                            alt="img"
                          />
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            {error  || usersError ? (
           <ErrorWidget error={error | usersError}/>
            ) : minerals.results.length === 0 ? (
              <div className="text-center">
                <i style={{ fontSize: "50px" }} className="ti-home"></i>
                <h2>No Data Found</h2>
              </div>
            ) : (
              <div ref={divRef} className="table-responsive">
                <table className="table datanew">
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>Product</th>
                      <th>Supplier Name</th>
                      <th>Type</th>
                      <th>Unit</th>
                      <th>Product Price</th>
                      <th>Sales</th>
                      <th>Status</th>
                      <th>Created On</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {minerals.results.map((item: TMineral, index: number) => {
                      var firstName = item.supplier.first_name.match(
                        /^([a-zA-Z])[^\s]*(.*)$/
                      );
                      var lastName = item.supplier.last_name.match(
                        /^([a-zA-Z])[^\s]*(.*)$/
                      );
                      var abbr_name = firstName![1] + " " + lastName![1];
                      return (
                        <tr key={index}>
                          <td>{index + 1}</td>
                          <td className="text-capitalize">
                            <div className="row">
                              <div className="col-md-4 mr-0 pr-0">
                                <a href="">
                                  <img
                                    className="product-imgs rounded"
                                    src={`${item.image}`}
                                    alt={item.productName}
                                  />
                                </a>
                              </div>
                              <div className="col-md-8 ml-0 pl-0">
                                <a href="">
                                  {item.productName.substring(0, 10)}
                                </a>
                              </div>
                            </div>
                          </td>
                          <td className="productimgname text-capitalize">
                            <a
                              href="javascript:void(0);"
                              className="product-imgs rounded"
                            >
                              {abbr_name}
                            </a>
                            <a href="javascript:void(0);">
                              {item.supplier.first_name}{" "}
                              {item.supplier.last_name}
                            </a>
                          </td>
                          <td className="text-capitalize">
                            {item.category.categoryName}
                          </td>
                          <td className="text-capitalize">{item.unit}</td>
                          <td>{Number(item.productPrice).toLocaleString()}</td>
                          <td>{item.mineral_order_product.length}</td>
                          <td className="text-capitalize">{item.status}</td>
                          <td>
                            {moment(item.created_at).format("Do MMMM, YYYY")}
                          </td>
                          <td>
                              <span>
                                <a className="me-3" href={`/zm/dashboard/mineral_transactions/?mineral=${item.id}`}>
                                  <i className="fa fa-arrow-right"></i>
                                </a>
                              </span>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
                <Pagination
                  currentPage={currentPage}
                  lastPage={minerals.total_pages}
                  maxLength={7}
                  setCurrentPage={setCurrentPage}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default MineralPage;
