import React from "react";
import { toast } from "react-toastify";
import httpDashboardServices from "../../services/http.cars.services";
import { DragAndDrop } from "../../../widgets/DragNdrop";
import { ICarImages } from "../../../../redux/types/car_rental.type";
import { useDeleteCarImageMutation } from "../../../../redux/slices/admin_hire_slice";
import { useAppSelector } from "../../../../hooks/redux-hooks";

interface IEdit {
  carid: any;
  images: ICarImages[];
}

const EditCarImages: React.FC<IEdit> = ({ carid, images }) => {
  const user = useAppSelector((state) => state.auth.user);
  const [files, setFiles] = React.useState<string[]>([]);
  const [isUpdating, setIsUpdating] = React.useState(false);

  const [deleteCarImage, { isLoading }] = useDeleteCarImageMutation();

  function removeFile(idx: any, image_id: number) {
    const newArr = [...files];
    newArr.splice(idx, 1);
    setFiles([]);
    setFiles(newArr);
    deleteCarImage(image_id)
      .unwrap()
      .then(() => {
        toast.success("car updated Successfully");
        const newArr = [...files];
        newArr.splice(idx, 1);
        window.location.reload();
        setFiles([]);
        setFiles(newArr);
      })
      .catch((err) => {
        console.error(err.error);
        toast.error("Failed to add:" + err.error);
      });
  }

  const uploadImages = (event: any) => {
    setIsUpdating(true);
    event.preventDefault();
    const bodyFormData = new FormData();
    files.map(async (image, index) => {
      bodyFormData.append("car", carid);
      bodyFormData.append("image", image);
      try {
        httpDashboardServices
          .uploadProductImages(bodyFormData)
          .then((response) => {
            toast.success(`Uploaded Car image ${index + 1}`);
            setIsUpdating(false);
          })
          .catch((error) => {
            console.error(error);
            setIsUpdating(false);
            // toast.error(error.message);
          });
      } catch (error) {}
    });
  };

  return (
    <>
      <div className="row">
        {images.map((file, index) => {
          return (
            <div key={index} className="col-md-2 col-lg-3 shadow-xl image-full">
              <img
                src={`${process.env.REACT_APP_API_URL}${file.image}`}
                style={{
                  width: "100%",
                  height: "140px",
                }}
                className="img-responsive rounded"
                alt="room"
              />

              <div className="card-actions justify-end mt-2">
                {user?.user_type === "ADMIN" ? null : (
                  <button
                    disabled={isLoading}
                    onClick={() => removeFile(index, file.id)}
                    className="btn btn-danger"
                  >
                    {isLoading && <i className="fa fa-refresh fa-spin" />}{" "}
                    <i className="fa fa-trash"></i>
                  </button>
                )}
              </div>
            </div>
          );
        })}
      </div>
      {user?.user_type === "ADMIN" ? null : (
        <form id="paypal_donate_form_onetime_recurring" onSubmit={uploadImages}>
          <div className="row">
            <div className="col-md-12">
              <div className="row">
                <div className="col-lg-12">
                  <div className="form-group">
                    <DragAndDrop
                      heading={"Car Image Gallery"}
                      onChange={(e: any) => {
                        if (e.target.files && e.target.files[0]) {
                          for (let i = 0; i < e.target.files["length"]; i++) {
                            setFiles((prevState: any) => [
                              ...prevState,
                              e.target.files[i],
                            ]);
                          }
                        }
                      }}
                      setFiles={setFiles}
                      files={files}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="pull-right mb-4">
              <button
                type="submit"
                className="btn btn-success"
                disabled={isUpdating || files.length === 0}
              >
                {files.length === 0
                  ? "Select Image to"
                  : isUpdating && <i className="fa fa-refresh fa-spin" />}{" "}
                Update Car Gallery
              </button>
            </div>
          </div>
        </form>
      )}
    </>
  );
};

export default EditCarImages;
