import React from "react";
import userIcon from "../../assets/img/logo.jpg";
import { useAppSelector } from "../../hooks/redux-hooks";

const DashboardAvatar: React.FC = () => {
  const user = useAppSelector((state) => state.auth.user);
  return (
    <div className="dash_user_avater">
      <center>
        <img src={user?.user_profile?.profile_picture === undefined
          ? "https://intercitytravellers.com/media/profilepic/user.png"
          : `${process.env.REACT_APP_API_URL}${user?.user_profile?.profile_picture}`} className="img-fluid avater" alt="" />
      </center>
      <h4 className="text-capitalize">
        {`${user?.first_name} ${user?.last_name}`}{" "}
      </h4>
      <span className="text-muted">
        <i className="fa fa-envelope"></i> {user?.email}
      </span>
      <br />
      {/* <div className="adgt-wriop-footer">
                {user?.phoneNumber !== undefined ? (
                  <div className="single-button d-flex align-items-center justify-content-between">
                    <button
                      type="button"
                      className="btn btn-md font--bold btn-light-primary me-2 full-width"
                    >
                      <i className="fa fa-phone me-2"></i>
                      {user?.phoneNumber}
                    </button>
                  </div>
                ) : null}
              </div> */}
    </div>
  );
};

export default DashboardAvatar;
