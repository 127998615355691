import React, { useRef } from "react";
import Filters from "../../widgets/filters";
import deleteIcon from "../../../../assets/img/icons/delete.svg";
import eyeIcon from "../../../../assets/img/icons/eye.svg";
import editIcon from "../../../../assets/img/icons/edit.svg";
import CreateAccount from "./crud/create_account";
import { useGetCompaniesListQuery } from "../../../../redux/slices/authApiSlice";
import { User } from "../../../../redux/types/user";
import moment from "moment";
import EditAccount from "./crud/edit_account";
import DeleteUserModal from "./crud/delete_user_modal";
import LoadingSpinner from "../../../../components/widgets/spinner";

const FuelCompanies: React.FC = () => {
  const divRef = useRef<HTMLDivElement>(null);
  const [show, setShow] = React.useState(false);
  const [user, setUser] = React.useState<User | any>();
  const [showDelete, setShowDelete] = React.useState(false);
  const [deleteUser, setDeleteUser] = React.useState<User | any>();
  const [query, setQuery] = React.useState("")
  const [currentPage, setCurrentPage] = React.useState(1);
  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value;
    setQuery(newValue);
  }
  // DELETE USER
  const handleCloseDelete = () => {
    setShowDelete(false);
  };

  const handleShowDelete = (user: User) => {
    setDeleteUser(user);
    setShowDelete(true);
  };

  const handleClose = () => {
    setShow(false);
  };

  const handleShow = (project: User) => {
    setUser(project);
    setShow(true);
  };

  const {
    data: usersList,
    isLoading,
    isFetching,
    error,
  }: User | any = useGetCompaniesListQuery({ user_type: "FUEL" });

  if (isLoading || isFetching) {
    return (
      <div style={{ marginTop: "20%" }} className="text-center">
        <LoadingSpinner />
      </div>
    );
  }
  return (
    <>
      <div className="content">
        <div className="page-header">
          <div className="page-title">
            <h4>Fuel Companies List</h4>
            <h6>Manage your Fuel Companiess</h6>
          </div>
          <div className="page-btn">
            <CreateAccount
              title="Add Fuel Company"
              accountType="FUEL"
            />
          </div>
        </div>

        <div className="card">
          <div className="card-body">
            <Filters divRef={divRef} query={query} onSearchChange={onChange} />

            <div className="card" id="filter_inputs">
              <div className="card-body pb-0">
                <div className="row">
                  <div className="col-lg-2 col-sm-6 col-12">
                    <div className="form-group">
                      <input type="text" placeholder="Enter Customer Code" />
                    </div>
                  </div>
                  <div className="col-lg-2 col-sm-6 col-12">
                    <div className="form-group">
                      <input type="text" placeholder="Enter Customer Name" />
                    </div>
                  </div>
                  <div className="col-lg-2 col-sm-6 col-12">
                    <div className="form-group">
                      <input type="text" placeholder="Enter Phone Number" />
                    </div>
                  </div>
                  <div className="col-lg-2 col-sm-6 col-12">
                    <div className="form-group">
                      <input type="text" placeholder="Enter Email" />
                    </div>
                  </div>
                  <div className="col-lg-1 col-sm-6 col-12 ms-auto">
                    <div className="form-group">
                      <a className="btn btn-filters ms-auto">
                        <img
                          src="assets/img/icons/search-whites.svg"
                          alt="img"
                        />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {error ? (
              <div className="alert alert-danger text-center mt-5" role="alert">
                {error.status}
              </div>
            ) : usersList.length === 0 ? (
              <div className="text-center">
                <i style={{ fontSize: "50px" }} className="ti-home"></i>
                <h2>No Data Found</h2>
              </div>
            ) : (
              <div ref={divRef} className="table-responsive">
                <table className="table datanew">
                  <thead>
                    <tr>
                      <th>
                        #
                      </th>
                      <th>Client Name</th>
                      <th>Company Name</th>
                      <th>Country</th>
                      <th>Phone</th>
                      <th>email</th>
                      <th>Date Joined</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {usersList.map((item: User, index: number) => {
                      var firstName = item.first_name.match(
                        /^([a-zA-Z])[^\s]*(.*)$/
                      );
                      var lastName = item.last_name.match(
                        /^([a-zA-Z])[^\s]*(.*)$/
                      );
                      var abbr_name = firstName![1] + " " + lastName![1];
                      return (
                        <>
                            <tr>
                              <td>{index + 1}</td>
                              <td className="productimgname">
                                <a
                                  href={`/zm/dashboard/real_estate_company_details/?company_id=${item.id}`}
                                  className="product-imgs text-uppercase rounded"
                                >
                                  {abbr_name}
                                </a>
                                <a
                                  className="text-capitalize"
                                  href={`/zm/dashboard/real_estate_company_details/?company_id=${item.id}`}
                                >
                                  {`${item.first_name} ${item.last_name}`}
                                </a>
                              </td>
                              <td className="text-capitalize">
                                {item.company_name}
                              </td>
                              <td className="text-capitalize">
                                {item.nationality}
                              </td>
                              <td>{item.phoneNumber}</td>
                              <td>{item.email}</td>
                              <td>
                                {moment(item.created_at).format("Do MMM, YYYY")}
                              </td>
                              <td>
                                <a
                                  className="me-3"
                                  onClick={() => handleShow(item)}
                                >
                                  <img src={editIcon} alt="img" />
                                </a>
                                <a className="me-3" href={`/zm/dashboard/real_estate_company_details/?company_id=${item.id}`}>
                                  <img src={eyeIcon} alt="img" />
                                </a>
                                <a
                                  className="me-3 confirm-text"
                                  onClick={() => handleShowDelete(item)}
                                >
                                  <img src={deleteIcon} alt="img" />
                                </a>
                              </td>
                            </tr>
                          <EditAccount
                            title={"Update User Account"}
                            accountType={"REALESTATE"}
                            user={user === null ? item : user}
                            show={show}
                            handleClose={handleClose}
                            setShow={setShow}
                          />
                          <DeleteUserModal
                            user={deleteUser === null ? item : deleteUser}
                            show={showDelete}
                            handleClose={handleCloseDelete}
                            setShow={setShowDelete}
                          />
                        </>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default FuelCompanies;
