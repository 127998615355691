import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { toast } from "react-toastify";
import { Spinner } from "react-bootstrap";
import { TAgriculture } from "../../../../redux/types/agriculture.type";
import { useDeleteAgricultureMutation } from "../../../../redux/slices/agriculture_slice";

type TAgroProps = {
  agro: TAgriculture;
  show: boolean;
  handleClose: () => void;
  setShow: (show: boolean) => void;
};

const DeleteAgroModal: React.FC<TAgroProps> = ({
  agro,
  show,
  handleClose,
  setShow,
}) => {
  const [
    deleteAgro, { isLoading: deleting, error: errorDeleting, isSuccess },] = useDeleteAgricultureMutation();

  const onDelete = () => {
    deleteAgro(agro.id)
      .unwrap()
      .then((res) => {
        setShow(false);
        toast.success("Deleted Successfully");
      })
      .catch((err) => {
        toast.error("An error occurred try again");
      });
  };

  return (
    <>
      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Delete</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Are you sure you want to delete{" "}
          <b className="text-capitalize">{agro?.productName}</b>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" disabled={deleting} onClick={handleClose}>
            Close
          </Button>
          <Button disabled={deleting} onClick={onDelete} variant="danger">
            {deleting && <Spinner size="sm" />} Delete
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default DeleteAgroModal;
