import React from "react";
import { useAppSelector } from "../../../../hooks/redux-hooks";
import { ICarBooking } from "../../../../redux/types/car_booking.type";
import ReservationCard from "./reservation_card";
import { useGetAdminCustomerBookingsQuery } from "../../../../redux/slices/admin_hire_slice";
import Pagination from "../../../pagination/pagination_items";
import LoadingSpinner from "../../../../components/widgets/spinner";
import { ErrorWidget } from "../../../utils/error_widget";

const CarReservation: React.FC = () => {
  const user = useAppSelector((state) => state.auth.user);
  const [status, setStatus] = React.useState("")
  const [query, setQuery] = React.useState("")
  const [currentPage, setCurrentPage] = React.useState(1);
  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value;
    setQuery(newValue);
  }

  const {
    data: bookings,
    isLoading,
    error,
  }: ICarBooking | any = useGetAdminCustomerBookingsQuery({
    company: user?.id,
    status: status,
    searchQuery: query, 
    page: currentPage,
  });

  if (isLoading) {
    return (
      <div style={{ marginTop: "10%" }} className="text-center">
      <LoadingSpinner />
    </div>
    );
  }



  return (
    <>
      <div className="content">
        <div className="page-header">
          <div className="page-title">
            <h4>Car Rentals List</h4>
            <h6>Manage your Car Rentals</h6>
          </div>
        </div>
      
      {error ?  <ErrorWidget error={error} />: bookings.results.length === 0 ? (
        <center>
          <div
            className="text-center alert alert-info custom-length"
            role="alert"
          >
            <i className="fa fa-car fa-5x"></i>
            <h1>NO RENTALS FOUND</h1>
          </div>
        </center>
      ) : (
        <>
        <div className="table-responsive mb-4">
          <table className="table datanew">
            <thead>
              <tr>
                <th>#</th>
                <th>Car</th>
                <th>Pick Up Date</th>
                <th>Customer Name</th>
                <th>Reference</th>
                <th>Status</th>
                <th>Payment</th>
                <th>Total</th>
                <th>Paid</th>
                <th className="text-center">Action</th>
              </tr>
            </thead>
            <tbody>
              {bookings.results.map((item: ICarBooking, index: number) => {
                return (
                  <ReservationCard item={item} index={index} key={index} />
                );
              })}
            </tbody>
          </table>
        </div>
        <Pagination
              currentPage={currentPage}
              lastPage={bookings.total_pages}
              maxLength={7}
              setCurrentPage={setCurrentPage}
            />
        </>
        
      )}
      </div>
    </>
  );
};

export default CarReservation;
