import React, { useRef } from "react";
import moment from "moment";
import { useGetFuelListQuery } from "../../../../redux/slices/fuel_slice";
import { TFuelModel } from "../../../../redux/types/fuel_mode.type";
import LoadingSpinner from "../../../../components/widgets/spinner";
import Pagination from "../../../pagination/pagination_items";
import { ErrorWidget } from "../../../utils/error_widget";
import Filters from "../../widgets/filters";

const FuelList: React.FC = () => {
  const divRef = useRef<HTMLDivElement>(null);
  const [query, setQuery] = React.useState("")
  const [currentPage, setCurrentPage] = React.useState(1);
  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value;
    setQuery(newValue);
  }
  const {
    data: fuelData,
    isLoading,
    error,
  }: TFuelModel | any = useGetFuelListQuery({supplier: "", status: "ACTIVE", page: currentPage, searchQuery: query});

  if (isLoading) {
    return (
      <div style={{ marginTop: "20%" }} className="text-center">
        <LoadingSpinner />
      </div>
    );
  }
  return (
    <div className="content">
      <div className="page-header">
        <div className="page-title">
          <h4>
            <b>Fuel List</b>
          </h4>
          <h6>Manage your Fuels</h6>
        </div>
        <div className="page-btn">
          <a
            href="/zm/dashboard/pending_fuel_list"
            className="btn btn-added"
          >
            Pending Requests <i className="fa fa-arrow-right m-2"></i>
          </a>
      </div>
      </div>
      <div className="card">
        <div className="row">
        <div className="col-lg-6 col-sm-6 col-12 px-3 pt-3">
          <div className="form-group">
            <input
              className="form-control"
              value={query}
              onChange={onChange}
              type="search"
              name="search_project"
              id="search_project"
              placeholder="Search..."
            />
          </div>
        </div>
        <div className="col-lg-6 col-sm-6 col-12 px-3 pt-3">
          <Filters divRef={divRef} showSearch={false} query={query} onSearchChange={onChange} />
        </div>
        </div>
        <div className="card-body">
          {error ? (
           <ErrorWidget error={error}/>
          ) : fuelData.results.length === 0 ? (
            <center className="p-3">
              <i className="fa fa-calendar-day fa-5x"></i>
              <h4>
                <b>No Data Found</b>
              </h4>
            </center>
          ) : (
            <div ref={divRef} className="table-responsive">
              <table className="table datanew">
                <thead>
                  <tr>
                    <th>#</th>
                    <th>Supplier</th>
                    <th>Name</th>
                    <th>Reference</th>
                    <th>Amount</th>
                    <th>Quantity</th>
                    <th>Sales</th>
                    <th>Status</th>
                    <th>Created On</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {fuelData.results.map((item: TFuelModel, index: number) => (
                    <tr key={index}>
                      <td>{index+1}</td>
                      <td className="text-capitalize">{item.supplier.company_name}</td>
                      <td className="text-capitalize">{item.fuel_name}</td>
                      <td>{item.product_unique_id}</td>
                      <td>ZMW {Number(item.fuel_price).toLocaleString()}/{item.unit}</td>
                      <td>{item.quantity}</td>
                      <td>{item.fuel_order_product.length}</td>
                      <td>{item.status}</td>
                      <td>{moment(item.created_at).format("Do MMMM, YYYY")}</td>
                      <td>
                        <a
                          href={`/zm/dashboard/fuel_details/?fuel_id=${item.id}`}
                        >
                          <i className="fa fa-arrow-right"></i>
                        </a>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
              <Pagination
                currentPage={currentPage}
                lastPage={fuelData.total_pages}
                maxLength={7}
                setCurrentPage={setCurrentPage}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default FuelList;
