import { apiSlice } from "../services/apiSlice";
import { TConstructionMiningOrders } from "../types/construction.type";

export const constructionOrderSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    createConstructionOrder: builder.mutation<TConstructionMiningOrders, Partial<TConstructionMiningOrders>>({
      query(data) {
        return {
          url: `/administrator/v1/everyday/admin_construction_orders/tech`,
          method: "POST",
          body: data,
        };
      },
      invalidatesTags: ["TConstructionMiningOrders"],
      transformResponse: (result: { agri: TConstructionMiningOrders }) =>
        result.agri,
    }),
    updateConstructionOrder: builder.mutation<TConstructionMiningOrders, Partial<TConstructionMiningOrders>& Pick<TConstructionMiningOrders, 'id'>>({
      query: ({ id, ...patch }) => ({
        url: `/administrator/v1/everyday/admin_construction_orders/tech/${id}/`,
        method: "PATCH",
        body: patch,
      }),
      invalidatesTags: ["TConstructionMiningOrders"],
      transformResponse: (response: { agri: TConstructionMiningOrders }) =>
        response.agri,
    }),
    getConstructionOrder: builder.query<TConstructionMiningOrders, number>({
      query: (id) => ({
        url: `/administrator/v1/everyday/admin_construction_orders/tech/${id}/`,
      }),
      providesTags: ["TConstructionMiningOrders"],
    }),
    getConstructionOrdersList: builder.query<TConstructionMiningOrders[], any>({
      query: ({supplier, status, searchQuery, page}) => `/administrator/v1/everyday/admin_construction_orders/tech/?supplier__id=${supplier}&status=${status}&search=${searchQuery}&page=${page}`,
      // transformResponse: (res: TConstructionMiningOrders[]) =>
      //   res.sort((a, b) => b.id - a.id),
      //   providesTags: (result) =>
      //   result ? result.map(({ id }) => ({ type: 'TConstructionMiningOrders', id })) : ['TConstructionMiningOrders'],
      // keepUnusedDataFor: 5,
    }),
    getConstructionCustomer: builder.query<TConstructionMiningOrders, any>({
      query: ({customer}) => ({
        url: `/administrator/v1/everyday/construction_customer/${customer}/tech`,
      }),
      providesTags: ["TConstructionMiningOrders"],
    }),
    getConstructionCustomersList: builder.query<TConstructionMiningOrders[], any>({
      query: ({supplier, page, searchQuery}) => `/administrator/v1/everyday/admin_construction_customers/tech/?supplier__id=${supplier}&page=${page}&search=${searchQuery}`,
      // transformResponse: (res: TConstructionMiningOrders[]) =>
      //   res.sort((a, b) => b.id - a.id),
      // providesTags: ["TConstructionMiningOrders"],
      // keepUnusedDataFor: 5,
    }),
    deleteConstructionOrder: builder.mutation<TConstructionMiningOrders, number>({
      query: (id) => ({
        url: `/administrator/v1/everyday/admin_construction_orders/tech/${id}/`,
        method: "DELETE",
      }),
      invalidatesTags: ["TConstructionMiningOrders"],
    }),
  }),
});
export const {
  useCreateConstructionOrderMutation,
  useDeleteConstructionOrderMutation,
  useGetConstructionCustomerQuery,
  useGetConstructionCustomersListQuery,
  useGetConstructionOrderQuery,
  useUpdateConstructionOrderMutation,
  useGetConstructionOrdersListQuery,
} = constructionOrderSlice;