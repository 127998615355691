import React from 'react';
import ReactDOM from 'react-dom/client';
import 'bootstrap/dist/css/bootstrap.min.css';
import "./assets/styles/css/bootstrap.min.css";
import "./assets/styles/css/animate.css";
import "./assets/styles/css/dataTables.bootstrap4.min.css";
import "./assets/styles/plugins/fontawesome/css/fontawesome.min.css";
import "./assets/styles/plugins/fontawesome/css/all.min.css";
import "./assets/styles/css/style.css";
import './index.css'
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Provider } from "react-redux";
import store from "./redux/store/store";

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <App />
    </Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
