import React, { useRef } from "react";
import Filters from "../../widgets/filters";
import editIcon from "../../../../assets/img/icons/edit.svg";
import deleteIcon from "../../../../assets/img/icons/delete.svg";
import plusIcon from "../../../../assets/img/icons/plus.svg";

const ExpensesList: React.FC = () => {
  const divRef = useRef<HTMLDivElement>(null);
  const [query, setQuery] = React.useState("")
  const [currentPage, setCurrentPage] = React.useState(1);
  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value;
    setQuery(newValue);
  }
  return (
    <>
      <div className="content">
        <div className="page-header">
          <div className="page-title">
            <h4>Expenses LIST</h4>
            <h6>Manage your purchases</h6>
          </div>
          <div className="page-btn">
            <button className="btn btn-added">
              <img src={plusIcon} className="me-2" alt="img" />
              Add New Expense
            </button>
          </div>
        </div>

        <div className="card">
          <div className="card-body">
            <Filters divRef={divRef} query={query} onSearchChange={onChange} />

            <div ref={divRef} className="table-responsive">
              <table className="table datanew">
                <thead>
                  <tr>
                    <th>
                      <label className="checkboxs">
                        <input type="checkbox" id="select-all" />
                        <span className="checkmarks"></span>
                      </label>
                    </th>
                    <th>Category name</th>
                    <th>Reference</th>
                    <th>Date</th>
                    <th>Status</th>
                    <th>Amount</th>
                    <th>Description</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <label className="checkboxs">
                        <input type="checkbox" />
                        <span className="checkmarks"></span>
                      </label>
                    </td>
                    <td>Foods & Snacks</td>
                    <td>PT002</td>
                    <td>19 Nov 2022</td>
                    <td>
                      <span className="badges bg-lightgreen">Active</span>
                    </td>
                    <td>250</td>
                    <td>Employee Foods</td>
                    <td>
                      <a className="me-3" href="editexpense.html">
                        <img src={editIcon} alt="img" />
                      </a>
                      <a
                        className="me-3 confirm-text"
                        href="javascript:void(0);">
                        <img src={deleteIcon} alt="img" />
                      </a>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <label className="checkboxs">
                        <input type="checkbox" />
                        <span className="checkmarks"></span>
                      </label>
                    </td>
                    <td>Entertainment</td>
                    <td>PT003</td>
                    <td>19 Nov 2022</td>
                    <td>
                      <span className="badges bg-lightred">In Active</span>
                    </td>
                    <td>120</td>
                    <td>Office Vehicle</td>
                    <td>
                      <a className="me-3" href="editexpense.html">
                        <img src={editIcon} alt="img" />
                      </a>
                      <a
                        className="me-3 confirm-text"
                        href="javascript:void(0);">
                        <img src={deleteIcon} alt="img" />
                      </a>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ExpensesList;
