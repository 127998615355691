import React from 'react'
import RecentProducts from '../dashboard/recent_products'
import { useNavigate } from 'react-router-dom';

const CarList: React.FC = () => {
    const navigate = useNavigate();
  return (
    <div className='content'>
    <div className="page-header">
          <div className="page-title">
          <button onClick={() => navigate(-1)} className="btn btn-sm btn-secondary text-white">
           <i className="fa fa-arrow-left"></i>
          </button>
            <h4>Car Rental list</h4>
            <h6>View/Search Car Rental</h6>
          </div>
          {/* {user?.user_type !== "ADMIN" ? "" : <AddMainCategory />} */}
        </div>
        <RecentProducts />
    </div>
  )
}

export default CarList