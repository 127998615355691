import { configureStore } from "@reduxjs/toolkit";
import { setupListeners } from '@reduxjs/toolkit/query'
import { apiSlice } from "../services/apiSlice";
import authReducer from "../features/authSlice";
import profileReducer from '../features/profile/profile';
import sidebarReducer from "../slices/sidebarSlice";

const store = configureStore({
  reducer: {
    auth: authReducer,
		profile: profileReducer,
    sidebar: sidebarReducer,
    [apiSlice.reducerPath]: apiSlice.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(apiSlice.middleware),
});
setupListeners(store.dispatch)

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export default store;