import React from "react";
import pdf from "../../../../../assets/img/icons/pdf.svg";
import excel from "../../../../../assets/img/icons/excel.svg";
import printer from "../../../../../assets/img/icons/printer.svg";
import LoadingSpinner from "../../../../../components/widgets/spinner";
import { useNavigate } from "react-router-dom";
import OrderActionModal from "./order_action_modal";
import { useGetAgroOrderQuery } from "../../../../../redux/slices/agro_order_slice";
import { TAgroOrder } from "../../../../../redux/types/agro_order.type";

const AgroDetails: React.FC = () => {
  const navigate = useNavigate();
  const queryParams = new URLSearchParams(window.location.search);
  const order_id = queryParams.get("order_id");
  const {
    data: details,
    isLoading: isFetching,
    error,
  }: TAgroOrder | any = useGetAgroOrderQuery(Number(order_id));

  if (isFetching) {
    return (
      <div style={{ marginTop: "20%" }} className="text-center">
        <LoadingSpinner />
      </div>
    );
  }
  if (error) {
    return (
      <div className="text-center alert alert-danger mt-5" role="alert">
        <h4>Error: {error.status} </h4>
      </div>
    );
  }
  return (
    <div className="content">
      <div className="page-header">
        <button
          onClick={() => navigate(-1)}
          className="btn btn-sm btn-secondary text-white"
        >
          <i className="fa fa-arrow-left"></i>
        </button>
        <div className="page-title">
          <h4>Agro Order Details</h4>
          <h6>Manage your Order Details</h6>
        </div>
      </div>
      <div className="card">
        <div className="card-body">
          <div className="card-sales-split">
            <h2>Sale Detail : {details.agro_order_id}</h2>
            <ul>
              <li>
                <a href="javascript:void(0);">
                  <img src={pdf} alt="img" />
                </a>
              </li>
              <li>
                <a href="javascript:void(0);">
                  <img src={excel} alt="img" />
                </a>
              </li>
              <li>
                <a href="javascript:void(0);">
                  <img src={printer} alt="img" />
                </a>
              </li>
            </ul>
          </div>
          <div
            className="invoice-box table-height"
            style={{
              maxWidth: "1600px",
              width: "100%",
              overflow: "auto",
              margin: "15px auto",
              padding: "0",
              fontSize: "14px",
              lineHeight: "24px",
              color: "#555",
            }}
          >
            <table
              cellPadding="0"
              cellSpacing="0"
              style={{
                width: "100%",
                lineHeight: "inherit",
                textAlign: "left",
              }}
            >
              <tbody>
                <tr className="top">
                  <td
                    colSpan={6}
                    style={{ padding: "5px", verticalAlign: "top" }}
                  >
                    <table
                      style={{
                        width: "100%",
                        lineHeight: "inherit",
                        textAlign: "left",
                      }}
                    >
                      <tbody>
                        <tr>
                          <td
                            style={{
                              padding: "5px",
                              verticalAlign: "top",
                              textAlign: "left",
                              paddingBottom: "20px",
                            }}
                          >
                            <span
                              style={{
                                verticalAlign: "inherit",
                                marginBottom: "25px",
                              }}
                            >
                              <span
                                style={{
                                  verticalAlign: "inherit",
                                  fontSize: "14px",
                                  color: "#7367f0",
                                  fontWeight: "600",
                                  lineHeight: "35px",
                                }}
                              >
                                Customer Info
                              </span>
                            </span>
                            <br />
                            <span style={{ verticalAlign: "inherit" }}>
                              <span
                                className="text-capitalize"
                                style={{
                                  verticalAlign: "inherit",
                                  fontSize: "14px",
                                  color: "#000",
                                  fontWeight: "400",
                                }}
                              >
                                {details.customer.first_name}{" "}
                                {details.customer.last_name}
                              </span>
                            </span>
                            <br />
                            <span style={{ verticalAlign: "inherit" }}>
                              <span
                                style={{
                                  verticalAlign: "inherit",
                                  fontSize: "14px",
                                  color: "#000",
                                  fontWeight: "400",
                                }}
                              >
                                <a
                                  className="__cf_email__"
                                  data-cfemail="3a4d5b565117535417594f494e55575f487a5f425b574a565f14595557"
                                >
                                  {details.customer.email}
                                </a>
                              </span>
                            </span>
                            <br />
                            <span style={{ verticalAlign: "inherit" }}>
                              <span
                                style={{
                                  verticalAlign: "inherit",
                                  fontSize: "14px",
                                  color: "#000",
                                  fontWeight: "400",
                                }}
                              >
                                {details.customer.phoneNumber}
                              </span>
                            </span>
                            <br />
                            <span style={{ verticalAlign: "inherit" }}>
                              <span
                                style={{
                                  verticalAlign: "inherit",
                                  fontSize: "14px",
                                  color: "#000",
                                  fontWeight: "400",
                                }}
                              >
                                N45 , Dhaka
                              </span>
                            </span>
                            <br />
                          </td>
                          <td
                            style={{
                              padding: "5px",
                              verticalAlign: "top",
                              textAlign: "left",
                              paddingBottom: "20px",
                            }}
                          >
                            <span
                              style={{
                                verticalAlign: "inherit",
                                marginBottom: "25px",
                              }}
                            >
                              <span
                                style={{
                                  verticalAlign: "inherit",
                                  fontSize: "14px",
                                  color: "#7367f0",
                                  fontWeight: "600",
                                  lineHeight: "35px",
                                }}
                              >
                                Company Info
                              </span>
                            </span>
                            <br />
                            <span style={{ verticalAlign: "inherit" }}>
                              <span
                                style={{
                                  verticalAlign: "inherit",
                                  fontSize: "14px",
                                  color: "#000",
                                  fontWeight: "400",
                                }}
                              >
                                <b className="text-capitalize">{details.supplier.company_name}</b>
                              </span>
                            </span>
                            <br />
                            <span style={{ verticalAlign: "inherit" }}>
                              <span
                                style={{
                                  verticalAlign: "inherit",
                                  fontSize: "14px",
                                  color: "#000",
                                  fontWeight: "400",
                                }}
                              >
                                <a
                                  className="__cf_email__"
                                  data-cfemail="9ffefbf2f6f1dffae7fef2eff3fab1fcf0f2"
                                >
                                  {details.supplier.email}
                                </a>
                              </span>
                            </span>
                            <br />
                            <span style={{ verticalAlign: "inherit" }}>
                              <span
                                style={{
                                  verticalAlign: "inherit",
                                  fontSize: "14px",
                                  color: "#000",
                                  fontWeight: "400",
                                }}
                              >
                                {details.supplier.phoneNumber}
                              </span>
                            </span>
                            <br />
                            <span style={{ verticalAlign: "inherit" }}>
                              <span
                                style={{
                                  verticalAlign: "inherit",
                                  fontSize: "14px",
                                  color: "#000",
                                  fontWeight: "400",
                                }}
                              >
                                3618 Abia Martin Drive
                              </span>
                            </span>
                            <br />
                          </td>
                          <td
                            style={{
                              padding: "5px",
                              verticalAlign: "top",
                              textAlign: "left",
                              paddingBottom: "20px",
                            }}
                          >
                            <span
                              style={{
                                verticalAlign: "inherit",
                                marginBottom: "25px",
                              }}
                            >
                              <span
                                style={{
                                  verticalAlign: "inherit",
                                  fontSize: "14px",
                                  color: "#7367f0",
                                  fontWeight: "600",
                                  lineHeight: "35px",
                                }}
                              >
                                Invoice Info
                              </span>
                            </span>
                            <br />
                            <span style={{ verticalAlign: "inherit" }}>
                              <span
                                style={{
                                  verticalAlign: "inherit",
                                  fontSize: "14px",
                                  color: "#000",
                                  fontWeight: "400",
                                }}
                              >
                                Reference
                              </span>
                            </span>
                            <br />
                            <span style={{ verticalAlign: "inherit" }}>
                              <span
                                style={{
                                  verticalAlign: "inherit",
                                  fontSize: "14px",
                                  color: "#000",
                                  fontWeight: "400",
                                }}
                              >
                                Payment Status
                              </span>
                            </span>
                            <br />
                            <span style={{ verticalAlign: "inherit" }}>
                              <span
                                style={{
                                  verticalAlign: "inherit",
                                  fontSize: "14px",
                                  color: "#000",
                                  fontWeight: "400",
                                }}
                              >
                                Status
                              </span>
                            </span>
                            <br />
                          </td>
                          <td
                            style={{
                              padding: "5px",
                              verticalAlign: "top",
                              textAlign: "right",
                              paddingBottom: "20px",
                            }}
                          >
                            <span
                              style={{
                                verticalAlign: "inherit",
                                marginBottom: "25px",
                              }}
                            >
                              <span
                                style={{
                                  verticalAlign: "inherit",
                                  fontSize: "14px",
                                  color: "#7367f0",
                                  fontWeight: "600",
                                  lineHeight: "35px",
                                }}
                              >
                                &nbsp;
                              </span>
                            </span>
                            <br />
                            <span style={{ verticalAlign: "inherit" }}>
                              <span
                                style={{
                                  verticalAlign: "inherit",
                                  fontSize: "14px",
                                  color: "#000",
                                  fontWeight: "400",
                                }}
                              >
                                {details.agro_order_id}
                              </span>
                            </span>
                            <br />
                            <span
                              className="badges bg-lightgreen"
                              style={{ verticalAlign: "inherit" }}
                            >
                              <span
                                style={{
                                  verticalAlign: "inherit",
                                  fontSize: "14px",
                                  color: "#fff",
                                  fontWeight: "400",
                                }}
                              >
                                Paid
                              </span>
                            </span>
                            <br />
                            <span
                              className="badges bg-lightred"
                              style={{ verticalAlign: "inherit" }}
                            >
                              <span
                                style={{
                                  verticalAlign: "inherit",
                                  fontSize: "14px",
                                  color: "#fff",
                                  fontWeight: "400",
                                }}
                              >
                                {details.status}
                              </span>
                            </span>
                            <br />
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </td>
                </tr>
                <tr className="heading" style={{ background: "#f3f2f7" }}>
                  <td
                    style={{
                      verticalAlign: "middle",
                      fontWeight: "600",
                      color: "#5e5873",
                      fontSize: "14px",
                      padding: "10px",
                    }}
                  >
                    Product Name
                  </td>
                  <td
                    style={{
                      verticalAlign: "middle",
                      fontWeight: "600",
                      color: "#5e5873",
                      fontSize: "14px",
                      padding: "10px",
                    }}
                  >
                    QTY
                  </td>
                  <td
                    style={{
                      verticalAlign: "middle",
                      fontWeight: "600",
                      color: "#5e5873",
                      fontSize: "14px",
                      padding: "10px",
                    }}
                  >
                    Price
                  </td>
                  <td
                    style={{
                      verticalAlign: "middle",
                      fontWeight: "600",
                      color: "#5e5873",
                      fontSize: "14px",
                      padding: "10px",
                    }}
                  >
                    Discount
                  </td>
                  <td
                    style={{
                      verticalAlign: "middle",
                      fontWeight: "600",
                      color: "#5e5873",
                      fontSize: "14px",
                      padding: "10px",
                    }}
                  >
                    TAX
                  </td>
                  <td
                    style={{
                      verticalAlign: "middle",
                      fontWeight: "600",
                      color: "#5e5873",
                      fontSize: "14px",
                      padding: "10px",
                    }}
                  >
                    Subtotal
                  </td>
                </tr>
                <tr
                  className="details"
                  style={{ borderBottom: "1px solid #e9ecef" }}
                >
                  <td
                    style={{
                      padding: "10px",
                      verticalAlign: "top",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <img
                      src={`${process.env.REACT_APP_API_URL}${details.product.thumbnail}`}
                      alt="img"
                      className="me-2 rounded"
                      style={{ width: "40px", height: "40px" }}
                    />
                    <b className="text-capitalize">
                      {details.product.productName}
                    </b>
                  </td>
                  <td style={{ padding: "10px", verticalAlign: "top" }}>
                    {details.quantity}/{details.unit}
                  </td>
                  <td style={{ padding: "10px", verticalAlign: "top" }}>
                    {Number(details.product.productPrice).toLocaleString()}
                  </td>
                  <td style={{ padding: "10px", verticalAlign: "top" }}>
                    0.00
                  </td>
                  <td style={{ padding: "10px", verticalAlign: "top" }}>
                    0.00
                  </td>
                  <td style={{ padding: "10px", verticalAlign: "top" }}>
                    {Number(details.productPrice).toLocaleString()}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="row">
            <div className="row">
              <div className="col-lg-6">
                <div className="total-order w-100 max-widthauto m-auto mb-4">
                  <ul>
                    <li>
                      <h4>Order Tax</h4>
                      <h5>ZMW 0.00 (0.00%)</h5>
                    </li>
                    <li>
                      <h4>Discount</h4>
                      <h5>ZMW 0.00</h5>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="total-order w-100 max-widthauto m-auto mb-4">
                  <ul>
                    <li>
                      <h4>Shipping</h4>
                      <h5>ZMW 0.00</h5>
                    </li>
                    <li className="total">
                      <h4>Grand Total</h4>
                      <h5>
                        ZMW {Number(details.productPrice).toLocaleString()}
                      </h5>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-lg-12">
              {details.status === "PENDING" ? (
                <OrderActionModal agro={details} />
              ) : null}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AgroDetails;
