import React from "react";

interface InspectionSectionProps {
  title: string;
  data: { [key: string]: string }; // Object with key-value pairs for inspection items
  
}

const InitialInspectionSection: React.FC<InspectionSectionProps> = ({ title, data }) => {
  return (
    <section>
      <h2>{title}</h2>
      <div className="table-responsive">
      <table className="table datanew">
        <thead>
          <tr>
            <th>No</th>
            <th>Description</th>
            <th>Status</th>
          </tr>
        </thead>
        <tbody>
          {Object.keys(data).map((key, index) => (
            <tr key={key}>
              <td>{index + 1}</td>
              <td>{key.replace(/_/g, " ").toUpperCase()}</td>
              <td>
                {data[key]}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      </div>
    </section>
  );
};

export default InitialInspectionSection;
