import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { toast } from "react-toastify";
import { Spinner } from "react-bootstrap";
import { useUpdateMineralOrderMutation } from "../../../../../redux/slices/mineral_order_slice";
import { TMineral } from "../../../../../redux/types/mineral.type";
import { orderStatus } from "../../../../utils/order_status";
import { useForm } from "react-hook-form";

type TMineralProps = {
  mineral: TMineral;
};

const OrderActionModal: React.FC<TMineralProps> = ({ mineral }) => {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [
    updateMineralOrder,
    { isLoading: updating, error: errorUpdating, isSuccess },
  ] = useUpdateMineralOrderMutation();

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  const onSubmit = handleSubmit(async (data) => {
    updateMineralOrder({
      id: mineral.id,
      ...data,
    })
      .unwrap()
      .then((res) => {
        reset();
        setShow(false);
        toast.success("Order Updated Successfully");
      })
      .catch((err) => {
        toast.error("Error: " + err.data["detail"]);
      });
  });

  return (
    <>
      <button onClick={handleShow} className="btn btn-submit me-2">
        Update
      </button>
      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Update</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={onSubmit}>
            <div className="form-group">
              <label htmlFor="order-status">Select Status</label>
              <select
                {...register("status", { required: true })}
                className={`form-control ${
                  errors.status ? "border-danger" : ""
                }`}
                defaultValue={mineral.status}
                name="status"
                id="status"
              >
                <option value="">Click to Select Status</option>
                {orderStatus.map((item, index) => {
                  return (
                    <option key={index} value={item}>
                      {item}
                    </option>
                  );
                })}
              </select>
              {errors.status && (
                <p className="text-danger">This field is required</p>
              )}
            </div>
            <Modal.Footer>
              <Button
                variant="secondary"
                disabled={updating}
                onClick={handleClose}
              >
                Close
              </Button>
              <Button disabled={updating} type="submit" variant="info">
                {updating && <Spinner size="sm" />} update
              </Button>
            </Modal.Footer>
          </form>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default OrderActionModal;
