import { apiSlice } from "../services/apiSlice";
import { TAgroOrder } from "../types/agro_order.type";
import { ICarBooking } from "../types/car_booking.type";
import { TInvestmentDonation } from "../types/investment_donations.type";
import { TMineralOrder } from "../types/mineral_order.type";

export const agroOrderSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    welcomeCarBooking: builder.query<ICarBooking[], void>({
      query: () => `/administrator/v1/everyday/welcome_car_booking/tech`,
      transformResponse: (res: ICarBooking[]) =>
        res.sort((a, b) => b.id - a.id),
      providesTags: ["ICarBooking"],
      // keepUnusedDataFor: 5,
    }),
    welcomeAgro: builder.query<TAgroOrder[], void>({
      query: () => `/administrator/v1/everyday/welcome_agro_orders/tech`,
      transformResponse: (res: TAgroOrder[]) =>
        res.sort((a, b) => b.id - a.id),
        providesTags: (result) =>
        result ? result.map(({ id }) => ({ type: 'TAgroOrder', id })) : ['TAgroOrder'],
      // keepUnusedDataFor: 5,
    }),
    welcomeMineralOrders: builder.query<TMineralOrder[], void>({
      query: () => `/administrator/v1/everyday/welcome_mineral_orders/tech`,
      transformResponse: (res: TMineralOrder[]) =>
        res.sort((a, b) => b.id - a.id),
        providesTags: (result) =>
        result ? result.map(({ id }) => ({ type: 'TMineralOrder', id })) : ['TMineralOrder'],
      // keepUnusedDataFor: 5,
    }),
    welcomeInvestmentDonations: builder.query<TInvestmentDonation[], void>({
      query: () => `/administrator/v1/everyday/welcome_investment_donations/tech`,
      transformResponse: (res: TInvestmentDonation[]) =>
        res.sort((a, b) => b.id - a.id),
        providesTags: (result) =>
        result ? result.map(({ id }) => ({ type: 'TInvestmentDonation', id })) : ['TInvestmentDonation'],
      // keepUnusedDataFor: 5,
    }),
  }),
});
export const {
  useWelcomeAgroQuery,
  useWelcomeCarBookingQuery,
  useWelcomeMineralOrdersQuery,
  useWelcomeInvestmentDonationsQuery,
} = agroOrderSlice;
