import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { RequestsModel } from "../../../models/requests_model";
import { useForm } from "react-hook-form";
import { useUpdateRequestMutation } from "../../../redux/slices/property/property_requests_slice";
import { toast } from "react-toastify";

interface IActionProps {
  request: RequestsModel;
}

const RequestActionModal: React.FC<IActionProps> = ({ request }) => {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [updateRequest, { isLoading }] = useUpdateRequestMutation();
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  const onSubmit = (data: any) => {
    updateRequest({ id: request.id, ...data })
      .unwrap()
      .then(() => {
        toast.success("Request updated successfully");
      })
      .catch((error) => {
        toast.error("Error " + error.data["message"]);
      });
  };

  return (
    <>
      <Button className="btn btn-sm btn-primary" onClick={handleShow}>
        Action
      </Button>

      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        centered
        keyboard={false}>
        <Modal.Header>
          <Modal.Title>Respond to Request </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="container">
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="col-lg-12 col-md-12">
                <div className="form-group">
                  <label>Option *</label>
                  <select
                    {...register("status", {
                      required: "Select an Option",
                    })}
                    disabled={isLoading}
                    name="status"
                    id="status"
                    className="form-control">
                    <option value="">Select an Option</option>
                    <option value="CONFIRMED">Confirm Request</option>
                    <option value="CANCELLED">Reject Request</option>
                  </select>
                  {errors.status && (
                    <p className="text-danger" style={{ fontSize: 14 }}>
                      {errors.status.message?.toString()}
                    </p>
                  )}
                </div>
                {request.property.propertyLabel === "rent" ? <div className="form-group">
                  <input
                    type="date"
                    {...register("montlyPaymentDate", {
                      required: "Tenant monthly payment date",
                    })}
                    disabled={isLoading}
                    name="montlyPaymentDate"
                    id="montlyPaymentDate"
                    placeholder="Tenant Rent Payment Date"
                    className="form-control"
                  />
                  {errors.montlyPaymentDate && (
                    <p className="text-danger" style={{ fontSize: 14 }}>
                      {errors.montlyPaymentDate.message?.toString()}
                    </p>
                  )}
                </div>: null}
                <div className="form-group">
                  <input
                    type="number"
                    {...register("rent_amount", {
                      required: "Rent Amount is required",
                    })}
                    disabled={isLoading}
                    name="rent_amount"
                    defaultValue={request.property.propertyPrice}
                    step="0.01"
                    id="rent_amount"
                    placeholder="House rent Amount"
                    className="form-control"
                  />
                  {errors.rent_amount && (
                    <p className="text-danger" style={{ fontSize: 14 }}>
                      {errors.rent_amount.message?.toString()}
                    </p>
                  )}
                </div>
              </div>
              <Modal.Footer>
                <Button
                  disabled={isLoading}
                  variant="secondary"
                  onClick={handleClose}>
                  Close
                </Button>
                <Button disabled={isLoading} type="submit" variant="primary">
                  {" "}
                  {isLoading && (
                    <i className="fa fa-spinner fa-spin text-danger"></i>
                  )}{" "}
                  Submit
                </Button>
              </Modal.Footer>
            </form>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default RequestActionModal;
