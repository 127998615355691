import React from "react";
import { Outlet } from "react-router-dom";
import SideBar from "../navigation/sidebar";
import TopNav from "../navigation/top_nav";

const DashboardLayout: React.FC = () => {
  return (
    <>
      <div className="main-wrapper">
        <TopNav />
        <SideBar />
        <div className="page-wrapper">
          <Outlet />
        </div>
      </div>
    </>
  );
};

export default DashboardLayout;
