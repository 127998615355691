import React from "react";
import { Link } from "react-router-dom";
import { useAppSelector } from "../../../../hooks/redux-hooks";
import LoadingSpinner from "../../../../components/widgets/spinner";
import { TMineralOrder } from "../../../../redux/types/mineral_order.type";
import { useGetMineralCustomersQuery } from "../../../../redux/slices/mineral_order_slice";
import { ErrorWidget } from "../../../utils/error_widget";
import BreadCrumbs from "../../../widgets/bread_crumbs";
import Pagination from "../../../pagination/pagination_items";

const Customers: React.FC = () => {
  const user = useAppSelector((state) => state.auth.user);
  const [query, setQuery] = React.useState("")
  const [currentPage, setCurrentPage] = React.useState(1);
  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value;
    setQuery(newValue);
  }
  const {
    data: customerList,
    isLoading,
    error,
  }: TMineralOrder | any = useGetMineralCustomersQuery({
    supplier: user?.id,
    searchQuery: query, 
    page: currentPage
  });

  if (isLoading) {
    return (
      <center>
        <LoadingSpinner />
      </center>
    );
  }

  return (
    <div className="content">
      <BreadCrumbs
        title={"Customers List"}
        subTitle={"View/Manage Customers"}
      />
      {error ? (
        <ErrorWidget error={error}/>
      ) : customerList.results.length === 0 ? (
        <center>
          <div
            className="alert alert-info text-center"
            style={{ width: "fit-content" }}
          >
            <i className="fa fa-users fa-5x"></i>
            <h1>Not Data Found</h1>
          </div>
        </center>
      ) : (
        <>
        <div className="col-lg col-sm-6 col-12">
          <div className="form-group">
            <input type="text"
             value={query}
            onChange={onChange} placeholder="Search Customers..." />
          </div>
        </div>
        <div className="row">
          {customerList.results.map((item: TMineralOrder, index: number) => {
          return (
            <div key={index} className="col-md-3 col-sm-2 col-xl-3">
              <div className="card" style={{ borderRadius: "15px" }}>
                <div className="card-body text-center">
                  <div className="mt-3 mb-4">
                    <img
                      src="https://mdbcdn.b-cdn.net/img/Photos/new-templates/bootstrap-chat/ava2-bg.webp"
                      className="rounded-circle img-fluid"
                      style={{ width: "100px" }}
                    />
                  </div>
                  <h4 className="mb-2 text-capitalize">
                    {item.customer.first_name} {item.customer.last_name}
                  </h4>
                  <p className="text-muted mb-4">{item.customer.email}</p>
                  <Link
                    to={`/zm/mineral/customer_profile/?customer_id=${item.customer.id}`}
                    className="btn btn-primary btn-rounded btn-sm"
                  >
                   <i className="fa fa-arrow-right"></i> Details
                  </Link>
                </div>
              </div>
            </div>
          );
        })}
        </div>
        <Pagination
          currentPage={currentPage}
          lastPage={customerList.total_pages}
          maxLength={7}
          setCurrentPage={setCurrentPage}
        />
        </>
        
      )}
    </div>
  );
};

export default Customers;
