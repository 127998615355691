import IPropertyPayments from "../../../models/payments_model";
import { apiSlice } from "../../services/apiSlice";


export const propertyPayment = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    submitPayment: builder.mutation<IPropertyPayments, Partial<IPropertyPayments>>({
      query(data) {
        return {
          url: `/realestate/v1/everyday/rent_property_payments/tech/`,
          method: "POST",
          body: data,
        };
      },
      invalidatesTags: ["IPropertyPayments"],
      transformResponse: (result: { branch: IPropertyPayments }) =>
        result.branch,
    }),
    updatePayment: builder.mutation<IPropertyPayments, Partial<IPropertyPayments>& Pick<IPropertyPayments, 'id'>>({
        query: ({ id, ...patch }) =>({
            url: `/realestate/v1/everyday/rent_property_payments/tech/${id}/`,
            method: "PATCH",
            body: patch,
        }),
        invalidatesTags: ["IPropertyPayments"],
        transformResponse: (response: { data: IPropertyPayments }) => response.data,
        transformErrorResponse: (
          response: { status: string | number },
        ) => response.status,
        
      }),
    getPayment: builder.query<IPropertyPayments, any>({
      query: (id) => ({
        url: `/realestate/v1/everyday/rent_property_payments/tech/${id}/`,
      }),
      providesTags: ["IPropertyPayments"],
    }),
    getPayments: builder.query<IPropertyPayments[], void>({
      query: (uid) => `/realestate/v1/everyday/rent_property_payments/tech/`,
      transformResponse: (res: IPropertyPayments[]) =>
        res.sort((a, b) => b.id - a.id),
      providesTags: ["IPropertyPayments"],
      // keepUnusedDataFor: 5,
    }),
    getPropertyPayments: builder.query<IPropertyPayments[], any>({
      query: (property) => `/realestate/v1/everyday/property_payment/${property}/tech/`,
      transformResponse: (res: IPropertyPayments[]) =>
        res.sort((a, b) => b.id - a.id),
      providesTags: ["IPropertyPayments"],
      // keepUnusedDataFor: 5,
    }),
    getApprovedPayment: builder.query<IPropertyPayments[], any>({
      query: (id) => `/realestate/v1/everyday/rented_properties/tech/${id}/`,
      providesTags: ["IPropertyPayments"],
      // keepUnusedDataFor: 5,
    }),
    deletePayment: builder.mutation<IPropertyPayments, number>({
      query: (id) => ({
        url: `/realestate/v1/everyday/rent_property_payments/tech/${id}/`,
        method: "DELETE",
      }),
      invalidatesTags: ["IPropertyPayments"],
    }),
  }),
});
export const {
  useDeletePaymentMutation,
  useGetApprovedPaymentQuery,
  useGetPropertyPaymentsQuery,
  useGetPaymentQuery,
  useGetPaymentsQuery,
  useSubmitPaymentMutation,
  useUpdatePaymentMutation
} = propertyPayment;
