import React from "react";
import { useNavigate } from "react-router-dom";

interface IProps{
    title: string;
    subTitle: string;
}

const BreadCrumbs: React.FC<IProps> = ({title, subTitle}) => {
  const navigate = useNavigate();
  return (
    <div className="page-header">
      <button
        onClick={() => navigate(-1)}
        className="btn btn-sm btn-secondary text-white"
      >
        <i className="fa fa-arrow-left"></i>
      </button>
      <div className="page-title">
        <h4>{title}</h4>
        <h6>{subTitle}</h6>
      </div>
    </div>
  );
};

export default BreadCrumbs;
