import { apiSlice } from "../services/apiSlice";
import { TInvestment } from "../types/investment.type";

export const investmentAPISlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    createInvestment: builder.mutation<TInvestment, Partial<TInvestment>>({
      query(data) {
        return {
          url: `/investments/v1/everyday/admin_investments/tech/`,
          method: "POST",
          body: data,
        };
      },
      invalidatesTags: ["TInvestment"],
      transformResponse: (result: { data: TInvestment }) =>
        result.data,
    }),
    updateInvestment: builder.mutation<TInvestment, Partial<TInvestment>& Pick<TInvestment, 'id'>>({
      query: ({ id, ...patch }) => ({
        url: `/investments/v1/everyday/admin_investments/tech/${id}/`,
        method: "PATCH",
        body: patch,
      }),
      invalidatesTags: ["TInvestment"],
      transformResponse: (response: { data: TInvestment }) =>
        response.data,
    }),
    getInvestment: builder.query<TInvestment, number>({
      query: (id) => ({
        url: `/investments/v1/everyday/admin_investments/tech/${id}/`,
      }),
      providesTags: ["TInvestment"],
    }),
    getInvestmentList: builder.query<TInvestment[], any>({
      query: ({user, status, searchQuery, page}) => `/investments/v1/everyday/admin_investments/tech/?user__id=${user}&status=${status}&search=${searchQuery}&page=${page}`,
      // transformResponse: (res: TInvestment[]) =>
      //   res.sort((a, b) => b.id - a.id),
      //   providesTags: (result) =>
      //   result ? result.map(({ id }) => ({ type: 'TInvestment', id })) : ['TInvestment'],
      // keepUnusedDataFor: 5,
    }),
    deleteInvestment: builder.mutation<TInvestment, number>({
      query: (id) => ({
        url: `/investments/v1/everyday/admin_investments/tech/${id}/`,
        method: "DELETE",
      }),
      invalidatesTags: ["TInvestment"],
    }),
  }),
});
export const {
  useCreateInvestmentMutation,
  useDeleteInvestmentMutation,
  useGetInvestmentListQuery,
  useGetInvestmentQuery,
  useUpdateInvestmentMutation,
} = investmentAPISlice;
