import React from "react";
import dash2 from "../../../../assets/img/icons/dash2.svg";
import { TAgroOrder } from "../../../../redux/types/agro_order.type";
import { TMineralOrder } from "../../../../redux/types/mineral_order.type";
import { ICarBooking } from "../../../../redux/types/car_booking.type";
import { TInvestmentDonation } from "../../../../redux/types/investment_donations.type";

interface IWelcomeDisplay {
  confirmedAgro: TAgroOrder[];
  confirmedMinerals: TMineralOrder[];
  confirmedCars: ICarBooking[];
  investmentDonations: TInvestmentDonation[],
  agroCompanies: number;
  mineralCompanies: number;
  rentalCompanies: number;
  investmentCompanies: number;
  estateCompanies: number;
  customers: number;
}

const DashboardStats: React.FC<IWelcomeDisplay> = ({
  agroCompanies,
  mineralCompanies,
  rentalCompanies,
  investmentCompanies,
  estateCompanies,
  customers,
  confirmedAgro,
  confirmedMinerals,
  confirmedCars,
  investmentDonations,
}) => {
  let confirmedAgroAmount = confirmedAgro.map(
    (item: TAgroOrder) => item.productPrice
  );

  const totalAgro = confirmedAgroAmount.reduce(function (
    acc: number,
    val: number
  ) {
    return acc + val;
  },
  0);

  // CONFIRMED MINERALS
  let confirmedAmount = confirmedMinerals.map(
    (item: TMineralOrder) => item.productPrice
  );

  const totalMinerals = confirmedAmount.reduce(function (
    acc: number,
    val: number
  ) {
    return acc + val;
  },
  0);
  // CONFIRMED CAR RENTALS
  let confirmCars = confirmedCars.map((item: ICarBooking) => item.rental_price);

  const totalCars = confirmCars.reduce(function (acc: number, val: number) {
    return acc + val;
  }, 0);

  // CONFIRMED DONATIONS
  let donations = investmentDonations.map((item: TInvestmentDonation) => item.donated_amount);

  const totalDonations = donations.reduce(function (acc: number, val: number) {
    return acc + val;
  }, 0);
  return (
    <>
      <div className="row">
        <div className="col-lg-3 col-sm-6 col-12">
          <div className="dash-widget">
            <div className="dash-widgetimg">
              <span>
                <img src={dash2} alt="img" />
              </span>
            </div>
            <div className="dash-widgetcontent">
              <h6>
                ZMW{" "}
                <span className="counters" data-count={`${totalAgro}`}>
                  {Number(totalAgro).toLocaleString()}
                </span>
              </h6>
              <h6>
                <b>Agriculture Sales</b>{" "}
                <span
                  style={{ borderRadius: "50%", backgroundColor: "orangered" }}
                  className="rounded-pill px-1 text-white"
                >
                  {confirmedAgro.length}
                </span>
              </h6>
            </div>
          </div>
        </div>
        <div className="col-lg-3 col-sm-6 col-12">
          <div className="dash-widget dash1">
            <div className="dash-widgetimg">
              <span>
                <img src={dash2} alt="img" />
              </span>
            </div>
            <div className="dash-widgetcontent">
              <h6>
                ZMW{" "}
                <span className="counters" data-count={`${totalMinerals}`}>
                  {Number(totalMinerals).toLocaleString()}
                </span>
              </h6>
              <h6>
                <b>Mineral Sales</b> <span
                  style={{ borderRadius: "50%", backgroundColor: "orangered" }}
                  className="rounded-pill px-1 text-white"
                >
                  {confirmedMinerals.length}
                </span>
              </h6>
            </div>
          </div>
        </div>
        <div className="col-lg-3 col-sm-6 col-12">
          <div className="dash-widget dash2">
            <div className="dash-widgetimg">
              <span>
                <img src={dash2} alt="img" />
              </span>
            </div>
            <div className="dash-widgetcontent">
              <h6>
                ZMW{" "}
                <span className="counters" data-count={`${totalCars}`}>
                  {Number(totalCars).toLocaleString()}
                </span>
              </h6>
              <h6>
                <b>Car Rental Sales</b> <span
                  style={{ borderRadius: "50%", backgroundColor: "orangered" }}
                  className="rounded-pill px-1 text-white"
                >
                  {confirmCars.length}
                </span>
              </h6>
            </div>
          </div>
        </div>
        <div className="col-lg-3 col-sm-6 col-12">
          <div className="dash-widget dash3">
            <div className="dash-widgetimg">
              <span>
                <img src={dash2} alt="img" />
              </span>
            </div>
            <div className="dash-widgetcontent">
              <h6>
                ZMW{" "}
                <span className="counters" data-count="0">
                  {Number(totalDonations).toLocaleString()}
                </span>
              </h6>
              <h6>
                <b>Investment Sales</b> <span
                  style={{ borderRadius: "50%", backgroundColor: "orangered" }}
                  className="rounded-pill px-1 text-white"
                >
                  {investmentDonations.length}
                </span>
              </h6>
            </div>
          </div>
        </div>
        <div className="col-lg-4 col-sm-6 col-12 d-flex">
          <div className="dash-count">
            <div className="dash-counts">
              <h4>{customers}</h4>
              <h5>Customers</h5>
            </div>
            <div className="dash-imgs">
              <i className="fa fa-users"></i>
            </div>
          </div>
        </div>
        <div className="col-lg-4 col-sm-6 col-12 d-flex">
          <div className="dash-count das1">
            <div className="dash-counts">
              <h4>{agroCompanies}</h4>
              <h5>Agro Suppliers</h5>
            </div>
            <div className="dash-imgs">
              <i className="fa fa-tractor"></i>
            </div>
          </div>
        </div>
        <div className="col-lg-4 col-sm-6 col-12 d-flex">
          <div className="dash-count das2">
            <div className="dash-counts">
              <h4>{mineralCompanies}</h4>
              <h5>Mineral Suppliers</h5>
            </div>
            <div className="dash-imgs">
              <i className="fa fa-gem"></i>
            </div>
          </div>
        </div>
        <div className="col-lg-4 col-sm-6 col-12 d-flex">
          <div className="dash-count das3">
            <div className="dash-counts">
              <h4>{rentalCompanies}</h4>
              <h5>Car Rental Companies</h5>
            </div>
            <div className="dash-imgs">
              <i className="fa fa-car"></i>
            </div>
          </div>
        </div>
        <div className="col-lg-4 col-sm-6 col-12 d-flex">
          <div className="dash-count das2">
            <div className="dash-counts">
              <h4>{investmentCompanies}</h4>
              <h5>Investment Companies</h5>
            </div>
            <div className="dash-imgs">
              <i className="fab fa-bitcoin"></i>
            </div>
          </div>
        </div>
        <div className="col-lg-4 col-sm-6 col-12 d-flex">
          <div className="dash-count das1">
            <div className="dash-counts">
              <h4>{estateCompanies}</h4>
              <h5>Real Estate Companies</h5>
            </div>
            <div className="dash-imgs">
              <i className="fa fa-home"></i>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default DashboardStats;
