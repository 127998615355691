import React from 'react'
import Filters from '../../widgets/filters'
import deleteIcon from "../../../../assets/img/icons/delete.svg";
import editIcon from "../../../../assets/img/icons/edit.svg";

const AgricultureOrdersList: React.FC = () => {
  return (
    <>
      <div className="content">
          <div className="page-header">
            <div className="page-title">
              <h4>Agriculture Customer Orders</h4>
              <h6>Manage your customer Orders</h6>
            </div>
          </div>

          <div className="card">
            <div className="card-body">
            {/* <Filters /> */}

              <div className="card" id="filter_inputs">
                <div className="card-body pb-0">
                  <div className="row">
                    <div className="col-lg col-sm-6 col-12">
                      <div className="form-group">
                        <input
                          type="text"
                          className="datetimepicker cal-icon"
                          placeholder="Choose Date" />
                      </div>
                    </div>
                    <div className="col-lg col-sm-6 col-12">
                      <div className="form-group">
                        <input type="text" placeholder="Enter Reference" />
                      </div>
                    </div>
                    <div className="col-lg col-sm-6 col-12">
                      <div className="form-group">
                        <select className="select">
                          <option>Choose Supplier</option>
                          <option>Supplier</option>
                        </select>
                      </div>
                    </div>
                    <div className="col-lg col-sm-6 col-12">
                      <div className="form-group">
                        <select className="select">
                          <option>Choose Status</option>
                          <option>Inprogress</option>
                        </select>
                      </div>
                    </div>
                    <div className="col-lg col-sm-6 col-12">
                      <div className="form-group">
                        <select className="select">
                          <option>Choose Payment Status</option>
                          <option>Payment Status</option>
                        </select>
                      </div>
                    </div>
                    <div className="col-lg-1 col-sm-6 col-12">
                      <div className="form-group">
                        <a className="btn btn-filters ms-auto"
                          ><img
                            src="assets/img/icons/search-whites.svg"
                            alt="img"
                        /></a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="table-responsive">
                <table className="table datanew">
                  <thead>
                    <tr>
                      <th>
                        #
                      </th>
                      <th>Customer Name</th>
                      <th>Reference</th>
                      <th>Date</th>
                      <th>Quantity</th>
                      <th>Grand Total</th>
                      <th>Paid</th>
                      <th>Status</th>
                      <th>Payment Status</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    
                    <tr>
                      <td>
                        1
                      </td>
                      <td className="text-bolds">Modern Automobile</td>
                      <td>PT002</td>
                      <td>19 Nov 2022</td>
                      <td>5</td>
                      <td>410</td>
                      <td>410</td>
                      <td>
                        <span className="badges bg-lightgreen">Received</span>
                      </td>
                      <td><span className="badges bg-lightgreen">Paid</span></td>
                      <td>
                        <a className="me-3" href="editpurchase.html">
                          <img src={editIcon} alt="img" />
                        </a>
                        <a className="me-3 confirm-text" href="javascript:void(0);">
                          <img src={deleteIcon} alt="img" />
                        </a>
                      </td>
                    </tr>
                    
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
    </>
  )
}

export default AgricultureOrdersList
