import React from "react";
import {
  useGetViewRequestQuery,
} from "../../../../redux/slices/car_viewing_request_slice";
import LoadingSpinner from "../../../../components/widgets/spinner";
import { ICarViewRequest } from "../../../../redux/types/car_booking.type";
import { ErrorWidget } from "../../../utils/error_widget";
import BreadCrumbs from "../../../widgets/bread_crumbs";
import ViewRequestActionModal from "../view_request_action_modal";
import moment from "moment";

const CarViewRequestDetails: React.FC = () => {
  const queryParams = new URLSearchParams(window.location.search);
  const car_id = queryParams.get("car_id");

  const {
    data: request,
    isLoading: isFetching,
    error,
  }: ICarViewRequest | any = useGetViewRequestQuery(Number(car_id));

  if (isFetching) {
    return (
      <div style={{ marginTop: "20%" }} className="text-center">
        <LoadingSpinner />
      </div>
    );
  }
  if (error) {
    return <ErrorWidget error={error} />;
  }
  return (
    <>
      <div className="content">
        <BreadCrumbs
          title={"Car Viewing Request"}
          subTitle={"View/Manage Car Viewing Request"}
        />
        <ViewRequestActionModal request={request} />
        <center>
          <h3>Car Viewing Request Details</h3>
        </center>
        <div className="row mt-5">
          <div className="col-md-8">
            <h5 className="mb-4">
              <b>Car Details</b>
            </h5>
            <div className="row">
            <div className="col-md-6">
                <img className="rounded"
                 src={`${process.env.REACT_APP_API_URL}${request.car.car_images[0].image}`} 
                 alt={request.car.car_model} />
            </div>
            <div className="col-md-6">
                <h5 className="text-capitalize">Brand: <b>{request.car.car_brand.brand_name}</b></h5>
                <h5 className="text-capitalize">Model: <b>{request.car.car_model}</b></h5>
                <h5 className="text-capitalize">Car Status: <b>{request.car.is_available === false ? "Unavailable": "Available"}</b></h5>
            </div>
            </div>
            <center>
              <h4>Requested Viewing Date: {moment(request.view_date).format("Do MMM YYYY HH:mm a")}</h4>
            </center>
          </div>
          <div className="col-md-4">
            <h5 className="mb-4">
              <b>Client Details</b>
            </h5>
            <h5 className="text-capitalize">Name: <b>{request.client.first_name} {request.client.last_name}</b></h5>
            <h5>E-mail: <b>{request.client.email}</b></h5>
            <h5>Phone Number: <b>{request.client.phoneNumber}</b></h5>
          </div>
        </div>
        <p>
          <blockquote>
            {request.car.description}
          </blockquote>
        </p>
      </div>
    </>
  );
};

export default CarViewRequestDetails;
