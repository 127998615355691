import { apiSlice } from "../services/apiSlice";
import { TAgriculture } from "../types/agriculture.type";

export const agricultureAPISlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    createAgriculture: builder.mutation<TAgriculture, Partial<TAgriculture>>({
      query(data) {
        return {
          url: `/administrator/v1/everyday/admin_agriculture/tech`,
          method: "POST",
          body: data,
        };
      },
      invalidatesTags: ["TAgriculture"],
      transformResponse: (result: { agri: TAgriculture }) =>
        result.agri,
    }),
    updateAgriculture: builder.mutation<TAgriculture, Partial<TAgriculture>& Pick<TAgriculture, 'id'>>({
      query: ({ id, ...patch }) => ({
        url: `/administrator/v1/everyday/admin_agriculture/tech/${id}/`,
        method: "PATCH",
        body: patch,
      }),
      invalidatesTags: ["TAgriculture"],
      transformResponse: (response: { agri: TAgriculture }) =>
        response.agri,
    }),
    getAgriculture: builder.query<TAgriculture, number>({
      query: (id) => ({
        url: `/administrator/v1/everyday/admin_agriculture/tech/${id}/`,
      }),
      providesTags: ["TAgriculture"],
    }),
    getAgricultureList: builder.query<TAgriculture[], any>({
      query: ({supplier, status, searchQuery, page}) => `/administrator/v1/everyday/admin_agriculture/tech/?supplier__id=${supplier}&status=${status}&search=${searchQuery}&page=${page}`,
      // transformResponse: (res: TAgriculture[]) =>
      //   res.sort((a, b) => b.id - a.id),
      //   providesTags: (result) =>
      //   result ? result.map(({ id }) => ({ type: 'TAgriculture', id })) : ['TAgriculture'],
      // keepUnusedDataFor: 5,
    }),
    deleteAgriculture: builder.mutation<TAgriculture, number>({
      query: (id) => ({
        url: `/administrator/v1/everyday/admin_agriculture/tech/${id}/`,
        method: "DELETE",
      }),
      invalidatesTags: ["TAgriculture"],
    }),
  }),
});
export const {
  useCreateAgricultureMutation,
  useDeleteAgricultureMutation,
  useGetAgricultureListQuery,
  useGetAgricultureQuery,
  useUpdateAgricultureMutation,
} = agricultureAPISlice;
