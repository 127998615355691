export // Sample Data with Sales and Profit
const monthlyData = [
  {
    name: "Jan",
    sales: 4000,
    profit: 2400,
  },
  {
    name: "Feb",
    sales: 3000,
    profit: 1398,
  },
  {
    name: "Mar",
    sales: 2000,
    profit: 980,
  },
  {
    name: "Apr",
    sales: 2780,
    profit: 3908,
  },
  {
    name: "May",
    sales: 1890,
    profit: 4800,
  },
  {
    name: "June",
    sales: 2390,
    profit: 3800,
  },
  {
    name: "July",
    sales: 3490,
    profit: 4300,
  },
  {
    name: "Aug",
    sales: 3490,
    profit: 4300,
  },
  {
    name: "Sep",
    sales: 6490,
    profit: 2300,
  },
  {
    name: "Oct",
    sales: 7490,
    profit: 5300,
  },
  {
    name: "Nov",
    sales: 4490,
    profit: 3300,
  },
  {
    name: "Dec",
    sales: 5490,
    profit: 4900,
  },
];

export const dailyData = [
  {
    name: "Mon",
    sales: 1000,
    profit: 1200,
  },
  {
    name: "Tue",
    sales: 5000,
    profit: 1800,
  },
  {
    name: "Wed",
    sales: 1200,
    profit: 1000,
  },
  {
    name: "Thur",
    sales: 4500,
    profit: 2500,
  },
  {
    name: "Fri",
    sales: 1000,
    profit: 1200,
  },
  {
    name: "Sat",
    sales: 6000,
    profit: 5800,
  },
  {
    name: "Sun",
    sales: 100,
    profit: 50,
  },
];
