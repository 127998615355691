import React from "react";
import MessageBox from "./message_box";

const Chat: React.FC = () => {
  return (
    <>
      <div className="content">
        <div className="col-lg-12">
          <div className="row chat-window">
            <div className="col-lg-5 col-xl-4 chat-cont-left">
              <div className="card mb-sm-3 mb-md-0 contacts_card flex-fill">
                <div className="card-header chat-search">
                  <div className="input-group">
                    <div className="input-group-prepend">
                      <span className="search_btn">
                        <i className="fas fa-search"></i>
                      </span>
                    </div>
                    <input
                      type="text"
                      placeholder="Search"
                      className="form-control search-chat rounded-pill"
                    />
                  </div>
                </div>
                <div className="card-body contacts_body chat-users-list chat-scroll">
                  <a href="javascript:void(0);" className="media d-flex active">
                    <div className="media-img-wrap flex-shrink-0">
                      <div className="avatar avatar-away">
                        <img
                          src={require("../../../../assets/img/customer/customer1.jpg")}
                          alt="User Image"
                          className="avatar-img rounded-circle"
                        />
                      </div>
                    </div>
                    <div className="media-body flex-grow-1">
                      <div>
                        <div className="user-name">Jeffrey Akridge</div>
                        <div className="user-last-chat">Hey, How are you?</div>
                      </div>
                      <div>
                        <div className="last-chat-time">2 min</div>
                        <div className="badge badge-success badge-pill">15</div>
                      </div>
                    </div>
                  </a>
                  <a
                    href="javascript:void(0);"
                    className="media d-flex read-chat">
                    <div className="media-img-wrap flex-shrink-0">
                      <div className="avatar avatar-online">
                        <img
                          src={require("../../../../assets/img/customer/customer2.jpg")}
                          alt="User Image"
                          className="avatar-img rounded-circle"
                        />
                      </div>
                    </div>
                    <div className="media-body flex-grow-1">
                      <div>
                        <div className="user-name">Nancy Olson</div>
                        <div className="user-last-chat">
                          I'll call you later
                        </div>
                      </div>
                      <div>
                        <div className="last-chat-time">8:01 PM</div>
                      </div>
                    </div>
                  </a>
                  <a href="javascript:void(0);" className="media d-flex">
                    <div className="media-img-wrap flex-shrink-0">
                      <div className="avatar avatar-away">
                        <img
                          src={require("../../../../assets/img/customer/customer3.jpg")}
                          alt="User Image"
                          className="avatar-img rounded-circle"
                        />
                      </div>
                    </div>
                    <div className="media-body flex-grow-1">
                      <div>
                        <div className="user-name">Ramona Kingsley</div>
                        <div className="user-last-chat">
                          Give me a full explanation about our project
                        </div>
                      </div>
                      <div>
                        <div className="last-chat-time">7:30 PM</div>
                        <div className="badge badge-success badge-pill">3</div>
                      </div>
                    </div>
                  </a>
                  
                  <a
                    href="javascript:void(0);"
                    className="media read-chat d-flex">
                    <div className="media-img-wrap flex-shrink-0">
                      <div className="avatar avatar-away">
                        <img
                          src={require("../../../../assets/img/customer/customer7.jpg")}
                          alt="User Image"
                          className="avatar-img rounded-circle"
                        />
                      </div>
                    </div>
                    <div className="media-body flex-grow-1">
                      <div>
                        <div className="user-name">Ryan Rodriguez</div>
                        <div className="user-last-chat">
                          This has allowed me to showcase not only my technical
                          skills
                        </div>
                      </div>
                      <div>
                        <div className="last-chat-time">Yesterday</div>
                      </div>
                    </div>
                  </a>
                  <a
                    href="javascript:void(0);"
                    className="media read-chat d-flex">
                    <div className="media-img-wrap flex-shrink-0">
                      <div className="avatar avatar-offline">
                        <img
                          src={require("../../../../assets/img/customer/customer8.jpg")}
                          alt="User Image"
                          className="avatar-img rounded-circle"
                        />
                      </div>
                    </div>
                    <div className="media-body flex-grow-1">
                      <div>
                        <div className="user-name">Lucile Devera</div>
                        <div className="user-last-chat">
                          Let's talk briefly in the evening.
                        </div>
                      </div>
                      <div>
                        <div className="last-chat-time">Sunday</div>
                      </div>
                    </div>
                  </a>
                  
                </div>
              </div>
            </div>

            <MessageBox />
          </div>
        </div>
      </div>
    </>
  );
};

export default Chat;
