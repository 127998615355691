import { createSlice } from "@reduxjs/toolkit";

interface SideBarState{
  isSidebarOpen: boolean
}

const initialState = {
  isSidebarOpen: false,
} as SideBarState;

const sidebarReducer = createSlice({
  name: "sidebar",
  initialState,
  reducers: {
    toggleSidebar(state) {
      state.isSidebarOpen = !state.isSidebarOpen;
    },
  },
});

export const selectIsSidebarOpen = (state: SideBarState) => state.isSidebarOpen;
export const { toggleSidebar } = sidebarReducer.actions;

export default sidebarReducer.reducer;
