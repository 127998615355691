import React, { useState } from "react";
import { PropertyModel } from "../../../../models/product_model";
import ProductDataService from "../../../services/http.product.services";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";

interface PaymentProps {
  property: PropertyModel | any;
}

function PaymentModal({ property }: PaymentProps) {
  const [activating, setActivating] = useState<boolean>(false);
  function randomString(length: any, chars: any) {
    var result = "";
    for (var i = length; i > 0; --i)
      result += chars[Math.floor(Math.random() * chars.length)];
    return result;
  }
  var orderId = randomString(
    10,
    "0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ"
  );

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  const onSubmit = (data: any) => {
    setActivating(true);
    ProductDataService.activateProperty(property)
      .then((response) => {
        toast.success("Property Activate Successfully");
        setActivating(false);
        reset();
        window.location.reload();
      })
      .catch((error) => {
        setActivating(false);
        toast.error(error);
      });
      // initialize payment
    // ProductDataService.initializePayment(data.phoneNumber, data.network, orderId).then((res) => {
    //   setActivating(false);
    //   if (res.status === 200) {
    //     toast.success(res.data.message);
    //     reset();
        
    //   } else {
    //     toast.error(res.data.message);
    //   }
    // }).catch((error) =>{
    //   setActivating(false);
    //   toast.error(`Something went wrong ${error.message}`);
    // });
    
  };
  return (
    <>
      {/* <!-- Bid Offer Modal --> */}
      <div
        className="modal fade"
        id="paymentModal"
        tabIndex={-1}
        role="dialog"
        aria-labelledby="paymentModal"
        aria-hidden="true"
      >
        <div
          className="modal-dialog modal-dialog-centered offer-pop-form"
          role="document"
        >
          <div className="modal-content" id="paymentModal">
            <span
              className="mod-close"
              data-bs-dismiss="modal"
              aria-hidden="true"
            >
              <i className="fas fa-close"></i>
            </span>
            <div className="modal-body">
              <div className="text-center">
                <h4 className="mb-3">EveryDay Tech</h4>
                <h2 className="mb-3 capitalize">
                  Activate {property?.propertyName}{" "}
                </h2>
                <small>You will pay an amount of K50 for activation</small>
              </div>
              <div className="offer-form">
                <form onSubmit={handleSubmit(onSubmit)}>
                  <div className="row">
                    <div className="col-lg-12 col-md-12">
                      <div className="form-group">
                        <label>Name</label>
                        <select
                          disabled={activating}
                          {...register("network", {
                            required: "Network is required!",
                          })}
                          name="network"
                          className={`form-control ${
                            errors.network ? "border-danger" : ""
                          }`}
                          id="network"
                        >
                          <option value="">Select Mobile Network</option>
                          <option value="Airtel Money">Airte Money</option>
                          <option value="Mtn Money">MTN Money</option>
                          <option value="Zampay">Zamtel Money</option>
                        </select>
                        {errors.network && (
                          <p className="text-danger" style={{ fontSize: 14 }}>
                            {errors.network.message?.toString()}
                          </p>
                        )}
                      </div>
                    </div>

                    <div className="col-lg-12 col-md-12">
                      <div className="form-group">
                        <label>Phone Number</label>
                        <input
                          type="text"
                          disabled={activating}
                          {...register("phoneNumber", {
                            required: "Phone Number is required!",
                          })}
                          name="phoneNumber"
                          className={`form-control ${
                            errors.phoneNumber ? "border-danger" : ""
                          }`}
                          placeholder="+260"
                        />
                      </div>
                      {errors.phoneNumber && (
                        <p className="text-danger" style={{ fontSize: 14 }}>
                          {errors.phoneNumber.message?.toString()}
                        </p>
                      )}
                    </div>
                  </div>

                  <div className="form-group">
                    <button
                      disabled={activating}
                      type="submit"
                      className="btn full-width btn-primary"
                    >
                      {activating && (
                        <span>
                          <i className="fa fa-refresh fa-spin"></i> Please Wait
                          ...
                        </span>
                      )}
                      {!activating && <span>Submit</span>}
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- End Modal --> */}
    </>
  );
}

export default PaymentModal;
