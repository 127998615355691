import React from "react";
import moment from "moment";
import editIcon from "../../../../assets/img/icons/edit.svg";
import { useGetFuelListQuery } from "../../../../redux/slices/fuel_slice";
import { TFuelModel } from "../../../../redux/types/fuel_mode.type";
import LoadingSpinner from "../../../../components/widgets/spinner";
import BreadCrumbs from "../../../widgets/bread_crumbs";
import EditFuelStatus from "./edit_fuel_status";
import { ErrorWidget } from "../../../utils/error_widget";
import Pagination from "../../../pagination/pagination_items";

const PendingFuelList: React.FC = () => {
  const [query, setQuery] = React.useState("")
  const [currentPage, setCurrentPage] = React.useState(1);
  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value;
    setQuery(newValue);
  }
  const [show, setShow] = React.useState(false);
  const [fuel, setFuel] = React.useState<TFuelModel | any>();
  const handleClose = () => {
    setShow(false);
  };

  const handleShow = (fuel: TFuelModel) => {
    setFuel(fuel);
    setShow(true);
  };
  const {
    data: fuelList,
    isLoading,
    isFetching,
    error,
  }: TFuelModel | any = useGetFuelListQuery({status: "PENDING", page: currentPage, searchQuery: query});

  if (isLoading) {
    return (
      <div style={{ marginTop: "20%" }} className="text-center">
        <LoadingSpinner />
      </div>
    );
  }
  return (
    <div className="content">
       <BreadCrumbs
        title={"Pending Fuel List"}
        subTitle={"View/Manage Pending Fuel List"}
      />
      <div className="card">
        <div className="col-lg-6 col-sm-6 col-12 px-3 pt-3">
          <div className="form-group">
            <input
              className="form-control"
              type="search"
              value={query}
              onChange={onChange}
              name="search_project"
              id="search_project"
              placeholder="Search..."
            />
          </div>
        </div>
        <div className="card-body">
          {error ? (
            <ErrorWidget error={error}/>
          ) : fuelList.results.length === 0 ? (
            <center className="p-3">
              <i className="fa fa-gas-pump fa-5x"></i>
              <h4>
                <b>No Data Found</b>
              </h4>
            </center>
          ) : (
            <div className="table-responsive">
              <table className="table datanew">
                <thead>
                  <tr>
                    <th>#</th>
                    <th>Supplier</th>
                    <th>Name</th>
                    <th>Reference</th>
                    <th>Amount</th>
                    <th>Quantity</th>
                    <th>Sales</th>
                    <th>Status</th>
                    <th>Created On</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {fuelList.results.map((item: TFuelModel, index: number) => (
                    <tr key={index}>
                      <td>{index+1}</td>
                      <td className="text-capitalize">{item.supplier.company_name}</td>
                      <td className="text-capitalize">{item.fuel_name}</td>
                      <td>{item.product_unique_id}</td>
                      <td>ZMW {Number(item.fuel_price).toLocaleString()}/{item.unit}</td>
                      <td>{item.quantity}</td>
                      <td>{item.fuel_order_product.length}</td>
                      <td>{item.status}</td>
                      <td>{moment(item.created_at).format("Do MMMM, YYYY")}</td>
                      <td>
                      <span>
                          <a className="me-3" onClick={() => handleShow(item)}>
                            <img src={editIcon} alt="edit-fuel"/>
                          </a>
                        </span>
                      </td>
                      <EditFuelStatus
                        fuel={fuel === null ? item : fuel}
                        show={show}
                        setShow={setShow}
                        handleClose={handleClose}
                      />
                    </tr>
                  ))}
                </tbody>
              </table>
              <Pagination
                currentPage={currentPage}
                lastPage={fuelList.total_pages}
                maxLength={7}
                setCurrentPage={setCurrentPage}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default PendingFuelList;
