import React, { useEffect, useState } from "react";
import { PropertyModel } from "../../../models/product_model";
import moment from "moment";
import DataService from "../../services/http.product.services";
import { useAppSelector } from "../../../hooks/redux-hooks";
import LoadingSpinner from "../../../components/widgets/spinner";

const RentedOrSoldProperty = () => {
  const user = useAppSelector((state) => state.auth.user);
  const [properties, setProperties] = React.useState<PropertyModel[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);

  const fetchProperties = async () => {
    setIsLoading(true);
    DataService.rentedSoldProperties(user?.id)
      .then((response) => {
        setProperties(response.data);
        setIsLoading(false);
      })
      .catch((error) => {
        setErrorMessage(error.message);
        setIsLoading(false);
      });
  };

  useEffect(() => {
    fetchProperties();
  }, []);
  return (
    <div className="content">
      
      <div className="row">
        <div className="col-xl-12 col-lg-12 col-md-12">
          <div className="card">
            <div className="card-body p-0">
              <div className="table-responsive">
                {isLoading ? (
                  <LoadingSpinner />
                ) : errorMessage !== null ? (
                  <div className="alert alert-danger text-center" role="alert">
                    {errorMessage}
                  </div>
                ) : properties.length === 0 ? (
                  <div className="alert alert-info">Notifications</div>
                ) : (
                  <table className="table table-lg">
                    <thead>
                      <tr>
                        <th>Name</th>
                        <th>Status</th>
                        <th>Price</th>
                        <th>Property Label</th>
                        <th>Date Created</th>
                      </tr>
                    </thead>

                    <tbody>
                      {properties.map((item, index) => {
                        return (
                          <tr key={index}>
                            <td className="capitalize">
                              <a href="#" className="font--medium">
                                <img
                                  src={`${process.env.REACT_APP_API_URL}${item.image}`}
                                  className="img-fluid rounded mr-2"
                                  width="60"
                                  alt="Avatar"
                                />
                                {item.propertyName}
                              </a>
                            </td>
                            <td>
                              <div className="d-inline-flex label text-success bg-light-success capitalize">
                                {item.status}
                              </div>
                            </td>
                            
                            <td className="prt-fgi">ZMW {Number(item.propertyPrice).toLocaleString()}</td>
                            <td>
                              <div className="d-inline-flex label text-warning bg-light-warning capitalize">
                                {`for ${item.propertyLabel}`}
                              </div>
                            </td>
                            <td>{moment(item.created_at).format("MMMM Do, YYYY")}</td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RentedOrSoldProperty;
