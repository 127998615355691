import React, { useRef } from "react";
import BreadCrumbs from "../../../widgets/bread_crumbs";
import LoadingSpinner from "../../../../components/widgets/spinner";
import moment from "moment";
import { PropertyModel } from "../../../../models/product_model";
import { useGetRealEstateListQuery } from "../../../../redux/slices/property/propertySlice";
import { ErrorWidget } from "../../../utils/error_widget";
import Pagination from "../../../pagination/pagination_items";
import Filters from "../../widgets/filters";

const RealEstateList: React.FC = () => {
  const divRef = useRef<HTMLDivElement>(null);
  const [query, setQuery] = React.useState("")
  const [currentPage, setCurrentPage] = React.useState(1);
  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value;
    setQuery(newValue);
  }
  const {
    data: properties,
    isLoading,
    isFetching,
    error,
  }: PropertyModel | any = useGetRealEstateListQuery({status: "",page: currentPage, searchQuery: query});

  if (isLoading || isFetching) {
    return (
      <div style={{ marginTop: "20%" }} className="text-center">
        <LoadingSpinner />
      </div>
    );
  }
  return (
    <div className="content">
      <BreadCrumbs
        title={"Real Estate"}
        subTitle={`View Real Estate Properties`}
      />
       <div className="row">
        <div className="col-lg-6 col-sm-6 col-12 px-3 pt-3">
          <div className="form-group">
            <input
              className="form-control"
              value={query}
              onChange={onChange}
              type="search"
              name="search_project"
              id="search_project"
              placeholder="Search..."
            />
          </div>
        </div>
        <div className="col-lg-6 col-sm-6 col-12 px-3 pt-3">
          <Filters divRef={divRef} showSearch={false} query={query} onSearchChange={onChange} />
        </div>
        </div>
      <div className="row">
              <div className="col-lg-12 col-md-12">
                <div className="dashboard_property">
                  <div className="table-responsive">
                    {isLoading ? (
                      <div className="text-center">
                        <i className="fa fa-spinner fa-spin fa-5x text-danger"></i>
                      </div>
                    ) : error ? (
                      <ErrorWidget error={error}/>
                    ) : properties.results.length === 0 ? (
                      <div className="alert alert-info text-center">
                        <i className="fa-solid fa-house-fire fa-10x"></i>
                        <h3>
                          No Property Listing Found
                        </h3>
                      </div>
                    ) : (
                      <div ref={divRef} className="table-repsonsive">
                      <table className="table table-hover">
                        <thead className="thead-dark">
                          <tr>
                            <th scope="col">Property</th>

                            <th scope="col" className="m2_hide">
                              Stats
                            </th>
                            <th scope="col" className="m2_hide">
                              Posted On
                            </th>
                            <th scope="col">Listing Status</th>
                            <th scope="col">Property Status</th>
                            <th scope="col">Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {/* <!-- tr block --> */}
                          {properties.results.map((item: PropertyModel, index: number) => {
                            return (
                              <tr key={index}>
                                <td>
                                  <div className="dash_prt_wrap">
                                    <div className="dash_prt_thumb">
                                      <img
                                        src={`${item.image}`}
                                        className="img-fluid"
                                        alt=""
                                      />
                                    </div>
                                    <div className="dash_prt_caption">
                                      <h5 className="capitalize">
                                        {item.propertyName.substring(0, 15)}{item.propertyName.length < 15 ? "" : "..."}
                                      </h5>
                                      <div className="prt_dashb_lot capitalize">
                                        {item.propertyAddress.substring(0, 20)}{item.propertyAddress.length < 15 ? "" : "..."}
                                      </div>
                                      <div className="prt_dash_rate">
                                        <span>
                                          ZMW {`${Number(item.propertyPrice).toLocaleString()}${item.propertyLabel === "rent" ? "/Month" : ""}`}
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                </td>

                                <td className="m2_hide">
                                  <div className="_leads_view">
                                    <h5 className="up">
                                      {Number(item.propertyReviews).toLocaleString()}
                                    </h5>
                                  </div>
                                  <div className="_leads_view_title">
                                    <span>Total Views</span>
                                  </div>
                                </td>
                                <td className="m2_hide">
                                  <div className="_leads_posted">
                                    <h5>
                                      {moment(item.created_at).format( "Do MMM YYYY - HH:MM a")}
                                    </h5>
                                  </div>
                                  <div className="_leads_view_title">
                                    <span>
                                      {moment(item.created_at).fromNow()}
                                    </span>
                                  </div>
                                </td>

                                <td>
                                  <div className="_leads_status text-capitalize">
                                    <span
                                      className={`text-capitalize ${item.status === "PENDING" ? "expire" : "active"}`}>
                                      {item.status}
                                    </span>
                                  </div>
                                </td>
                                <td className="text-capitalize">
                                  {item.rentedOrSold && item.propertyLabel === "rent" ? (
                                    <span>
                                      <i className="fa fa-home"></i> Property
                                      Rented
                                    </span>
                                  ) : item.rentedOrSold &&
                                    item.propertyLabel === "sale" ? (
                                    <span>
                                      <i className="fa fa-home"></i> Property
                                      Sold
                                    </span>
                                  ) : (
                                    `for ${item.propertyLabel}`
                                  )}
                                </td>
                                <td>
                                  <a href={`/zm/dashboard/real_estate_deals/?property=${item.id}`}>
                                    <i className="fa fa-arrow-right"></i>
                                  </a>
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                      <Pagination
                        currentPage={currentPage}
                        lastPage={properties.total_pages}
                        maxLength={7}
                        setCurrentPage={setCurrentPage}
                      />
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
    </div>
  );
};

export default RealEstateList;
