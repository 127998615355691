import React from 'react'
import { User } from '../../../../../redux/types/user';
import { useGetUserQuery } from '../../../../../redux/slices/usersApiSlice';
import LoadingSpinner from '../../../../../components/widgets/spinner';
import BreadCrumbs from '../../../../widgets/bread_crumbs';
import { dailyData, monthlyData } from '../../../../utils/data_charts';
import BranchCharts from '../../../reports/branch_charts';
import moment from 'moment';
import { ICarBooking } from '../../../../../redux/types/car_booking.type';
import { useGetAdminCustomerBookingsQuery } from '../../../../../redux/slices/admin_hire_slice';
import ReservationCard from '../../../../cars_dashboard/pages/transactions/reservation_card';

const CarCompanyDetails: React.FC = () => {
    const queryParams = new URLSearchParams(window.location.search);
    const company_id = queryParams.get("company_id");
  
    const {
      data: userDetails,
      isLoading: isFetching,
      error,
    }: User | any = useGetUserQuery(Number(company_id));

    const {
      data: bookings,
      isLoading,
    }: ICarBooking | any = useGetAdminCustomerBookingsQuery({
      company: company_id,
      status: "",
    });
    if (isFetching || isLoading) {
      return (
        <div style={{ marginTop: "20%" }} className="text-center">
          <LoadingSpinner />
        </div>
      );
    }
  return (
    <div className='content'>
        <BreadCrumbs title={'Company Details'} subTitle={`View ${userDetails?.company_name}`} />
        <div className="row">
        <div className="col-md-5">
            <h3>Daily Sales</h3>
            <BranchCharts width={1000} height={250} data={dailyData} />
          </div>
          <div className="col-md-7">
            <h3>Monthly Sales</h3>
            <BranchCharts width={1000} height={250} data={monthlyData} />
          </div>
        </div>
        <div className="col-md-12">
            
        <div className="card mb-5 mb-md-0">
        <h4 className='m-3 pr-2 text-capitalize'><b>Car Rentals For {userDetails?.company_name}</b></h4>
          {bookings.length === 0 ? (
            <center>
              <div
                className="text-center alert alert-info mt-3"
                role="alert"
                style={{ width: "fit-content" }}
              >
                <h1 className="mt-3">NO RENTALS FOUND</h1>
              </div>
            </center>
          ) : (
            <div className="card-body table-responsive">
              <table className="table datanew">
            <thead>
              <tr>
                <th>#</th>
                <th>Pick Up Date</th>
                <th>Customer Name</th>
                <th>Reference</th>
                <th>Status</th>
                <th>Payment</th>
                <th>Total</th>
                <th>Paid</th>
                <th className="text-center">Action</th>
              </tr>
            </thead>
            <tbody>
              {bookings.map((item: ICarBooking, index: number) => {
                return (
                  <ReservationCard item={item} index={index} key={index} />
                );
              })}
            </tbody>
          </table>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default CarCompanyDetails