import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { Spinner } from "react-bootstrap";
import { FormInput } from "../../../../components/molecules/form_input";
import { FormTextarea } from "../../../../components/molecules/form_textarea";
import { TAgriculture } from "../../../../redux/types/agriculture.type";
import { useUpdateAgricultureMutation } from "../../../../redux/slices/agriculture_slice";

export type DataFormFields = {
  supplier: string;
  productName: string;
  productPrice: string;
  thumbnail: File;
  description: string;
  category: string;
  unit: string;
  quantity: number
};

type TAgroProps = {
  agro: TAgriculture;
  show: boolean;
  handleClose: () => void;
  setShow: (show: boolean) => void;
};

const EditAgro: React.FC<TAgroProps> = ({
  agro,
  show,
  handleClose,
  setShow,
}) => {
  const [file, setFile] = useState<File | any>();

  const [updateAgriculture, { isLoading }] = useUpdateAgricultureMutation();

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  const onSubmit = handleSubmit(async (data) => {
    updateAgriculture({
      id: agro.id,
      ...data,
    })
      .unwrap()
      .then((res) => {
        reset();
        setShow(false);
        toast.success("Product Updated Successfully");
      })
      .catch((err) => {
        toast.error("Error: " + err.data["detail"]);
      });
  });

  return (
    <>
      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        centered
        scrollable
        size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title>Edit Agriculture Product</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={onSubmit} encType="multipart/form-data">
          <div className="row">
              <div className="col-md-4">
                <label htmlFor="name">Product Name</label>
                <FormInput
                  id="productName"
                  type="text"
                  name="productName"
                  disabled={isLoading}
                  defaultValue={agro?.productName}
                  label="Product Name"
                  placeholder="Product Name"
                  className="mb-2"
                  register={register}
                  rules={{ required: "Product Name is required." }}
                  errors={errors}
                />
              </div>
              <div className="col-md-4">
                <label htmlFor="image">Product Image</label>
                <FormInput
                  id="thumbnail"
                  type="file"
                  name="thumbnail"
                  label="Image"
                  disabled={isLoading}
                  accept="image/*"
                  placeholder="Image"
                  className="mb-2"
                  register={register}
                  rules={{
                    required: "Image is required.",
                    onChange: (event: React.ChangeEvent<HTMLInputElement>) => {
                      if (event.target.files && event.target.files[0]) {
                        setFile(event.target.files[0]);
                      }
                    },
                  }}
                  errors={errors}
                />
              </div>
              <div className="col-md-4">
                <label htmlFor="quantity">Quantity</label>
                <FormInput
                  id="quantity"
                  type="text"
                  name="quantity"
                  disabled={isLoading}
                  defaultValue={agro?.quantity}
                  label="quantity"
                  placeholder="quantity"
                  className="mb-2"
                  register={register}
                  rules={{ required: "quantity is required." }}
                  errors={errors}
                />
              </div>
              <div className="col-md-4">
                <label htmlFor="price">Product Price</label>
                <FormInput
                  id="productPrice"
                  type="number"
                  step="0.01"
                  name="productPrice"
                  disabled={isLoading}
                  defaultValue={agro?.productPrice}
                  label="Product Price"
                  placeholder="Product Price"
                  className="mb-2"
                  register={register}
                  rules={{ required: "Product Price is required." }}
                  errors={errors}
                />
              </div>
              
              <div className="col-md-4">
                <label htmlFor="category">Category</label>
                <FormInput
                  id="category"
                  type="text"
                  name="category"
                  disabled={isLoading}
                  defaultValue={agro?.category}
                  label="Category"
                  placeholder="Category E.G Tructor, Shovel, Concrete, Masonry"
                  className="mb-2"
                  register={register}
                  rules={{ required: "Category is required." }}
                  errors={errors}
                />
              </div>
              <div className="col-md-4">
                <label htmlFor="unit">Unit</label>
                <FormInput
                  id="unit"
                  type="text"
                  name="unit"
                  disabled={isLoading}
                  defaultValue={agro?.unit}
                  label="Unit"
                  placeholder="Unit E.G grams, Kg"
                  className="mb-2"
                  register={register}
                  rules={{ required: "Unit is required." }}
                  errors={errors}
                />
              </div>

              <div className="col-md-12">
                <label htmlFor="description">Description</label>
                <FormTextarea
                  id="description"
                  name="description"
                  disabled={isLoading}
                  defaultValue={agro?.description}
                  label="description"
                  placeholder="Enter Description"
                  rows={5}
                  cols={12}
                  className="form-control"
                  register={register}
                  rules={{ required: "Description is required." }}
                  errors={errors}
                />
              </div>
            </div>

            <Modal.Footer>
              <Button variant="secondary" onClick={handleClose}>
                Cancel
              </Button>
              <Button disabled={isLoading} variant="primary" type="submit">
                {isLoading && <Spinner size="sm" />}{" "}
                <i className="fa fa-plus"></i> Update
              </Button>
            </Modal.Footer>
          </form>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default EditAgro;
