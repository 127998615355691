import React from "react";
import pdf from "../../../../assets/img/icons/pdf.svg";
import excel from "../../../../assets/img/icons/excel.svg";
import printer from "../../../../assets/img/icons/printer.svg";

const OrderDetails: React.FC = () => {
  return (
    <>
      <div className="content">
        <div className="page-header">
          <div className="page-title">
            <h4>Sale Details</h4>
            <h6>View sale details</h6>
          </div>
        </div>
        <div className="card">
          <div className="card-body">
            <div className="card-sales-split">
              <h2>Sale Detail : SL0101</h2>
              <ul>
                <li>
                  <a href="javascript:void(0);">
                    <img src={pdf} alt="img" />
                  </a>
                </li>
                <li>
                  <a href="javascript:void(0);">
                    <img src={excel} alt="img" />
                  </a>
                </li>
                <li>
                  <a href="javascript:void(0);">
                    <img src={printer} alt="img" />
                  </a>
                </li>
              </ul>
            </div>
            <div
              className="invoice-box table-height"
              style={{
                maxWidth: "1600px",
                width: "100%",
                overflow: "auto",
                margin: "15px auto",
                padding: "0",
                fontSize: "14px",
                lineHeight: "24px",
                color: "#555",
              }}>
              <table
                cellPadding="0"
                cellSpacing="0"
                style={{
                  width: "100%",
                  lineHeight: "inherit",
                  textAlign: "left",
                }}>
                <tbody>
                  <tr className="top">
                    <td
                      colSpan={6}
                      style={{ padding: "5px", verticalAlign: "top" }}>
                      <table
                        style={{
                          width: "100%",
                          lineHeight: "inherit",
                          textAlign: "left",
                        }}>
                        <tbody>
                          <tr>
                            <td
                              style={{
                                padding: "5px",
                                verticalAlign: "top",
                                textAlign: "left",
                                paddingBottom: "20px",
                              }}>
                              <span
                                style={{
                                  verticalAlign: "inherit",
                                  marginBottom: "25px",
                                }}>
                                <span
                                  style={{
                                    verticalAlign: "inherit",
                                    fontSize: "14px",
                                    color: "#7367f0",
                                    fontWeight: "600",
                                    lineHeight: "35px",
                                  }}>
                                  Customer Info
                                </span>
                              </span>
                              <br />
                              <span style={{ verticalAlign: "inherit" }}>
                                <span
                                  style={{
                                    verticalAlign: "inherit",
                                    fontSize: "14px",
                                    color: "#000",
                                    fontWeight: "400",
                                  }}>
                                  walk-in-customer
                                </span>
                              </span>
                              <br />
                              <span style={{ verticalAlign: "inherit" }}>
                                <span
                                  style={{
                                    verticalAlign: "inherit",
                                    fontSize: "14px",
                                    color: "#000",
                                    fontWeight: "400",
                                  }}>
                                  <a
                                    href="/cdn-cgi/l/email-protection"
                                    className="__cf_email__"
                                    data-cfemail="3a4d5b565117535417594f494e55575f487a5f425b574a565f14595557">
                                    [email&#160;protected]
                                  </a>
                                </span>
                              </span>
                              <br />
                              <span style={{ verticalAlign: "inherit" }}>
                                <span
                                  style={{
                                    verticalAlign: "inherit",
                                    fontSize: "14px",
                                    color: "#000",
                                    fontWeight: "400",
                                  }}>
                                  123456780
                                </span>
                              </span>
                              <br />
                              <span style={{ verticalAlign: "inherit" }}>
                                <span
                                  style={{
                                    verticalAlign: "inherit",
                                    fontSize: "14px",
                                    color: "#000",
                                    fontWeight: "400",
                                  }}>
                                  N45 , Dhaka
                                </span>
                              </span>
                              <br />
                            </td>
                            <td
                              style={{
                                padding: "5px",
                                verticalAlign: "top",
                                textAlign: "left",
                                paddingBottom: "20px",
                              }}>
                              <span
                                style={{
                                  verticalAlign: "inherit",
                                  marginBottom: "25px",
                                }}>
                                <span
                                  style={{
                                    verticalAlign: "inherit",
                                    fontSize: "14px",
                                    color: "#7367f0",
                                    fontWeight: "600",
                                    lineHeight: "35px",
                                  }}>
                                  Company Info
                                </span>
                              </span>
                              <br />
                              <span style={{ verticalAlign: "inherit" }}>
                                <span
                                  style={{
                                    verticalAlign: "inherit",
                                    fontSize: "14px",
                                    color: "#000",
                                    fontWeight: "400",
                                  }}>
                                  DGT
                                </span>
                              </span>
                              <br />
                              <span style={{ verticalAlign: "inherit" }}>
                                <span
                                  style={{
                                    verticalAlign: "inherit",
                                    fontSize: "14px",
                                    color: "#000",
                                    fontWeight: "400",
                                  }}>
                                  <a
                                    href="/cdn-cgi/l/email-protection"
                                    className="__cf_email__"
                                    data-cfemail="9ffefbf2f6f1dffae7fef2eff3fab1fcf0f2">
                                    [email&#160;protected]
                                  </a>
                                </span>
                              </span>
                              <br />
                              <span style={{ verticalAlign: "inherit" }}>
                                <span
                                  style={{
                                    verticalAlign: "inherit",
                                    fontSize: "14px",
                                    color: "#000",
                                    fontWeight: "400",
                                  }}>
                                  6315996770
                                </span>
                              </span>
                              <br />
                              <span style={{ verticalAlign: "inherit" }}>
                                <span
                                  style={{
                                    verticalAlign: "inherit",
                                    fontSize: "14px",
                                    color: "#000",
                                    fontWeight: "400",
                                  }}>
                                  3618 Abia Martin Drive
                                </span>
                              </span>
                              <br />
                            </td>
                            <td
                              style={{
                                padding: "5px",
                                verticalAlign: "top",
                                textAlign: "left",
                                paddingBottom: "20px",
                              }}>
                              <span
                                style={{
                                  verticalAlign: "inherit",
                                  marginBottom: "25px",
                                }}>
                                <span
                                  style={{
                                    verticalAlign: "inherit",
                                    fontSize: "14px",
                                    color: "#7367f0",
                                    fontWeight: "600",
                                    lineHeight: "35px",
                                  }}>
                                  Invoice Info
                                </span>
                              </span>
                              <br />
                              <span style={{ verticalAlign: "inherit" }}>
                                <span
                                  style={{
                                    verticalAlign: "inherit",
                                    fontSize: "14px",
                                    color: "#000",
                                    fontWeight: "400",
                                  }}>
                                  Reference
                                </span>
                              </span>
                              <br />
                              <span style={{ verticalAlign: "inherit" }}>
                                <span
                                  style={{
                                    verticalAlign: "inherit",
                                    fontSize: "14px",
                                    color: "#000",
                                    fontWeight: "400",
                                  }}>
                                  Payment Status
                                </span>
                              </span>
                              <br />
                              <span style={{ verticalAlign: "inherit" }}>
                                <span
                                  style={{
                                    verticalAlign: "inherit",
                                    fontSize: "14px",
                                    color: "#000",
                                    fontWeight: "400",
                                  }}>
                                  Status
                                </span>
                              </span>
                              <br />
                            </td>
                            <td
                              style={{
                                padding: "5px",
                                verticalAlign: "top",
                                textAlign: "right",
                                paddingBottom: "20px",
                              }}>
                              <span
                                style={{
                                  verticalAlign: "inherit",
                                  marginBottom: "25px",
                                }}>
                                <span
                                  style={{
                                    verticalAlign: "inherit",
                                    fontSize: "14px",
                                    color: "#7367f0",
                                    fontWeight: "600",
                                    lineHeight: "35px",
                                  }}>
                                  &nbsp;
                                </span>
                              </span>
                              <br />
                              <span style={{ verticalAlign: "inherit" }}>
                                <span
                                  style={{
                                    verticalAlign: "inherit",
                                    fontSize: "14px",
                                    color: "#000",
                                    fontWeight: "400",
                                  }}>
                                  SL0101
                                </span>
                              </span>
                              <br />
                              <span className="badges bg-lightred" style={{ verticalAlign: "inherit" }}>
                                <span
                                  style={{
                                    verticalAlign: "inherit",
                                    fontSize: "14px",
                                    color: "#fff",
                                    fontWeight: "400",
                                  }}>
                                  Paid
                                </span>
                              </span>
                              <br />
                              <span className="badges bg-lightred" style={{ verticalAlign: "inherit" }}>
                                <span
                                  style={{
                                    verticalAlign: "inherit",
                                    fontSize: "14px",
                                    color: "#fff",
                                    fontWeight: "400",
                                  }}>
                                  Completed
                                </span>
                              </span>
                              <br />
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </td>
                  </tr>
                  <tr className="heading" style={{ background: "#f3f2f7" }}>
                    <td
                      style={{
                        verticalAlign: "middle",
                        fontWeight: "600",
                        color: "#5e5873",
                        fontSize: "14px",
                        padding: "10px",
                      }}>
                      Product Name
                    </td>
                    <td
                      style={{
                        verticalAlign: "middle",
                        fontWeight: "600",
                        color: "#5e5873",
                        fontSize: "14px",
                        padding: "10px",
                      }}>
                      QTY
                    </td>
                    <td
                      style={{
                        verticalAlign: "middle",
                        fontWeight: "600",
                        color: "#5e5873",
                        fontSize: "14px",
                        padding: "10px",
                      }}>
                      Price
                    </td>
                    <td
                      style={{
                        verticalAlign: "middle",
                        fontWeight: "600",
                        color: "#5e5873",
                        fontSize: "14px",
                        padding: "10px",
                      }}>
                      Discount
                    </td>
                    <td
                      style={{
                        verticalAlign: "middle",
                        fontWeight: "600",
                        color: "#5e5873",
                        fontSize: "14px",
                        padding: "10px",
                      }}>
                      TAX
                    </td>
                    <td
                      style={{
                        verticalAlign: "middle",
                        fontWeight: "600",
                        color: "#5e5873",
                        fontSize: "14px",
                        padding: "10px",
                      }}>
                      Subtotal
                    </td>
                  </tr>
                  <tr
                    className="details"
                    style={{ borderBottom: "1px solid #e9ecef" }}>
                    <td
                      style={{
                        padding: "10px",
                        verticalAlign: "top",
                        display: "flex",
                        alignItems: "center",
                      }}>
                      <img
                        src={require("../../../../assets/img/product/product1.jpg")}
                        alt="img"
                        className="me-2"
                        style={{ width: "40px", height: "40px" }}
                      />
                      Macbook pro
                    </td>
                    <td style={{ padding: "10px", verticalAlign: "top" }}>
                      1.00
                    </td>
                    <td style={{ padding: "10px", verticalAlign: "top" }}>
                      1500.00
                    </td>
                    <td style={{ padding: "10px", verticalAlign: "top" }}>
                      0.00
                    </td>
                    <td style={{ padding: "10px", verticalAlign: "top" }}>
                      0.00
                    </td>
                    <td style={{ padding: "10px", verticalAlign: "top" }}>
                      1500.00
                    </td>
                  </tr>
                  
                </tbody>
              </table>
            </div>
            <div className="row">
                <div className="col-lg-3 col-sm-6 col-12">
                  <div className="form-group">
                    <label>Order Tax</label>
                    <input type="text" />
                  </div>
                </div>
                <div className="col-lg-3 col-sm-6 col-12">
                  <div className="form-group">
                    <label>Discount</label>
                    <input type="text" />
                  </div>
                </div>
                <div className="col-lg-3 col-sm-6 col-12">
                  <div className="form-group">
                    <label>Shipping</label>
                    <input type="text" />
                  </div>
                </div>
                <div className="col-lg-3 col-sm-6 col-12">
                  <div className="form-group">
                    <label>Status</label>
                    <select className="select form-control">
                      <option>Choose Status</option>
                      <option>Completed</option>
                      <option>Inprogress</option>
                    </select>
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-6">
                    <div className="total-order w-100 max-widthauto m-auto mb-4">
                      <ul>
                        <li>
                          <h4>Order Tax</h4>
                          <h5>$ 0.00 (0.00%)</h5>
                        </li>
                        <li>
                          <h4>Discount</h4>
                          <h5>$ 0.00</h5>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="total-order w-100 max-widthauto m-auto mb-4">
                      <ul>
                        <li>
                          <h4>Shipping</h4>
                          <h5>$ 0.00</h5>
                        </li>
                        <li className="total">
                          <h4>Grand Total</h4>
                          <h5>$ 0.00</h5>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="col-lg-12">
                  <a href="javascript:void(0);" className="btn btn-submit me-2">Update</a>
                  <a href="javascript:void(0);" className="btn btn-cancel"  >Cancel</a>
                </div>
              </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default OrderDetails;
