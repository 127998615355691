import React from "react";
import { PropertyModel } from "../../../../models/product_model";

interface ProperyProps {
  property?: PropertyModel;
}

function Features({ property }: ProperyProps) {
  return (
    <>
      <div className="vesh-detail-bloc">
        <div className="vesh-detail-bloc_header">
          <a
            data-bs-toggle="collapse"
            data-parent="#featuresinfo"
            data-bs-target="#featuresinfo"
            aria-controls="featuresinfo"
            href="javascript:void(0);"
            aria-expanded="false">
            <h4 className="property_block_title">Features</h4>
          </a>
        </div>
        <div
          id="featuresinfo"
          className="panel-collapse"
          aria-labelledby="featuresinfo">
          <div className="vesh-detail-bloc-body">
            <div className="lvs-detail mb-4">
              <div className="row">
                <div className="col-xl-12 col-lg-12 col-md-12">
                  <h6>Interior Details</h6>
                </div>
              </div>
              <div className="row gy-3">
                {property?.propertyInteriorDetails?.map((item, index) => {
                  return (
                    <div
                      key={index}
                      className="col-xl-4 col-lg-4 col-md-4 col-12">
                      <div className="lvs-caption capitalize">
                        <i className="fa fa-check-circle"></i>

                        {item.name}
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
            <div className="lvs-detail mb-4">
              <div className="row">
                <div className="col-xl-12 col-lg-12 col-md-12">
                  <h6>Outdoor Details</h6>
                </div>
              </div>
              <div className="row gy-3">
                {property?.propertyOutdoorDetails?.map((item, index) => {
                  return (
                    <div
                      key={index}
                      className="col-xl-4 col-lg-4 col-md-4 col-12">
                      <div className="lvs-caption capitalize">
                        <i className="fa fa-check-circle"></i>
                        {item.name}
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
            <div className="lvs-detail mb-4">
              <div className="row">
                <div className="col-xl-12 col-lg-12 col-md-12">
                  <h6>Utilities</h6>
                </div>
              </div>
              <div className="row gy-3">
                {property?.propertyUtilities?.map((item, index) => {
                  return (
                    <div
                      key={index}
                      className="col-xl-4 col-lg-4 col-md-4 col-12">
                      <div className="lvs-caption capitalize">
                        <i className="fa fa-check-circle"></i> {item.name}
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Features;
