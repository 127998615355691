import React from "react";

interface InspectionSectionProps {
  title: string;
  data: { [key: string]: string }; // Object with key-value pairs for inspection items
  onChange: (field: string, value: string) => void; // Callback for updating data
}

const InspectionSection: React.FC<InspectionSectionProps> = ({ title, data, onChange }) => {
  return (
    <section>
      <h2>{title}</h2>
      <div className="table-responsive">
      <table className="table datanew">
        <thead>
          <tr>
            <th>No</th>
            <th>Description</th>
            <th>Status</th>
          </tr>
        </thead>
        <tbody>
          {Object.keys(data).map((key, index) => (
            <tr key={key}>
              <td>{index + 1}</td>
              <td>{key.replace(/_/g, " ").toUpperCase()}</td>
              <td>
                <select
                  value={data[key]}
                  onChange={(e) => onChange(key, e.target.value)}
                >
                  <option value="OK">OK</option>
                  <option value="Missing">Missing</option>
                  <option value="Damaged">Damaged</option>
                  <option value="N/A">N/A</option>
                </select>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      </div>
    </section>
  );
};

export default InspectionSection;
