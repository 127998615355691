import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import plusIcon from "../../../../assets/img/icons/plus.svg";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { Spinner } from "react-bootstrap";
import { FormInput } from "../../../../components/molecules/form_input";
import { FormTextarea } from "../../../../components/molecules/form_textarea";
import httpDashboardServices from "../../../services/http.category.services";
import { useAppSelector } from "../../../../hooks/redux-hooks";
import { useCreateMineralMutation } from "../../../../redux/slices/mineral_slice";
import { useGeTMineralCagetoryListQuery } from "../../../../redux/slices/mineral_category_slice";
import { TMineralCagetory } from "../../../../redux/types/mineral_category.type";

export type DataFormFields = {
  supplier: string;
  category: string;
  productName: string;
  unit: string;
  productPrice: string;
  description: string;
  image: File;
};

const AddFuel: React.FC = () => {
  const user = useAppSelector((state) => state.auth.user);
  const [isLoading, setIsLoading] = useState(false);
  const [show, setShow] = useState(false);
  const [file, setFile] = useState<File | any>();
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);


  // const [createMineral, { isLoading }] = useCreateMineralMutation();

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<DataFormFields>();

  
  const {
    data: categories,
    isLoading: fetchingData,
    isFetching,
    error,
  }: TMineralCagetory | any = useGeTMineralCagetoryListQuery();

  if (fetchingData || isFetching) {
    return (
      <div className="text-center">
        <i className="fa fa-spinner fa-spin fa-2x text-danger"></i>
      </div>
    );
  }

  const onSubmit = handleSubmit(async (data) => {
    setIsLoading(true);
    await httpDashboardServices
      .createMineral({
        supplier: user?.id,
        productName: data.productName,
        productPrice: data.productPrice,
        image: file,
        description: data.description,
        unit: data.unit,
        category: data.category,
      })
      .then((res: any) => {
        setIsLoading(false);
        reset();
        setShow(false);
        toast.success("Product Created Successfully");
      })
      .catch((err: any) => {
        setIsLoading(false);
        console.error(err.message);
        toast.error("Failed to add:" + err.data["detail"]);
      });
    // createMineral({
    //   supplier: user?.id,
    //   productName: data.productName,
    //   productPrice: data.productPrice,
    //   image: file,
    //   description: data.description,
    //   unit: data.unit,
    //   category: data.category,
    // })
    //   .unwrap()
    //   .then((res) => {
    //     reset();
    //     setShow(false);
    //     toast.success("Product Created Successfully");
    //   })
    //   .catch((err) => {
    //     toast.error("Error: " + err.data["detail"]);
    //   });
  });

  return (
    <>
      <button onClick={handleShow} className="btn btn-added mt-3">
        <img src={plusIcon} alt="img" />
        Add New Mineral
      </button>

      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        centered
        scrollable
        size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title>Add Mineral Product</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={onSubmit} encType="multipart/form-data">
            <div className="row">
              <div className="col-md-4">
                <label htmlFor="name">Product Name</label>
                <FormInput<DataFormFields>
                  id="productName"
                  type="text"
                  name="productName"
                  disabled={isLoading}
                  label="Product Name"
                  placeholder="Product Name"
                  className="mb-2"
                  register={register}
                  rules={{ required: "Product Name is required." }}
                  errors={errors}
                />
              </div>
              <div className="col-md-4">
                <label htmlFor="price">Product Price</label>
                <FormInput<DataFormFields>
                  id="productPrice"
                  type="text"
                  step="0.01"
                  name="productPrice"
                  disabled={isLoading}
                  label="Product Price"
                  placeholder="Product Price"
                  className="mb-2"
                  register={register}
                  rules={{ required: "Product Price is required." }}
                  errors={errors}
                />
              </div>
              <div className="col-md-4">
                <label htmlFor="price">Product Unit</label>
                <FormInput<DataFormFields>
                  id="productUnit"
                  type="text"
                  step="0.01"
                  name="unit"
                  disabled={isLoading}
                  label="Product Unit"
                  placeholder="Product Unit E.G grams, kg etc"
                  className="mb-2"
                  register={register}
                  rules={{ required: "Product Unit is required." }}
                  errors={errors}
                />
              </div>
              <div className="col-md-6">
                <label htmlFor="image">Product Image</label>
                <FormInput<DataFormFields>
                  id="thumbnail"
                  type="file"
                  name="image"
                  label="Image"
                  disabled={isLoading}
                  accept="image/*"
                  placeholder="Image"
                  className="mb-2"
                  register={register}
                  rules={{
                    required: "Image is required.",
                    onChange: (event: React.ChangeEvent<HTMLInputElement>) => {
                      if (event.target.files && event.target.files[0]) {
                        setFile(event.target.files[0]);
                      }
                    },
                  }}
                  errors={errors}
                />
              </div>
              <div className="col-md-6">
                <label htmlFor="label">Product Category</label>
                <select
                  {...register("category", { required: true })}
                  name="category"
                  className={`select text-capitalize form-control ${
                    errors.category ? "border-danger" : ""
                  }`}
                  id="category"
                >
                  <option value="">Select Category</option>
                  {categories?.map((item: TMineralCagetory, index: number) => {
                    return (
                      <option key={index} value={item.id}>
                        {item.categoryName}
                      </option>
                    );
                  })}
                </select>
                {errors.category && (
                  <p className="text-danger">Category is required.</p>
                )}
              </div>
              <div className="col-md-12">
                <label htmlFor="description">Description</label>
                <FormTextarea<DataFormFields>
                  id="description"
                  name="description"
                  disabled={isLoading}
                  label="description"
                  placeholder="Enter Description"
                  rows={8}
                  cols={12}
                  className="form-control"
                  register={register}
                  rules={{ required: "Description is required." }}
                  errors={errors}
                />
              </div>
            </div>

            <Modal.Footer>
              <Button variant="secondary" onClick={handleClose}>
                Cancel
              </Button>
              <Button disabled={isLoading} variant="primary" type="submit">
                {isLoading && <Spinner size="sm" />}{" "}
                <i className="fa fa-plus"></i> Create
              </Button>
            </Modal.Footer>
          </form>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default AddFuel;
