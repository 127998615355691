import React, { useRef } from "react";
import editIcon from "../../../../assets/img/icons/edit.svg";
import deleteIcon from "../../../../assets/img/icons/delete.svg";
import moment from "moment";
import { useAppSelector } from "../../../../hooks/redux-hooks";
import { ICarBrand } from "../../../../redux/types/car_rental.type";
import { useGetBrandsQuery } from "../../../../redux/slices/car_brands_apislice";
import Pagination from "../../../pagination/pagination_items";
import BreadCrumbs from "../../../widgets/bread_crumbs";
import LoadingSpinner from "../../../../components/widgets/spinner";
import Filters from "../../widgets/filters";

const CarBrandList: React.FC = () => {
  const divRef = useRef<HTMLDivElement>(null);
  const user = useAppSelector((state) => state.auth.user);
  const [query, setQuery] = React.useState("")
  const [currentPage, setCurrentPage] = React.useState(1);

  const {
    data: carBrands,
    isLoading,
    error,
    refetch,
  }: ICarBrand | any = useGetBrandsQuery({searchQuery: query, page: currentPage});

  if (isLoading) {
    return (
      <div style={{ marginTop: "20%" }} className="text-center">
        <LoadingSpinner />
      </div>
    );
  }

  const filterOrdersByDay = (day: any) => {
    const formattedDay = moment(day).format('YYYY-MM-DD');
    const filteredOrders = carBrands.filter((order: ICarBrand) => moment(order.created_at).isSame(formattedDay, 'day'));
    console.log(`Filtered orders for day ${formattedDay}:`, filteredOrders);
    return filteredOrders;
  };

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value;
    setQuery(newValue);
  }

  return (
    <>
      <div className="content">
      <BreadCrumbs
        title={"Car Brand List"}
        subTitle={"View/Manage Car Brand List"}
      />

        <div className="card">
          <div className="card-body">
            <div className="card">
              <div className="card-body pb-0">
                <div className="row">
                  <div className="col-lg-6 col-sm-6 col-12">
                    <div className="form-group">
                      <input className="form-control" value={query}
                      onChange={onChange} type="search" name="search_mineral" id="search_mineral" placeholder="Search..." />
                    </div>
                  </div>
                  <div className="col-lg-6 col-sm-6 col-12">
                  <Filters divRef={divRef} showSearch={false} query={query} onSearchChange={onChange} />
                  </div>
                </div>
              </div>
            </div>
            {error ? (
              <div className="alert alert-danger text-center mt-5" role="alert">
                {error.data?.detail}
              </div>
            ) : carBrands.results.length === 0 ? (
              <div className="text-center">
                <i style={{ fontSize: "50px" }} className="ti-home"></i>
                <h2>No Data Found</h2>
              </div>
            ) : (
              <div className="table-responsive">
                <table className="table datanew">
                  <thead>
                    <tr>
                      <th>
                        #
                      </th>
                      <th>Brand name</th>
                      <th>Created By</th>
                      <th>Created On</th>
                      <th>Cars</th>
                      {user?.user_type !== "ADMIN" ? null : <th>Action</th>}
                    </tr>
                  </thead>
                  <tbody>
                    {carBrands.results.map((item: ICarBrand, index: number) => {
                      return (
                        <tr key={index}>
                          <td>
                            {index + 1}
                          </td>
                          <td className="productimgname">
                            <a
                              href={``}
                              className="product-img rounded"
                            >
                              <img
                                src={`${item.image}`}
                                alt="product"
                                className="rounded"
                              />
                            </a>
                            <a className="text-capitalize" href={`#`}>
                              {item.brand_name}
                            </a>
                          </td>
                          <td>Admin</td>
                          <td>{moment(item.created_at).format("MMMM Do, YYYY")}</td>
                          <td className="text-capitalize">{item.carmodel_count}</td>
                          {user?.user_type !== "ADMIN" ? null : <td>
                            <a className="me-3" href="editcategory.html">
                              <img src={editIcon} alt="img" />
                            </a>
                            <a
                              className="me-3 confirm-text"
                              href="javascript:void(0);"
                            >
                              <img src={deleteIcon} alt="img" />
                            </a>
                          </td>}
                          
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
                <div className="mt-4">
                <Pagination
                  currentPage={currentPage}
                  lastPage={carBrands.total_pages}
                  maxLength={7}
                  setCurrentPage={setCurrentPage}
                />
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default CarBrandList;
