import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { TMineral } from "../../../../redux/types/mineral.type";
import { useDeleteMineralMutation } from "../../../../redux/slices/mineral_slice";
import { toast } from "react-toastify";
import { Spinner } from "react-bootstrap";

type TMineralProps = {
  mineral: TMineral;
  show: boolean;
  handleClose: () => void;
  setShow: (show: boolean) => void;
};

const DeleteMineralModal: React.FC<TMineralProps> = ({
  mineral,
  show,
  handleClose,
  setShow,
}) => {
  const [
    deleteMineral,
    { isLoading: deleting, error: errorDeleting, isSuccess },
  ] = useDeleteMineralMutation();

  const onDelete = () => {
    deleteMineral(mineral.id)
      .unwrap()
      .then((res) => {
        setShow(false);
        toast.success("Deleted Successfully");
      })
      .catch((err) => {
        toast.error("An error occurred try again");
      });
  };

  return (
    <>
      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Delete</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Are you sure you want to delete{" "}
          <b className="text-capitalize">{mineral?.productName}</b>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" disabled={deleting} onClick={handleClose}>
            Close
          </Button>
          <Button disabled={deleting} onClick={onDelete} variant="danger">
            {deleting && <Spinner size="sm" />} Delete
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default DeleteMineralModal;
