import React from "react";
import Calendar from "../../calendar/calendar";
import WelcomeDisplay from "../widgets/welcome_display";
import { useAppSelector } from "../../../hooks/redux-hooks";
import moment from "moment";
import { useGetAgroCustomersQuery, useGetAgroOrdersListQuery } from "../../../redux/slices/agro_order_slice";
import { TAgroOrder } from "../../../redux/types/agro_order.type";
import { TAgriculture } from "../../../redux/types/agriculture.type";
import { useGetAgricultureListQuery } from "../../../redux/slices/agriculture_slice";
import LoadingSpinner from "../../../components/widgets/spinner";

const AgroDashboardHomePage: React.FC = () => {
  const user = useAppSelector((state) => state.auth.user);
  const [query, setQuery] = React.useState("")
  const [currentPage, setCurrentPage] = React.useState(1);
  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value;
    setQuery(newValue);
  }
  const {
    data: reservations,
    isLoading,
    error,
  }: TAgroOrder | any = useGetAgroOrdersListQuery({
    status: "PENDING",
    user: user?.id,
  });
  const {
    data: confirmedReservations,
    isLoading: fetchingConfirmed,
  }: TAgroOrder | any = useGetAgroOrdersListQuery({
    status: "CONFIRMED",
    user: user?.id,
  });
  const {
    data: cancelled,
    isLoading: fetchingCancelled,
  }: TAgroOrder | any = useGetAgroOrdersListQuery({
    status: "CANCELLED",
    user: user?.id,
  });
  
  const { data: customers, isLoading: fetchingCustomers }: TAgroOrder | any =
    useGetAgroCustomersQuery({
      company: user?.id,
    });

  const { data: agroList, isLoading: fetchingMinerals }: TAgriculture | any =
    useGetAgricultureListQuery({supplier: user?.id, status: "ACTIVE", searchQuery: query, page: currentPage});

  if (isLoading || fetchingCustomers || fetchingConfirmed || fetchingMinerals || fetchingCancelled) {
    return (
      <div className="text-center mt-5">
        <LoadingSpinner />
      </div>
    );
  }

  if (error) {
    return (
      <div className="alert alert-danger text-center mt-5" role="alert">
        An error occurred while loading the application
      </div>
    );
  }

  let ticketAmount = reservations.map(
    (item: TAgroOrder) => item.productPrice
  );

  const total_amount = ticketAmount.reduce(function (acc: number, val: number) {
    return acc + val;
  }, 0);

  let confirmedAmount = confirmedReservations.map(
    (item: TAgroOrder) => item.productPrice
  );

  const totalConirmedAmount = confirmedAmount.reduce(function (
    acc: number,
    val: number
  ) {
    return acc + val;
  },
  0);

  return (
    <div className="content">
      <center>
        <h2
          style={{ fontFamily: "monospace", fontWeight: "bolder" }}
          className="text-capitalize"
        >
          Welcome: {user?.company_name}
        </h2>
      </center>
      <WelcomeDisplay
        totalAmount={total_amount}
        reservations={reservations}
        customers={customers}
        agroList={agroList.results}
        confirmedReservations={confirmedReservations}
        totalConfirmed={totalConirmedAmount}
        cancelled={cancelled}
      />
      <div className="col-md-12">
        <div className="card mb-4 mb-md-0">
          {reservations.length === 0 ? (
            <center>
              <div
                className="text-center alert alert-info mt-3"
                role="alert"
                style={{ width: "fit-content" }}
              >
                <h1 className="mt-3">NO PENDING SALES FOUND</h1>
              </div>
            </center>
          ) : (
            <div className="card-body table-responsive">
              <table className="table table-hover">
                <thead>
                  <tr>
                    <th>#</th>
                    <th>Product</th>
                    <th>Name</th>
                    <th>E-mail</th>
                    <th>Phone Number</th>
                    <th>Paid</th>
                    <th>Date</th>
                    <th>Status</th>
                  </tr>
                </thead>

                <tbody>
                  {reservations
                    .slice(0, 10)
                    .map((item: TAgroOrder, index: number) => (
                      <tr key={index}>
                        <td>{index + 1}</td>
                        <td className="text-capitalize">{item.product.productName.substring(0, 12)}</td>
                        <td className="text-capitalize">
                          {item.customer.first_name} {item.customer.last_name}
                        </td>
                        <td>{item.customer.email}</td>
                        <td>{item.customer.phoneNumber}</td>
                        <td>ZMW {item.productPrice.toLocaleString()}</td>
                        <td>{moment(item.created_at).format("MMM do YYYY")}</td>
                        <td>
                          <div className="badges bg-lightred text-white text-capitalize">
                            <b>{item.status}</b>
                          </div>
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
          )}
        </div>
      </div>
      <Calendar />
    </div>
  );
};

export default AgroDashboardHomePage;
