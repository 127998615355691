import React from "react";
import dashboard from "../../../assets/img/logo.jpg";
import product from "../../../assets/img/icons/product.svg";
import settings from "../../../assets/img/icons/settings.svg";
import sales1 from "../../../assets/img/icons/sales1.svg";
import purchase1 from "../../../assets/img/icons/purchase1.svg";
import expense1 from "../../../assets/img/icons/expense1.svg";
import quotation1 from "../../../assets/img/icons/quotation1.svg";
import transfer1 from "../../../assets/img/icons/transfer1.svg";
import user1 from "../../../assets/img/icons/users1.svg";
import { useAppSelector } from "../../../hooks/redux-hooks";
import { Link, useLocation } from "react-router-dom";
import DashboardAvatar from "../../widgets/dashboard_avatar";
import DashboardFooter from "../../widgets/dsahboard_footer";

const SideBar: React.FC = () => {
  const user = useAppSelector((state) => state.auth.user);
  const location = useLocation();
  return (
    <>
      <div className="sidebar" id="sidebar">
        <div className="sidebar-inner slimscroll">
          <div id="sidebar-menu" className="sidebar-menu">
          <DashboardAvatar />
            <ul>
              <li className={`${location.pathname === "/zm/dashboard" ? "active" : ""}`}>
                <a href="/zm/dashboard">
                  <img style={{borderRadius: '50%'}} src={dashboard} alt="img" />
                  <span>Dashboard</span>
                </a>
              </li>
              <li
                className={`${
                  location.pathname === "/zm/dashboard/profile/"
                    ? "active"
                    : ""
                }`}
              >
                <a href="/zm/dashboard/profile/">
                  <i className="fa fa-user-tie"></i>
                  <span>My Profile</span>
                  <span className="menu-arrow"></span>
                </a>
              </li>
              {/* CAR RENTAL */}
              <li className="submenu">
                <a href="javascript:void(0);">
                  <img src={purchase1} alt="img" />
                  <span>Car Rentals</span>
                  <span className="menu-arrow"></span>
                </a>
                <ul>
                  <li>
                    <Link className={`${location.pathname === "/zm/dashboard/car_brands" ? "active" : ""}`} to="/zm/dashboard/car_brands">Car Brands</Link>
                  </li>
                  <li>
                    <Link className={`${location.pathname === "/zm/dashboard/car_list" ? "active" : ""}`} to="/zm/dashboard/car_list">Car List</Link>
                  </li>
                </ul>
              </li>
              {/* MINERALS */}
              <li className="submenu">
                <a href="javascript:void(0);">
                  <img src={product} alt="img" />
                  <span>Commodities</span>
                  <span className="menu-arrow"></span>
                </a>
                <ul>
                  <li>
                    <Link className={`${location.pathname === "/zm/dashboard/mineral_trading" ? "active" : ""}`} to="/zm/dashboard/mineral_trading">Mineral Trading</Link>
                  </li>
                  <li>
                    <Link className={`${location.pathname === "/zm/dashboard/agriculture_list" ? "active" : ""}`} to="/zm/dashboard/agriculture_list">Agriculture List</Link>
                  </li>
                  <li>
                    <Link className={`${location.pathname === "/zm/dashboard/fuel_list" ? "active" : ""}`} to="/zm/dashboard/fuel_list">Fuel List</Link>
                  </li>
                </ul>
              </li>
              {/* INVESTMENT */}
              <li className="submenu">
                <a href="javascript:void(0);">
                  <img src={transfer1} alt="img" />
                  <span>Investment</span>
                  <span className="menu-arrow"></span>
                </a>
                <ul>
                  <li>
                  </li>
                  <li>
                    <Link className={`${location.pathname === "/zm/dashboard/investment_list" ? "active" : ""}`} to="/zm/dashboard/investment_list">Investment List</Link>
                  </li>
                </ul>
              </li>
              {/* REAL ESTATE */}
              <li className="submenu">
                <a href="javascript:void(0);">
                  <i className="fa fa-home"></i>
                  <span>Real Estate</span>
                  <span className="menu-arrow"></span>
                </a>
                <ul>
                  <li>
                  </li>
                  <li>
                    <Link className={`${location.pathname === "/zm/dashboard/real_estate_list" ? "active" : ""}`} to="/zm/dashboard/real_estate_list">Real Estate List</Link>
                  </li>
                  
                </ul>
              </li>
              {/* ESCROW */}
              <li className="submenu">
                <a href="javascript:void(0);">
                  <img src={expense1} alt="img" />
                  <span>DealSecure</span>
                  <span className="menu-arrow"></span>
                </a>
                <ul>
                  <li>
                  </li>
                  <li>
                    <Link className={`${location.pathname === "/zm/dashboard/escrow_list" ? "active" : ""}`} to="/zm/dashboard/escrow_list">DealSecure List</Link>
                  </li>
                  
                </ul>
              </li>
              {/* MINING CONSTRUCTION */}
              <li className="submenu">
                <a href="javascript:void(0);">
                  <img src={sales1} alt="img" />
                  <span>Mining/Construction</span>
                  <span className="menu-arrow"></span>
                </a>
                <ul>
                  <li>
                  </li>
                  <li>
                    <Link className={`${location.pathname === "/zm/dashboard/mining_list" ? "active" : ""}`} to="/zm/dashboard/mining_list">Mining List</Link>
                  </li>
                  <li>
                    <Link className={`${location.pathname === "/zm/dashboard/construction_list" ? "active" : ""}`} to="/zm/dashboard/construction_list">Construction List</Link>
                  </li>
                </ul>
              </li>
               <li className="submenu">
                <a href="javascript:void(0);">
                  <img src={user1} alt="img" />
                  <span>Organizations</span>
                  <span className="menu-arrow"></span>
                </a>
                <ul>
                  <li>
                  <Link className={`${location.pathname === "/zm/dashboard/car_organizations" ? "active" : ""}`} to="/zm/dashboard/car_organizations">Car Companies</Link>
                  <Link className={`${location.pathname === "/zm/dashboard/mineral_companies" ? "active" : ""}`} to="/zm/dashboard/mineral_companies">Mineral Companies</Link>
                  <Link className={`${location.pathname === "/zm/dashboard/agro_companies" ? "active" : ""}`} to="/zm/dashboard/agro_companies">Agro Companies</Link>
                  <Link className={`${location.pathname === "/zm/dashboard/investment_companies" ? "active" : ""}`} to="/zm/dashboard/investment_companies">Investment Companies</Link>
                  <Link className={`${location.pathname === "/zm/dashboard/real_estate_companies" ? "active" : ""}`} to="/zm/dashboard/real_estate_companies">Real-Estate Companies</Link>
                  <Link className={`${location.pathname === "/zm/dashboard/fuel_companies" ? "active" : ""}`} to="/zm/dashboard/fuel_companies">Fuel Companies</Link>
                  <Link className={`${location.pathname === "/zm/dashboard/mining_companies" ? "active" : ""}`} to="/zm/dashboard/mining_companies">Mining Companies</Link>
                  <Link className={`${location.pathname === "/zm/dashboard/construction_companies" ? "active" : ""}`} to="/zm/dashboard/construction_companies">Construction Companies</Link>
                  </li>
                </ul>
              </li>
              <li className="submenu">
                <a href="javascript:void(0);">
                  <img src={user1} alt="img" />
                  <span>People</span>
                  <span className="menu-arrow"></span>
                </a>
                <ul>
                  <li>
                    <Link className={`${location.pathname === "/zm/dashboard/customerlist" ? "active" : ""}`} to="/zm/dashboard/customerlist">Customer List</Link>
                  </li>
                </ul>
              </li>
              
              
              <li className="submenu">
                <a href="javascript:void(0);">
                  <img src={settings} alt="img" />
                  <span>Settings</span>
                  <span className="menu-arrow"></span>
                </a>
                <ul>
                  <li>
                    <Link className={`${location.pathname === "/zm/dashboard/payment_settings" ? "active" : ""}`} to="/zm/dashboard/payment_settings">Payment Settings</Link>
                  </li>
                  <li>
                    <Link className={`${location.pathname === "/zm/dashboard/currency_settings" ? "active" : ""}`} to="/zm/dashboard/currency_settings">Currency Settings</Link>
                  </li>
                </ul>
              </li>
              <li
                className={`${
                  location.pathname ===
                  "/zm/dashboard/change-password"
                    ? "active"
                    : ""
                }`}
              >
                <Link to="/zm/dashboard/change-password">
                  <i className="fa fa-unlock-alt"></i>
                  <span>Change Password</span>
                  <span className="menu-arrow"></span>
                </Link>
              </li>
            </ul>
            <DashboardFooter />
          </div>
        </div>
      </div>
    </>
  );
};

export default SideBar;
