import React from "react";
import { PropertyModel } from "../../../../models/product_model";

interface ProperyProps {
  property?: PropertyModel;
}

function Amenties({ property }: ProperyProps) {
  return (
    <>
      <div className="vesh-detail-bloc">
        <div className="vesh-detail-bloc_header">
          <a
            data-bs-toggle="collapse"
            data-parent="#amenitiesinfo"
            data-bs-target="#amenitiesinfo"
            aria-controls="amenitiesinfo"
            href="javascript:void(0);"
            aria-expanded="false">
            <h4 className="property_block_title">Amenties</h4>
          </a>
        </div>
        <div
          id="amenitiesinfo"
          className="panel-collapse"
          aria-labelledby="amenitiesinfo">
          <div className="vesh-detail-bloc-body">
            <ul className="avl-features third color">
              {property?.propertyAmenities?.map((item, index) => {
                return (
                  <li className="capitalize" key={index}>
                    {item.name}
                  </li>
                );
              })}
            </ul>
          </div>
        </div>
      </div>
    </>
  );
}

export default Amenties;
