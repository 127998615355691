import React from "react";
import { toast } from "react-toastify";
import { ICarBrand, ICarRental } from "../../../../redux/types/car_rental.type";
import { useForm } from "react-hook-form";
import EditCarImages from "./edit_car_images";
import { useGetBrandsQuery } from "../../../../redux/slices/car_brands_apislice";
import LoadingSpinner from "../../../../components/widgets/spinner";
import { useAdminUpdateCarMutation, useGetAdminCarQuery } from "../../../../redux/slices/admin_hire_slice";

const EditCar: React.FC = () => {
  const queryParams = new URLSearchParams(window.location.search);
  const { data: carBrands, isLoading: fetchingBrands }: ICarBrand | any =
    useGetBrandsQuery({searchQuery: "", page: ""});
  const car_id = queryParams.get("car_id");
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  const [adminUpdateCar, { isLoading }] = useAdminUpdateCarMutation();

  const {
    data: car,
    isLoading: isFetching,
    error,
  }: ICarRental | any = useGetAdminCarQuery(Number(car_id));

  if (isFetching) {
    return (
      <div style={{ marginTop: "20%" }} className="text-center">
        <LoadingSpinner />
      </div>
    );
  }
  if (error) {
    return (
      <div className="text-center alert alert-danger mt-5" role="alert">
        <h4>Error: {error.status} </h4>
      </div>
    );
  }

  const onSubmit = async (data: any) => {
    adminUpdateCar({ id: car_id, ...data })
      .unwrap()
      .then(() => {
        toast.success("car updated Successfully");
      })
      .catch((err) => {
        console.error(err.error);
        toast.error("Failed to add:" + err.error);
      });
  };
  return (
    <div className="container">
      <h3 className="mt-3 mb-4 text-center text-capitalize">Edit <span className="text-primary">{car?.car_model}</span></h3>
      <form
        id="paypal_donate_form_onetime_recurring"
        onSubmit={handleSubmit(onSubmit)}>
        <div className="row">
          <div className="col-md-12">
            <div className="row">
              <div className="col-sm-12 col-md-3">
                <div className="form-group">
                  <label htmlFor="form_name">
                    Car Seats <small className="text-danger">*</small>
                  </label>
                  <input
                    id="seats"
                    disabled={isLoading}
                    {...register("seats", { required: true })}
                    className={`form-control ${
                      errors.seats ? "border-danger" : ""
                    }`}
                    name="seats"
                    defaultValue={car?.seats}
                    type="number"
                    placeholder="Number of Seats"
                  />
                  {errors.seats && (
                    <p className="text-danger">This field is required.</p>
                  )}
                </div>
              </div>
              <div className="col-sm-12 col-md-3">
                <div className="form-group">
                  <label htmlFor="form_name">
                    Speedometer <small className="text-danger">*</small>
                  </label>
                  <input
                    id="speedometer"
                    disabled={isLoading}
                    {...register("speedometer", { required: true })}
                    className={`form-control ${
                      errors.speedometer ? "border-danger" : ""
                    }`}
                    name="speedometer"
                    defaultValue={car?.speedometer}
                    type="number"
                    placeholder="Speedometer"
                  />
                  {errors.speedometer && (
                    <p className="text-danger">This field is required.</p>
                  )}
                </div>
              </div>
              <div className="col-sm-12 col-md-3">
                <div className="form-group">
                  <label htmlFor="form_name">
                    Fuel Tank <small className="text-danger">*</small>
                  </label>
                  <input
                    id="fuel_litres"
                    disabled={isLoading}
                    {...register("fuel_litres", { required: true })}
                    defaultValue={car?.fuel_litres}
                    className={`form-control ${
                      errors.fuel_litres ? "border-danger" : ""
                    }`}
                    name="fuel_litres"
                    type="number"
                    step="0.01"
                    placeholder="eg 5.6"
                  />
                  {errors.fuel_litres && (
                    <p className="text-danger">This field is required.</p>
                  )}
                </div>
              </div>
              <div className="col-sm-12 col-md-3">
                <div className="form-group">
                  <label htmlFor="form_name">
                    Music <small className="text-danger">*</small>
                  </label>
                  <input
                    id="music_types"
                    disabled={isLoading}
                    {...register("music_types", { required: true })}
                    defaultValue={car?.music_types}
                    className={`form-control ${
                      errors.music_types ? "border-danger" : ""
                    }`}
                    name="music_types"
                    type="text"
                    placeholder="E.G Radiio/Bluetooth/USB"
                  />
                  {errors.music_types && (
                    <p className="text-danger">This field is required.</p>
                  )}
                </div>
              </div>
              <div className="col-sm-6 col-md-4">
                <div className="form-group">
                  <label htmlFor="form_name">
                    Number Plate
                    <small className="text-danger">*</small>
                  </label>
                  <input
                    id="title"
                    disabled={isLoading}
                    {...register("number_plate", { required: true })}
                    defaultValue={car?.number_plate}
                    className={`form-control ${
                      errors.number_plate ? "border-danger" : ""
                    }`}
                    name="number_plate"
                    type="text"
                    placeholder="Car Number Plate"
                    
                  />
                  {errors.number_plate && (
                    <p className="text-danger">This field is required.</p>
                  )}
                </div>
              </div>
              <div className="col-sm-6 col-md-4">
                <div className="form-group">
                  <label htmlFor="form_name">
                    Car Model <small className="text-danger">*</small>
                  </label>
                  <input
                    id="title"
                    disabled={isLoading}
                    {...register("car_model", { required: true })}
                    className={`form-control ${
                      errors.car_model ? "border-danger" : ""
                    }`}
                    name="car_model"
                    defaultValue={car?.car_model}
                    type="text"
                    placeholder="Car Manufacturer e.g BMW, VW, TOYOTA"
                  />
                  {errors.car_model && (
                    <p className="text-danger">This field is required.</p>
                  )}
                </div>
              </div>
              <div className="col-sm-6 col-md-4">
                <div className="form-group">
                  <label htmlFor="form_email">
                    Car Brand <small className="text-danger">*</small>
                  </label>
                  <select
                    required
                    className="form-control mb-2 text-capitalize"
                    name="car_brand">
                    <option disabled selected>
                      Choose Car Brand
                    </option>
                    {fetchingBrands ? (
                      <div></div>
                    ) : (
                      carBrands.results.map((item: ICarBrand, index: number) => {
                        return (
                          <option key={index} value={item.id}>
                            {item.brand_name}
                          </option>
                        );
                      })
                    )}
                  </select>
                </div>
              </div>
              <div className="col-sm-6 col-md-4">
                <div className="form-group">
                  <label htmlFor="form_email">
                   Local Car Rental Price <small className="text-danger">*</small>
                  </label>
                  <input
                    id="form_email"
                    disabled={isLoading}
                    defaultValue={car?.local_price}
                    {...register("local_price", { required: true })}
                    className={`form-control ${
                      errors.local_price ? "border-danger" : ""
                    }`}
                    name="local_price"
                    type="number"
                    step="0.01"
                    placeholder="Local Car Rental Price Per DAY"
                  />
                  {errors.local_price && (
                    <p className="text-danger">This field is required.</p>
                  )}
                </div>
              </div>
              <div className="col-sm-6 col-md-4">
                <div className="form-group">
                  <label htmlFor="form_email">
                    OutSide Local Car Rental Price <small className="text-danger">*</small>
                  </label>
                  <input
                    id="form_email"
                    disabled={isLoading}
                    defaultValue={car?.outside_town}
                    {...register("outside_town", { required: true })}
                    className={`form-control ${
                      errors.outside_town ? "border-danger" : ""
                    }`}
                    name="outside_town"
                    type="number"
                    step="0.01"
                    placeholder="Outside Local Car Rental Price Per DAY"
                  />
                  {errors.outside_town && (
                    <p className="text-danger">This field is required.</p>
                  )}
                </div>
              </div>
              <div className="col-sm-12 col-md-4">
                <div className="form-group">
                  <label htmlFor="form_name">
                    Gear Box
                    <small className="text-danger">*</small>
                  </label>
                  <select
                    disabled={isLoading}
                    {...register("gear_type", { required: true })}
                    className={`form-control ${
                      errors.gear_type ? "border-danger" : ""
                    }`}
                    defaultValue={car?.gear_type}
                    name="gear_type">
                    <option value="" selected>
                      Choose Transmission Type
                    </option>
                    <option value="automatic">Automatic Transmission</option>
                    <option value="manual">Manual Transmission</option>
                  </select>
                  {errors.gear_type && (
                    <p className="text-danger">This field is required.</p>
                  )}
                </div>
              </div>
              <div className="col-sm-12">
                <div className="form-group">
                  <label htmlFor="form_name">
                    Car Description <small className="text-danger">*</small>
                  </label>
                  <textarea
                    rows={5}
                    cols={8}
                    id="description"
                    defaultValue={car?.description}
                    disabled={isLoading}
                    {...register("description", { required: true })}
                    className={`form-control ${
                      errors.description ? "border-danger" : ""
                    }`}
                    minLength={200}
                    name="description"
                    placeholder="Car Description"></textarea>
                  {errors.description && (
                    <p className="text-danger">This field is required.</p>
                  )}
                </div>
              </div>
              <div className="pull-right mb-4">
                <button
                  type="submit"
                  className="btn btn-success"
                  disabled={isLoading}>
                  {isLoading && <i className="fa fa-refresh fa-spin" />} Update
                  Car Details
                </button>
              </div>
            </div>
          </div>
        </div>
      </form>
      <EditCarImages carid={car_id} images={car?.car_images} />
    </div>
  );
};

export default EditCar;
