import React from 'react'
import { useNavigate } from 'react-router-dom'

const PageNotFound: React.FC = () => {
  const navigate = useNavigate();
  return (
    <div className='error-page'>
      <div className="main-wrapper">
      <div className="error-box">
        <h1>404</h1>
        <h3 className="h2 mb-3">
          <i className="fas fa-exclamation-circle"></i> Oops! Page not found!
        </h3>
        <p className="h4 font-weight-normal">
          The page you requested was not found.
        </p>
        <button onClick={() => navigate(-1)} className="btn btn-primary">
           <i className="fa fa-arrow-left"></i> Go Back
          </button>
      </div>
    </div>

    </div>
  )
}

export default PageNotFound
