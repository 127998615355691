import React from "react";
import { PropertyModel } from "../../../../models/product_model";

interface ProperyProps {
  property?: PropertyModel;
}

function FloorPlans({ property }: ProperyProps) {
  return (
    <>
      <div className="vesh-detail-bloc">
        <div className="vesh-detail-bloc_header">
          <a
            data-bs-toggle="collapse"
            data-parent="#floorinfo"
            data-bs-target="#floorinfo"
            aria-controls="floorinfo"
            href="javascript:void(0);"
            aria-expanded="false">
            <h4 className="property_block_title">Floor Plan</h4>
          </a>
        </div>
        <div
          id="floorinfo"
          className="panel-collapse"
          aria-labelledby="floorinfo">
          <div className="vesh-detail-bloc-body">
            <ul className="nav nav-pills mb-3" id="pills-tab" role="tablist">
              <li className="nav-item" role="presentation">
                <button
                  className="nav-link active"
                  id="pills-2d-tab"
                  data-bs-toggle="pill"
                  data-bs-target="#pills-2d"
                  type="button"
                  role="tab"
                  aria-controls="pills-2d"
                  aria-selected="true">
                  2D Plans
                </button>
              </li>
              <li className="nav-item" role="presentation">
                <button
                  className="nav-link"
                  id="pills-3d-tab"
                  data-bs-toggle="pill"
                  data-bs-target="#pills-3d"
                  type="button"
                  role="tab"
                  aria-controls="pills-3d"
                  aria-selected="false">
                  3D Plans
                </button>
              </li>
              <li className="nav-item" role="presentation">
                <button
                  className="nav-link"
                  id="pills-elevation-tab"
                  data-bs-toggle="pill"
                  data-bs-target="#pills-elevation"
                  type="button"
                  role="tab"
                  aria-controls="pills-elevation"
                  aria-selected="false">
                  Elevations
                </button>
              </li>
            </ul>
            <div className="tab-content" id="pills-tabContent">
              <div
                className="tab-pane fade show active"
                id="pills-2d"
                role="tabpanel"
                aria-labelledby="pills-2d-tab"
                tabIndex={0}>
                <div className="row gx-3 gy-4">
                  <div className="col-xl-12 col-lg-12 col-md-12">
                    <div className="floor-thumb">
                      <img
                        src={`${process.env.REACT_APP_API_URL}/${property?.floorPlan2D}`}
                        className="img-fluid rounded"
                        style={{ width: "100%", height: "350px" }}
                        alt=""
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div
                className="tab-pane fade"
                id="pills-3d"
                role="tabpanel"
                aria-labelledby="pills-3d-tab"
                tabIndex={0}>
                <div className="row gx-3 gy-4">
                  <div className="col-xl-12 col-lg-12 col-md-12">
                    <div className="floor-thumb">
                      <img
                        src={`${process.env.REACT_APP_API_URL}/${property?.floorPlan3D}`}
                        className="img-fluid rounded"
                        style={{ width: "100%", height: "350px" }}
                        alt=""
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="tab-pane fade"
                id="pills-elevation"
                role="tabpanel"
                aria-labelledby="pills-elevation-tab"
                tabIndex={0}>
                <div className="row gx-3 gy-4">
                  <div className="col-xl-12 col-lg-12 col-md-12">
                    <div className="floor-thumb">
                      <img
                        src={`${process.env.REACT_APP_API_URL}/${property?.floorPlanElevation}`}
                        className="img-fluid rounded"
                        style={{ width: "100%", height: "350px" }}
                        alt=""
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default FloorPlans;
