import React from "react";
import BreadCrumbs from "../widgets/bread_crumbs";
import ChangePassword from "../widgets/change_password";

const ChangeUserPassword: React.FC = () => {
  return (
    <div className="content">
      <BreadCrumbs
        title={"Password"}
        subTitle={"Change Your Password"}
      />
      {/* <!-- ============================ User Dashboard ================================== --> */}
      <section className="gray pt-0 pb-5">
        <div className="col-xl-12 col-lg-12 col-md-12">
        <ChangePassword />
        </div>
      </section>
      {/* <!-- ============================ User Dashboard End ================================== --> */}
    </div>
  );
};

export default ChangeUserPassword;
