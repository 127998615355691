import React from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useAppSelector } from "../../../hooks/redux-hooks";
import dashboard from "../../../assets/img/logo.jpg";
import DashboardAvatar from "../../widgets/dashboard_avatar";
import DashboardFooter from "../../widgets/dsahboard_footer";

const SideBar: React.FC = () => {
  const user = useAppSelector((state) => state.auth.user);
  const location = useLocation();

  return (
    <>
      <div className="sidebar" id="sidebar">
        <div className="sidebar-inner slimscroll">
          <div id="sidebar-menu" className="sidebar-menu">
            <DashboardAvatar />
            <ul>
              <li
                className={`${
                  location.pathname === "/zm/accounts/realestate"
                    ? "active"
                    : ""
                }`}
              >
                <Link
                  className={`${
                    location.pathname === "/zm/accounts/realestate"
                      ? "active"
                      : ""
                  }`}
                  to={`/zm/accounts/realestate?uid=${user?.id}`}
                >
                  <img
                    style={{ borderRadius: "50%" }}
                    src={dashboard}
                    alt="img"
                  />
                  <span>Dashboard</span>
                </Link>
              </li>
              <li
                className={`${
                  location.pathname === "/zm/accounts/realestate/profile/"
                    ? "active"
                    : ""
                }`}
              >
                <Link to="/zm/accounts/realestate/profile/">
                  <i className="fa fa-user-tie"></i>
                  <span>My Profile</span>
                  <span className="menu-arrow"></span>
                </Link>
              </li>
              <li
                className={`${
                  location.pathname === "/zm/accounts/realestate/properties"
                    ? "active"
                    : ""
                }`}
              >
                <Link to="/zm/accounts/realestate/properties">
                  <i className="fa fa-tasks"></i>
                  <span>My Properties</span>
                  <span className="menu-arrow"></span>
                </Link>
              </li>
              <li
                className={`${
                  location.pathname === "/zm/accounts/realestate/add-property"
                    ? "active"
                    : ""
                }`}
              >
                <Link to="/zm/accounts/realestate/add-property">
                  <i className="fa fa-pen-nib"></i>
                  <span>Submit New Property</span>
                  <span className="menu-arrow"></span>
                </Link>
              </li>
              <li
                className={`${
                  location.pathname ===
                  "/zm/accounts/realestate/rented_properties"
                    ? "active"
                    : ""
                }`}
              >
                <Link to="/zm/accounts/realestate/rented_properties">
                  <i className="fa fa-bookmark"></i>
                  <span>Rented Properties</span>
                  <span className="notti_coun style-2">0</span>
                  <span className="menu-arrow"></span>
                </Link>
              </li>
              <li
                className={`${
                  location.pathname === "/zm/accounts/realestate/messages"
                    ? "active"
                    : ""
                }`}
              >
                <Link to="/zm/accounts/realestate/messages">
                  <i className="fa fa-envelope"></i>
                  <span>Messages</span>
                  <span className="notti_coun style-3">0</span>
                  <span className="menu-arrow"></span>
                </Link>
              </li>
              <li
                className={`${
                  location.pathname === "/zm/accounts/realestate/customers"
                    ? "active"
                    : ""
                }`}
              >
                <Link to="/zm/accounts/realestate/customers">
                  <i className="fa fa-users"></i>
                  <span>Customers</span>
                  <span className="notti_coun style-1">0</span>
                  <span className="menu-arrow"></span>
                </Link>
              </li>
              <li
                className={`${
                  location.pathname ===
                  "/zm/accounts/realestate/change-password"
                    ? "active"
                    : ""
                }`}
              >
                <Link to="/zm/accounts/realestate/change-password">
                  <i className="fa fa-unlock-alt"></i>
                  <span>Change Password</span>
                  <span className="menu-arrow"></span>
                </Link>
              </li>
            </ul>
            <DashboardFooter />
          </div>
        </div>
      </div>
    </>
  );
};

export default SideBar;
