import { apiSlice } from "../services/apiSlice";
import { TFuelModel } from "../types/fuel_mode.type";

export const fuelApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    createFuel: builder.mutation<TFuelModel, Partial<TFuelModel>>({
      query(data) {
        return {
          url: `/administrator/v1/everyday/admin_fuels/tech/`,
          method: "POST",
          body: data,
        };
      },
      invalidatesTags: ["TFuelModel"],
      transformResponse: (result: { item: TFuelModel }) =>
        result.item,
    }),
    updateFuel: builder.mutation<TFuelModel, Partial<TFuelModel>& Pick<TFuelModel, 'id'>>({
      query: ({ id, ...patch }) => ({
        url: `/administrator/v1/everyday/admin_fuels/tech/${id}/`,
        method: "PATCH",
        body: patch,
      }),
      invalidatesTags: ["TFuelModel"],
      transformResponse: (response: { item: TFuelModel }) =>
        response.item,
    }),
    getFuel: builder.query<TFuelModel, number>({
      query: (id) => ({
        url: `/administrator/v1/everyday/admin_fuels/tech/${id}/`,
      }),
      providesTags: ["TFuelModel"],
    }),
    getFuelList: builder.query<TFuelModel[], any>({
      query: ({supplier, status, page, searchQuery}) => `/administrator/v1/everyday/admin_fuels/tech/?supplier__id=${supplier}&status=${status}&page=${page}&search=${searchQuery}`,
      // transformResponse: (res: TFuelModel[]) =>
      //   res.sort((a, b) => b.id - a.id),
      //   providesTags: (result) =>
      //   result ? result.map(({ id }) => ({ type: 'TFuelModel', id })) : ['TFuelModel'],
      // keepUnusedDataFor: 5,
    }),
    deleteFuel: builder.mutation<TFuelModel, number>({
      query: (id) => ({
        url: `/administrator/v1/everyday/admin_fuels/tech/${id}/`,
        method: "DELETE",
      }),
      invalidatesTags: ["TFuelModel"],
    }),
  }),
});
export const {
  useCreateFuelMutation,
  useDeleteFuelMutation,
  useGetFuelListQuery,
  useGetFuelQuery,
  useUpdateFuelMutation,
} = fuelApiSlice;
