import React from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { ICarRental } from "../../../../redux/types/car_rental.type";

interface ICarDetails {
  item: ICarRental;
  show: boolean;
  handleClose: () => void;
}

const CarDetailsModal: React.FC<ICarDetails> = ({
  item,
  show,
  handleClose,
}) => {
  return (
    <>
      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        scrollable
        size="xl"
        centered={true}
        keyboard={false}>
        <Modal.Header closeButton>
          <Modal.Title className="text-capitalize">{item.car_model}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <>
            <div className="row">
              <div className="col-md-6 shadow rounded">
                <h4 className="text-center">Car Details</h4>
                <h6 className="text-capitalize">Car: {item.car_model}</h6>
                <h6 className="text-capitalize">Model: {item.car_model}</h6>
                <h6>Number Plate: {item?.number_plate}</h6>
                <h6 className="text-capitalize">
                  Transmission: {item.gear_type}
                </h6>
                <h6>Seats: {item.seats}</h6>
                <h6>Speedometer: {item.speedometer}</h6>
                <h6>Fuel Tank Litres: {item.fuel_litres}L</h6>
                <h6>
                  Local Rental Price: ZMW {item.local_price.toLocaleString()} Per Day
                  Outside Town Rental Price: ZMW {item.outside_town.toLocaleString()} Per Day
                </h6>
                STATUS:
                {item.is_available ? (
                  <span className="badges bg-lightgreen text-white">
                    AVAILABLE
                  </span>
                ) : (
                  <span className="badges bg-lightred text-white">
                    NOT AVAILABLE
                  </span>
                )}
              </div>
              <div className="col-md-6">
                <div className="row">
                  {item.car_images?.map((data, index) => {
                    return (
                      <div key={index} className="col-md-4 shadow">
                        <img
                          height={200}
                          width={200}
                          className="rounded img-responsive"
                          src={`${data?.image}`}
                          alt={item.car_model}
                        />
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleClose}>
                Close
              </Button>
            </Modal.Footer>
          </>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default CarDetailsModal;
