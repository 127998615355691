import React from "react";
import { Outlet } from "react-router-dom";
import TopNav from "../navigation/top_nav";
import SideBar from "../navigation/sidebar";

const AgricultureMainLayout: React.FC = () => {
  return (
    <>
    <div className="main-wrapper">
          <TopNav />
          <SideBar />
          <div className="page-wrapper">
            <Outlet />
        </div>
      </div>
    </>
  );
};

export default AgricultureMainLayout;
