import { apiSlice } from "../services/apiSlice";
import { TMineralCagetory } from "../types/mineral_category.type";

export const mineralCategorySlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    createMineralCategory: builder.mutation<TMineralCagetory, Partial<TMineralCagetory>>({
      query(data) {
        return {
          url: `/administrator/v1/everyday/admin_mineral_category/tech`,
          method: "POST",
          body: data,
        };
      },
      invalidatesTags: ["TMineralCagetory"],
      transformResponse: (result: { agri: TMineralCagetory }) =>
        result.agri,
    }),
    updateMineralCategory: builder.mutation<TMineralCagetory,{ id: any; agri: TMineralCagetory }>({
      query: ({ id, agri }) => ({
        url: `/administrator/v1/everyday/admin_mineral_category/tech/${id}/`,
        method: "PATCH",
        body: agri,
      }),
      invalidatesTags: ["TMineralCagetory"],
      transformResponse: (response: { agri: TMineralCagetory }) =>
        response.agri,
    }),
    geTMineralCagetory: builder.query<TMineralCagetory, number>({
      query: (id) => ({
        url: `/administrator/v1/everyday/admin_mineral_category/tech/${id}/`,
      }),
      providesTags: ["TMineralCagetory"],
    }),
    geTMineralCagetoryList: builder.query<TMineralCagetory[], void>({
      query: () => `/administrator/v1/everyday/admin_mineral_category/tech/`,
      transformResponse: (res: TMineralCagetory[]) =>
        res.sort((a, b) => b.id - a.id),
        providesTags: (result) =>
        result ? result.map(({ id }) => ({ type: 'TMineralCagetory', id })) : ['TMineralCagetory'],
      // keepUnusedDataFor: 5,
    }),
    deleteMineralCategory: builder.mutation<TMineralCagetory, number>({
      query: (id) => ({
        url: `/administrator/v1/everyday/admin_mineral_category/tech/${id}/`,
        method: "DELETE",
      }),
      invalidatesTags: ["TMineralCagetory"],
    }),
  }),
});
export const {
  useCreateMineralCategoryMutation,
  useDeleteMineralCategoryMutation,
  useGeTMineralCagetoryListQuery,
  useGeTMineralCagetoryQuery,
  useUpdateMineralCategoryMutation
} = mineralCategorySlice;
