import React from "react";
import plusIcon from "../../../../assets/img/icons/plus.svg";
import searchIcon from "../../../../assets/img/icons/search.svg";
import pdf from "../../../../assets/img/icons/pdf.svg";
import excel from "../../../../assets/img/icons/excel.svg";
import printer from "../../../../assets/img/icons/printer.svg";
import deleteIcon from "../../../../assets/img/icons/delete.svg";
import editIcon from "../../../../assets/img/icons/eye1.svg";

const PaymentSettings: React.FC = () => {
  return (
    <>
      <div className="content">
        <div className="page-header">
          <div className="page-title">
            <h4>Payment Settings</h4>
            <h6>Manage Payment Settings</h6>
          </div>
          <div className="page-btn">
            <a
              className="btn btn-added"
            >
              <img src={plusIcon} alt="img" className="me-2" />
              Add Payment Settings
            </a>
          </div>
        </div>

        <div className="card">
          <div className="card-body">
            <div className="table-top">
              <div className="search-set">
                <div className="search-input">
                  <a className="btn btn-searchset">
                    <img src={searchIcon} alt="img" />
                  </a>
                </div>
              </div>
              <div className="wordset">
                <ul>
                  <li>
                    <a
                      data-bs-toggle="tooltip"
                      data-bs-placement="top"
                      title="pdf"
                    >
                      <img src={pdf} alt="img" />
                    </a>
                  </li>
                  <li>
                    <a
                      data-bs-toggle="tooltip"
                      data-bs-placement="top"
                      title="excel"
                    >
                      <img src={excel} alt="img" />
                    </a>
                  </li>
                  <li>
                    <a
                      data-bs-toggle="tooltip"
                      data-bs-placement="top"
                      title="print"
                    >
                      <img src={printer} alt="img" />
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="table-responsive">
              <table className="table datanew">
                <thead>
                  <tr>
                    <th>#</th>
                    <th>Payment Type Name</th>
                    <th>Status</th>
                    <th className="text-end">Action</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>1</td>
                    <td>Cash</td>
                    <td>
                      <div className="status-toggle d-flex justify-content-between align-items-center">
                        <input
                          type="checkbox"
                          id="user1"
                          className="check"
                          checked
                        />
                        <label htmlFor="user1" className="checktoggle">
                          checkbox
                        </label>
                      </div>
                    </td>
                    <td className="text-end">
                      <span>
                        <a className="me-3" href="#">
                          <img src={editIcon} alt="img" />
                        </a>
                        <a
                          className="me-3 confirm-text"
                          href="javascript:void(0);"
                        >
                          <img src={deleteIcon} alt="img" />
                        </a>
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <td>2</td>
                    <td>Credit</td>
                    <td>
                      <div className="status-toggle d-flex justify-content-between align-items-center">
                        <input type="checkbox" id="user2" className="check" />
                        <label htmlFor="user2" className="checktoggle">
                          checkbox
                        </label>
                      </div>
                    </td>
                    <td className="text-end">
                      <span>
                        <a className="me-3" href="#">
                          <img src={editIcon} alt="img" />
                        </a>
                        <a
                          className="me-3 confirm-text"
                          href="javascript:void(0);"
                        >
                          <img src={deleteIcon} alt="img" />
                        </a>
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <td>3</td>
                    <td>Mobile Money</td>
                    <td>
                      <div className="status-toggle d-flex justify-content-between align-items-center">
                        <input
                          type="checkbox"
                          id="user3"
                          className="check"
                          checked
                        />
                        <label htmlFor="user3" className="checktoggle">
                          checkbox
                        </label>
                      </div>
                    </td>
                    <td className="text-end">
                      <span>
                        <a className="me-3" href="#">
                          <img src={editIcon} alt="img" />
                        </a>
                        <a
                          className="me-3 confirm-text"
                          href="javascript:void(0);"
                        >
                          <img src={deleteIcon} alt="img" />
                        </a>
                      </span>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PaymentSettings;
