import React from 'react'
import { User } from '../../../../../redux/types/user';
import { useGetUserQuery } from '../../../../../redux/slices/usersApiSlice';
import LoadingSpinner from '../../../../../components/widgets/spinner';
import BreadCrumbs from '../../../../widgets/bread_crumbs';
import { dailyData, monthlyData } from '../../../../utils/data_charts';
import BranchCharts from '../../../reports/branch_charts';
import { useGetAgroOrdersListQuery } from '../../../../../redux/slices/agro_order_slice';
import { TAgroOrder } from '../../../../../redux/types/agro_order.type';
import moment from 'moment';

const AgroCompanyDetails: React.FC = () => {
    const queryParams = new URLSearchParams(window.location.search);
    const company_id = queryParams.get("company_id");
  
    const {
      data: userDetails,
      isLoading: isFetching,
      error,
    }: User | any = useGetUserQuery(Number(company_id));

    const {
        data: agroList,
        isLoading: fetchingConfirmed,
      }: TAgroOrder | any = useGetAgroOrdersListQuery({
        status: "",
        user: Number(company_id),
      });
  
    if (isFetching || fetchingConfirmed) {
      return (
        <div style={{ marginTop: "20%" }} className="text-center">
          <LoadingSpinner />
        </div>
      );
    }
  return (
    <div className='content'>
        <BreadCrumbs title={'Company Details'} subTitle={`View ${userDetails?.company_name}`} />
        <div className="row">
        <div className="col-md-5">
            <h3>Agriculture Daily Sales</h3>
            <BranchCharts width={1000} height={250} data={dailyData} />
          </div>
          <div className="col-md-7">
            <h3>Agriculture Monthly Sales</h3>
            <BranchCharts width={1000} height={250} data={monthlyData} />
          </div>
        </div>
        <div className="col-md-12">
            
        <div className="card mb-5 mb-md-0">
        <h4 className='m-3 pr-2'><b>Product Sales</b></h4>
          {agroList.length === 0 ? (
            <center>
              <div
                className="text-center alert alert-info mt-3"
                role="alert"
                style={{ width: "fit-content" }}
              >
                <h1 className="mt-3">NO SALES FOUND</h1>
              </div>
            </center>
          ) : (
            <div className="card-body table-responsive">
              <table className="table table-hover">
                <thead>
                  <tr>
                    <th>#</th>
                    <th>Product</th>
                    <th>Product Price</th>
                    <th>Name</th>
                    <th>E-mail</th>
                    <th>Phone Number</th>
                    <th>Qty</th>
                    <th>Paid</th>
                    <th>Date</th>
                    <th>Status</th>
                  </tr>
                </thead>

                <tbody>
                  {agroList
                    .map((item: TAgroOrder, index: number) => (
                      <tr key={index}>
                        <td>{index + 1}</td>
                        <td className="text-capitalize">{item.product.productName.substring(0, 12)}</td>
                        <td>ZMW {item.product.productPrice.toLocaleString()}</td>
                        <td className="text-capitalize">
                          {item.customer.first_name} {item.customer.last_name}
                        </td>
                        <td>{item.customer.email}</td>
                        <td>{item.customer.phoneNumber}</td>
                        <td>{item.quantity}/{item.unit}</td>
                        <td>ZMW {item.productPrice.toLocaleString()}</td>
                        <td>{moment(item.created_at).format("MMM do YYYY")}</td>
                        <td>
                          <div className="badges bg-lightred text-white text-capitalize">
                            <b>{item.status}</b>
                          </div>
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default AgroCompanyDetails