import React from "react";
import close from "../../../assets/img/icons/closes.svg";
import search from "../../../assets/img/icons/search.svg";
import logoutIcon from "../../../assets/img/icons/log-out.svg";
import { useAppSelector, useAppDispatch } from "../../../hooks/redux-hooks";
import { resetUserProfile } from "../../../redux/features/profile/profile";
import { useLogoutMutation } from "../../../redux/slices/authApiSlice";
import { logout as setLogout } from "../../../redux/features/authSlice";
import { Link } from "react-router-dom";
import AgricNotificationWidget from "./agric_notification_widget";

const TopNav: React.FC = () => {
  const user = useAppSelector((state) => state.auth.user);
  // Handle logout
  const dispatch = useAppDispatch();
  const [logout] = useLogoutMutation();
  // const { isAuthenticated } = useAppSelector((state) => state.auth);

  const handleLogout = () => {
    dispatch(setLogout());
    dispatch(resetUserProfile());
    // logout(undefined)
    //   .unwrap()
    //   .then(() => {
    //     dispatch(setLogout());
    //     dispatch(resetUserProfile());
    //   });
    window.location.href = "/";
  };
  return (
    <>
      <div className="header">
        <div className="header-left active">
          <a href="/" className="logo">
            <img
              style={{ width: "50px", borderRadius: "50%" }}
              src={require("../../../assets/img/logo.jpg")}
              alt=""
            />
          </a>
          <a href="/" className="logo-small">
            <img
              style={{ borderRadius: "50%" }}
              src={require("../../../assets/img/logo.jpg")}
              alt=""
            />
          </a>
          <a id="toggle_btn" href="javascript:void(0);">
            {" "}
          </a>
        </div>

        <a id="mobile_btn" className="mobile_btn" href="#sidebar">
          <span className="bar-icon">
            <span></span>
            <span></span>
            <span></span>
          </span>
        </a>

        <ul className="nav user-menu">
          <li className="nav-item">
            <div className="top-nav-search">
              <a href="javascript:void(0);" className="responsive-search">
                <i className="fa fa-search"></i>
              </a>
              <form action="#">
                <div className="searchinputs">
                  <input type="text" placeholder="Search Here ..." />
                  <div className="search-addon">
                    <span>
                      <img src={close} alt="img" />
                    </span>
                  </div>
                </div>
                <a className="btn" id="searchdiv">
                  <img src={search} alt="img" />
                </a>
              </form>
            </div>
          </li>

          <AgricNotificationWidget/>

          <li className="nav-item dropdown has-arrow main-drop">
            <a
              href="javascript:void(0);"
              className="dropdown-toggle nav-link userset"
              data-bs-toggle="dropdown"
            >
              <span className="user-img">
                <img
                  src={
                    user?.user_profile?.profile_picture === undefined
                      ? "https://intercitytravellers.com/media/profilepic/user.png"
                      : `${process.env.REACT_APP_API_URL}${user?.user_profile?.profile_picture}`
                  }
                  alt=""
                />
                <span className="status online"></span>
              </span>
            </a>
            <div className="dropdown-menu menu-drop-user">
              <div className="profilename">
                <div className="profileset">
                  <span className="user-img">
                    <img
                      src={
                        user?.user_profile?.profile_picture === undefined
                          ? "https://intercitytravellers.com/media/profilepic/user.png"
                          : `${process.env.REACT_APP_API_URL}${user?.user_profile?.profile_picture}`
                      }
                      alt=""
                    />
                  </span>
                  <div className="profilesets">
                    <h6 className="text-capitalize">{user?.company_name}</h6>
                    <h5>Admin</h5>
                  </div>
                </div>
                <hr className="m-0" />
                <Link className="dropdown-item" to="/zm/agriculture/profile/">
                  <i className="me-2" data-feather="user"></i> My Profile
                </Link>
                <hr className="m-0" />
                <a
                  style={{ cursor: "pointer" }}
                  className="dropdown-item logout pb-0"
                  onClick={handleLogout}
                >
                  <img src={logoutIcon} className="me-2" alt="img" />
                  Logout
                </a>
              </div>
            </div>
          </li>
        </ul>

        <div className="dropdown mobile-user-menu">
          <a
            href="javascript:void(0);"
            className="nav-link dropdown-toggle"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            <i className="fa fa-ellipsis-v"></i>
          </a>
          <div className="dropdown-menu dropdown-menu-right">
            <Link className="dropdown-item" to="/zm/agriculture/profile/">
              My Profile
            </Link>
            {/* <Link className="dropdown-item" to="generalsettings">
              Settings
            </Link> */}
            <a onClick={handleLogout} className="dropdown-item">
              Logout
            </a>
          </div>
        </div>
      </div>
    </>
  );
};

export default TopNav;
