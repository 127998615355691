import React from "react";
import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { useAppSelector } from "../../hooks/redux-hooks";
import { useUpdateUserPasswordMutation } from "../../redux/slices/usersApiSlice";

const ChangePassword: React.FC = () => {
  const profileData = useAppSelector((state) => state.auth.user);
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();
  const [updateUserPassword, { isLoading, error }] = useUpdateUserPasswordMutation();

  const onSubmit = (data: any) => {
    updateUserPassword({ id: Number(profileData?.id), ...data })
      .unwrap()
      .then((res) => {
        reset();
        toast.success("Password Changed Successfully");
      }).catch((err) => {
        toast.error("Error: " + err.data['old_password']);
      })  
         
    
    
        
  };

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)} className="mt-5 mb-3">
        <h3 className="text-center mb-3">Change Password</h3>
        <Row>
          <Form.Group
            className="mb-3"
            as={Col}
            md="6"
            {...register("old_password", { required: true })}
            controlId="validationCustom03">
            <Form.Label>Current Password</Form.Label>
            <Form.Control
              type="password"
              disabled={isLoading}
              {...register("old_password", { required: true })}
              placeholder="Current Password"
            />
            {errors.old_password && (
              <p className="text-danger">This field is required.</p>
            )}
          </Form.Group>
          <Form.Group
            className="mb-3"
            as={Col}
            md="6"
            controlId="validationCustom04">
            <Form.Label>New Password</Form.Label>
            <Form.Control
            disabled={isLoading}
              type="password"
              {...register("new_password", { required: true })}
              placeholder="New Password"
            />
            {errors.new_password && (
              <p className="text-danger">This field is required.</p>
            )}
          </Form.Group>
          <Form.Group controlId="validationCustom05">
            <Form.Label>Confirm New Password</Form.Label>
            <Form.Control
              type="password"
              disabled={isLoading}
              {...register("new_password", { required: true })}
              placeholder="Retype New Password"
            />
            {errors.new_password && (
              <p className="text-danger">This field is required.</p>
            )}
          </Form.Group>

          <Form.Group className="mt-3">
            <div className="ms-auto text-center">
              <button type="submit" className="btn btn-info me-2">
                {isLoading && (
                  <div className="spinner-border spinner-border-sm text-danger"></div>
                )}{" "}
                Update Password
              </button>
            </div>
          </Form.Group>
        </Row>
      </form>
    </>
  );
};

export default ChangePassword;
