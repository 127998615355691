import React from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { toast } from "react-toastify";
import { Spinner } from "react-bootstrap";
import { useDeleteUserMutation } from "../../../../../redux/slices/usersApiSlice";
import { User } from "../../../../../redux/types/user";

type TProps = {
  user: User;
  show: boolean;
  handleClose: () => void;
  setShow: (show: boolean) => void;
};

const DeleteUserModal: React.FC<TProps> = ({
  user,
  show,
  handleClose,
  setShow,
}) => {
  const [deleteUser, { isLoading: deleting, error: errorDeleting, isSuccess }] =
    useDeleteUserMutation();

  const onDelete = () => {
    deleteUser(user.id)
      .unwrap()
      .then((res) => {
        setShow(false);
        toast.success("User Deleted Successfully");
      })
      .catch((err) => {
        toast.error("An error occurred try again");
      });
  };

  return (
    <>
      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Delete</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <center>
            <i className="fa fa-user-times fa-5x text-danger"></i>
          </center>
          <h3 className="text-center mt-3 mb-3">Are you sure you want to delete <br />
          <b className="text-capitalize">{user?.company_name}</b></h3>
          <p className="text-danger">
            This action can't be undone and all the users information and
            products will be delete and can't be retrieved
          </p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" disabled={deleting} onClick={handleClose}>
            Close
          </Button>
          <Button disabled={deleting} onClick={onDelete} variant="danger">
            {deleting && <Spinner size="sm" />} Delete
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default DeleteUserModal;
