import { User } from "../../types/user";
import { PayloadAction, createSlice } from "@reduxjs/toolkit";


const initialState = {
    id: 0,
    email: "",
    first_name: "",
    last_name: "",
    user_type: "",
    system_access: [{system_name:""}],
    } as User;

const profileSlice = createSlice({
    name: "profile",
    initialState,
    reducers:{
        setUserProfile: (state, action: PayloadAction<Partial<User>>) => {
            return { ...state, ...action.payload };
          },

        resetUserProfile: () => {
            return initialState; // Reset to the initial state
        },
   
        

    }
})

export const { setUserProfile, resetUserProfile } = profileSlice.actions;
export default profileSlice.reducer;
