import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import httpProductServices from "../../services/http.product.services";
import { toast } from "react-toastify";
import { useAppSelector } from "../../../hooks/redux-hooks";
import { Utilities } from "../../../redux/types/utilities.type";
import { DragAndDrop } from "../../widgets/DragNdrop";

const FormLandForm: React.FC = () => {
  const user = useAppSelector((state) => state.auth.user);
  const [files, setFiles] = React.useState<string[]>([]);
  const [submiting, setSubmiting] = useState(false);
  const [utilities, setUtilities] = useState<Utilities[]>([
    { name: "" },
    { name: "" },
    { name: "" },
  ]);
  const [amenities, setAmenities] = useState<Utilities[]>([
    { name: "" },
    { name: "" },
    { name: "" },
  ]);
  const [outDoorDetails, setOutDoorDetails] = useState<Utilities[]>([
    { name: "" },
    { name: "" },
    { name: "" },
  ]);
  const [interialDetails, setInterialDetails] = useState<Utilities[]>([
    { name: "" },
    { name: "" },
    { name: "" },
  ]);
  
  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, []);



  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();


  const handleFormSubmit = async (data: any): Promise<void> => {    
    const uid: any = user?.id;
    let form_data = new FormData();
    files.forEach((image, index) => {
      form_data.append(`property_image`, image);
    });
    form_data.append("user", uid);
    form_data.append("propertyPrice", data.propertyPrice);
    form_data.append("propertyDescription", data.propertyDescription);
    form_data.append("contactName", data.contactName);
    form_data.append("contactEmail", data.contactEmail);
    form_data.append("phoneNumber", data.phoneNumber);
    form_data.append("propertyName", data.propertyName);
    form_data.append("propertyLabel", data.propertyLabel);
    form_data.append("propertyLocation", data.propertyAddress);
    form_data.append("propertyType", data.propertyType);
    form_data.append("propertyCity", data.propertyCity);
    form_data.append("propertyCountry", data.propertyCountry);
    form_data.append("zipCode", data.zipCode);
    form_data.append("squareFeet", data.squareFeet);
    form_data.append("propertyAddress", data.propertyAddress);
    form_data.append("yearBuilt", "2024-01-20");
    form_data.append("bedrooms", "0");
    form_data.append("bathrooms", "0");
    form_data.append("garages", "0");
    form_data.append("propertyAmenities", JSON.stringify([...amenities]));
    form_data.append("propertyUtilities", JSON.stringify([...utilities]));
    form_data.append("propertyOutdoorDetails", JSON.stringify([...outDoorDetails]));
    form_data.append("propertyInteriorDetails",JSON.stringify([...interialDetails]));
    form_data.append("image", files[0]);

    if (files.length > 3) {
      setSubmiting(true);
      httpProductServices
      .createProperty(form_data)
      .then((response) => {
        if (response.status === 201) {
          setSubmiting(false);
          toast.success("Property Added Successfully");
        }
      })
      .catch((error: any) => {
        setSubmiting(false);
        toast.error(error.message);
      });
    } else {
      toast.error("Please select more than 5 images")
    }
    // reset();
  };

  return (
    <>
      {/* <!-- ============================ User Dashboard ================================== --> */}
      <section className="gray pt-0 pb-5">
        <div className="col-xl-12 col-lg-12 col-md-12">
          <div className="dashboard-body">
            <div className="dashboard-wraper">
            <h3 className="mt-3 mb-3 text-center">Farm/Land Listing</h3>
              <div className="row">
                {/* <!-- Submit Form --> */}
                <div className="col-lg-12 col-md-12">
                  <form onSubmit={handleSubmit(handleFormSubmit)}>
                    <div className="submit-page">
                      {/* <!-- Basic Information --> */}
                      <div className="frm_submit_block">
                        <h3>Basic Information</h3>
                        <div className="frm_submit_wrap">
                          <div className="row">
                            <div className="form-group col-md-6">
                              <label>
                                Property Title
                                
                              </label>
                              <input
                                type="text"
                                disabled={submiting}
                                placeholder="Property Title"
                                {...register("propertyName", {
                                  required: "Property Name is required!",
                                })}
                                className={`form-control ${
                                  errors.propertyName ? "border-danger" : ""
                                }`}
                              />
                              {errors.propertyName && (
                                <p
                                  className="text-danger"
                                  style={{ fontSize: 14 }}
                                >
                                  {errors.propertyName.message?.toString()}
                                </p>
                              )}
                            </div>

                            <div className="form-group col-md-6">
                              <label>Property Label</label>
                              <select
                                disabled={submiting}
                                {...register("propertyLabel", {
                                  required: "Property Label is required!",
                                })}
                                id="status"
                                className={`form-control ${
                                  errors.propertyLabel ? "border-danger" : ""
                                }`}
                              >
                                <option value="">Select Property Label</option>
                                <option value="rent">For Rent</option>
                                <option value="sale">For Sale</option>
                              </select>
                              {errors.propertyLabel && (
                                <p
                                  className="text-danger"
                                  style={{ fontSize: 14 }}
                                >
                                  {errors.propertyLabel.message?.toString()}
                                </p>
                              )}
                            </div>
                            <div className="form-group col-md-6">
                              <label>Property Type</label>
                              <select
                                disabled={submiting}
                                {...register("propertyType", {
                                  required: "Property Type is required!",
                                })}
                                id="ptypes"
                                className={`form-control ${
                                  errors.propertyType ? "border-danger" : ""
                                }`}
                              >
                                <option value="">Select Property Type</option>
                                <option value="lands">Land</option>
                                <option value="farms">Farm</option>
                              </select>
                              {errors.propertyType && (
                                <p
                                  className="text-danger"
                                  style={{ fontSize: 14 }}
                                >
                                  {errors.propertyType.message?.toString()}
                                </p>
                              )}
                            </div>
                            <div className="form-group col-md-6">
                              <label>Price</label>
                              <input
                                type="text"
                                disabled={submiting}
                                {...register("propertyPrice", {
                                  required: "Property Price is required!",
                                })}
                                className={`form-control ${
                                  errors.propertyPrice ? "border-danger" : ""
                                }`}
                                placeholder="ZMW"
                              />
                              {errors.propertyPrice && (
                                <p
                                  className="text-danger"
                                  style={{ fontSize: 14 }}
                                >
                                  {errors.propertyPrice.message?.toString()}
                                </p>
                              )}
                            </div>

                            <div className="form-group col-md-6">
                              <label>Area</label>
                              <input
                                type="text"
                                disabled={submiting}
                                placeholder="Area"
                                {...register("areaLocation", {
                                  required: "Property Area is required!",
                                })}
                                className={`form-control ${
                                  errors.areaLocation ? "border-danger" : ""
                                }`}
                              />
                              {errors.areaLocation && (
                                <p
                                  className="text-danger"
                                  style={{ fontSize: 14 }}
                                >
                                  {errors.areaLocation.message?.toString()}
                                </p>
                              )}
                            </div>

                            <div className="form-group col-md-6">
                              <label>
                                Square Feet
                                
                              </label>
                              <input
                                type="text"
                                disabled={submiting}
                                placeholder="Square Feet"
                                {...register("squareFeet", {
                                  required: "Property Square Feet is required!",
                                })}
                                className={`form-control ${
                                  errors.squareFeet ? "border-danger" : ""
                                }`}
                              />
                              {errors.squareFeet && (
                                <p
                                  className="text-danger"
                                  style={{ fontSize: 14 }}
                                >
                                  {errors.squareFeet.message?.toString()}
                                </p>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>

                      {/* <!-- Gallery --> */}
                      <div
                        style={{
                          backgroundColor: "lightgray",
                        }}
                        className="frm_submit_block"
                      >
                        <h3 className="text-center">Gallery</h3>
                        <div className="frm_submit_wrap">
                        <DragAndDrop
                      heading={"Land/Farm Image Gallery"}
                      onChange={(e: any) => {
                        if (e.target.files && e.target.files[0]) {
                          for (let i = 0; i < e.target.files["length"]; i++) {
                            setFiles((prevState: any) => [
                              ...prevState,
                              e.target.files[i],
                            ]);
                          }
                        }
                      }}
                      setFiles={setFiles}
                      files={files}
                    />
                        </div>
                      </div>
                      <div className="form-group col-md-12">
                        <label>Property Description</label>
                        <textarea
                          disabled={submiting}
                          placeholder="Property Description"
                          {...register("propertyDescription", {
                            required: "Property Description is required!",
                            // minLength: 500
                          })}
                          className={`form-control h-120 ${
                            errors.propertyDescription
                              ? "border-danger"
                              : ""
                          }`}
                        ></textarea>
                        {errors.propertyDescription && (
                          <p
                            className="text-danger"
                            style={{ fontSize: 14 }}
                          >
                            {errors.propertyDescription.message?.toString()}
                          </p>
                        )}
                      </div>
                      {/* <!-- Location --> */}
                      <div className="frm_submit_block">
                        <h3>Location</h3>
                        <div className="frm_submit_wrap">
                          <div className="row">
                            <div className="form-group col-md-6">
                              <label>Address</label>
                              <input
                                type="text"
                                placeholder="Address"
                                disabled={submiting}
                                {...register("propertyAddress", {
                                  required: "Property Address is required!",
                                })}
                                className={`form-control ${
                                  errors.propertyAddress ? "border-danger" : ""
                                }`}
                              />
                              {errors.propertyAddress && (
                                <p
                                  className="text-danger"
                                  style={{ fontSize: 14 }}
                                >
                                  {errors.propertyAddress.message?.toString()}
                                </p>
                              )}
                            </div>

                            <div className="form-group col-md-6">
                              <label>City</label>
                              <input
                                type="text"
                                disabled={submiting}
                                placeholder="City"
                                {...register("propertyCity", {
                                  required: "Property City is required!",
                                })}
                                className={`form-control ${
                                  errors.propertyCity ? "border-danger" : ""
                                }`}
                              />
                              {errors.propertyCity && (
                                <p
                                  className="text-danger"
                                  style={{ fontSize: 14 }}
                                >
                                  {errors.propertyCity.message?.toString()}
                                </p>
                              )}
                            </div>

                            <div className="form-group col-md-6">
                              <label>State</label>
                              <input
                                type="text"
                                disabled={submiting}
                                placeholder="State"
                                {...register("propertyCountry", {
                                  required: "Property State is required!",
                                })}
                                className={`form-control ${
                                  errors.propertyCountry ? "border-danger" : ""
                                }`}
                              />
                              {errors.propertyCountry && (
                                <p
                                  className="text-danger"
                                  style={{ fontSize: 14 }}
                                >
                                  {errors.propertyCountry.message?.toString()}
                                </p>
                              )}
                            </div>

                            <div className="form-group col-md-6">
                              <label>Zip Code</label>
                              <input
                                type="text"
                                disabled={submiting}
                                placeholder="Enter Zip Code"
                                {...register("zipCode", {
                                  required: "Property Zip Code is required!",
                                })}
                                className={`form-control ${
                                  errors.zipCode ? "border-danger" : ""
                                }`}
                              />
                              {errors.propertyZipCode && (
                                <p
                                  className="text-danger"
                                  style={{ fontSize: 14 }}
                                >
                                  {errors.propertyZipCode.message?.toString()}
                                </p>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                      {/* <!-- Contact Information --> */}
                      <div className="frm_submit_block">
                        <h3>Contact Information</h3>
                        <div className="frm_submit_wrap">
                          <div className="row">
                            <div className="form-group col-md-4">
                              <label>Name</label>
                              <input
                                type="text"
                                placeholder="property contact Name"
                                defaultValue={`${user?.first_name} ${user?.last_name}`}
                                disabled={submiting}
                                {...register("contactName", {
                                  required:
                                    "Property Contact Name Code is required!",
                                })}
                                className={`form-control ${
                                  errors.contactName ? "border-danger" : ""
                                }`}
                              />
                              {errors.contacName && (
                                <p
                                  className="text-danger"
                                  style={{ fontSize: 14 }}
                                >
                                  {errors.contacName.message?.toString()}
                                </p>
                              )}
                            </div>

                            <div className="form-group col-md-4">
                              <label>Email</label>
                              <input
                                type="email"
                                defaultValue={user?.email}
                                placeholder="property contact email"
                                disabled={submiting}
                                {...register("contactEmail", {
                                  required: "Property Zip Code is required!",
                                })}
                                className={`form-control ${
                                  errors.contactEmail ? "border-danger" : ""
                                }`}
                              />
                              {errors.email && (
                                <p
                                  className="text-danger"
                                  style={{ fontSize: 14 }}
                                >
                                  {errors.email.message?.toString()}
                                </p>
                              )}
                            </div>

                            <div className="form-group col-md-4">
                              <label>Phone </label>
                              <input
                                type="text"
                                disabled={submiting}
                                defaultValue={user?.phoneNumber}
                                placeholder="property Contact Number"
                                {...register("phoneNumber", {
                                  required:
                                    "Property Phone Number is required!",
                                })}
                                className={`form-control ${
                                  errors.phoneNumber ? "border-danger" : ""
                                }`}
                              />
                              {errors.phoneNumber && (
                                <p
                                  className="text-danger"
                                  style={{ fontSize: 14 }}
                                >
                                  {errors.phoneNumber.message?.toString()}
                                </p>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="form-group col-lg-12 col-md-12">
                        <div className="form-check form-check-inline">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            id="gdpr"
                          />
                          <label className="form-check-label" htmlFor="gdpr">
                            I consent to having this website store my submitted
                            information so they can respond to my inquiry.
                          </label>
                        </div>
                      </div>

                      <div className="form-group col-lg-12 col-md-12">
                        <button
                          disabled={submiting || files.length <3}
                          className="btn btn-primary"
                          type="submit"
                        >
                          {files.length < 3 ? "Select at least 4 images" : "Submit"}
                          {submiting && (
                            <span>
                              <i className="fa fa-refresh fa-spin"></i> 
                            </span>
                          )}
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
            {/* <!-- row --> */}
          </div>
        </div>
      </section>
      {/* <!-- ============================ User Dashboard End ================================== --> */}
    </>
  );
};

export default FormLandForm;
