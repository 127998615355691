import React, { useRef } from 'react'
import deleteIcon from "../../../../../assets/img/icons/delete.svg";
import editIcon from "../../../../../assets/img/icons/edit.svg";
import Filters from '../../../widgets/filters';
import { useNavigate } from 'react-router-dom';
import { useGetMineralOrderListQuery } from '../../../../../redux/slices/mineral_order_slice';
import { TMineralOrder } from '../../../../../redux/types/mineral_order.type';
import moment from 'moment';

const MineralOrdersList: React.FC = () => {
  const divRef = useRef<HTMLDivElement>(null);
  const navigate = useNavigate();
  const [query, setQuery] = React.useState("")
  const [currentPage, setCurrentPage] = React.useState(1);
  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value;
    setQuery(newValue);
  }
    const {
      data: ordersList,
      isLoading,
      isFetching,
      error,
    }: TMineralOrder | any = useGetMineralOrderListQuery({status: "",});
  
    if (isLoading || isFetching) {
      return (
        <div style={{ marginTop: "20%" }} className="text-center">
          <i className="fa fa-spinner fa-spin fa-5x text-danger"></i>
        </div>
      );
    }
  return (
    <>
      <div className="content">
          <div className="page-header">
          <button onClick={() => navigate(-1)} className="btn btn-sm btn-secondary text-white">
           <i className="fa fa-arrow-left"></i>
          </button>
            <div className="page-title">
              <h4>Minerals Customer Orders</h4>
              <h6>Manage your customer Orders</h6>
            </div>
          </div>

          <div className="card">
            <div className="card-body">
            <Filters divRef={divRef} query={query} onSearchChange={onChange} />

              <div className="card" id="filter_inputs">
                <div className="card-body pb-0">
                  <div className="row">
                    <div className="col-lg col-sm-6 col-12">
                      <div className="form-group">
                        <input
                          type="text"
                          className="datetimepicker cal-icon"
                          placeholder="Choose Date" />
                      </div>
                    </div>
                    <div className="col-lg col-sm-6 col-12">
                      <div className="form-group">
                        <input type="text" placeholder="Enter Reference" />
                      </div>
                    </div>
                    <div className="col-lg col-sm-6 col-12">
                      <div className="form-group">
                        <select className="select">
                          <option>Choose Supplier</option>
                          <option>Supplier</option>
                        </select>
                      </div>
                    </div>
                    <div className="col-lg col-sm-6 col-12">
                      <div className="form-group">
                        <select className="select">
                          <option>Choose Status</option>
                          <option>Inprogress</option>
                        </select>
                      </div>
                    </div>
                    <div className="col-lg col-sm-6 col-12">
                      <div className="form-group">
                        <select className="select">
                          <option>Choose Payment Status</option>
                          <option>Payment Status</option>
                        </select>
                      </div>
                    </div>
                    <div className="col-lg-1 col-sm-6 col-12">
                      <div className="form-group">
                        <a className="btn btn-filters ms-auto"
                          ><img
                            src="assets/img/icons/search-whites.svg"
                            alt="img"
                        /></a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {error ? (
              <div className="alert alert-danger text-center mt-5" role="alert">
                {error.status}
              </div>
            ) : ordersList.length === 0 ? (
              <div className="text-center">
                <i style={{ fontSize: "50px" }} className="ti-home"></i>
                <h2>No Data Found</h2>
              </div>
            ) :
              <div className="table-responsive">
                <table className="table datanew">
                  <thead>
                    <tr>
                      <th>
                        #
                      </th>
                      <th>Customer Name</th>
                      <th>Supplier</th>
                      <th>Reference</th>
                      <th>Date</th>
                      <th>Quantity</th>
                      <th>Paid</th>
                      <th>Status</th>
                      <th>Product</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    
                    {ordersList.map((item: TMineralOrder, index: number)=>{
                      return <tr key={index}>
                      <td>
                        {index+1}
                      </td>
                      <td className="text-bolds text-capitalize">{item.customer.first_name} {item.customer.last_name}</td>
                      <td className="text-bolds text-capitalize">{item.supplier.first_name} {item.supplier.last_name}</td>
                      <td>{item.mineral_order_id}</td>
                      <td>{moment(item.created_at).format("Do MMM, YYYY")}</td>
                      <td>{item.quantity}/{item.unit}</td>
                      <td>{Number(item.productPrice).toLocaleString()}</td>
                      <td>
                        <span className="badges bg-lightgreen text-capitalize">{item.status}</span>
                      </td>
                      <td><span className="text-capitalize">{item.product.productName.substring(0, 10)}</span></td>
                      <td>
                        <a className="me-3" href="/zm/dashboard/order_details">
                          <img src={editIcon} alt="img" />
                        </a>
                        <a className="me-3 confirm-text" href="javascript:void(0);">
                          <img src={deleteIcon} alt="img" />
                        </a>
                      </td>
                    </tr>
                    })}
                    
                  </tbody>
                </table>
              </div>
}
            </div>
          </div>
        </div>
    </>
  )
}

export default MineralOrdersList
