import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { toast } from "react-toastify";
import { Spinner } from "react-bootstrap";
import { useForm } from "react-hook-form";
import editPencil from "../../../../assets/img/icons/edit-5.svg";
import { ICarBooking } from "../../../../redux/types/car_booking.type";
import { useUpdateBookingMutation } from "../../../../redux/slices/car_booking_slice";
import { orderStatus } from "../../../utils/order_status";
import InspectionSection from "./widgets/InspectionSection";
import { initialInspectionData } from "../../../utils/car_inspection";

type TDetailProps = {
  detail: ICarBooking;
};

const CarRentalActionModal: React.FC<TDetailProps> = ({ detail }) => {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);


  const [uponDeparture, setUponDeparture] = React.useState(detail.vehicle_request_inspection === null ? initialInspectionData: detail.vehicle_request_inspection.upon_departure);
  const [uponReturn, setUponReturn] = React.useState(detail.vehicle_request_inspection === null ? initialInspectionData: detail.vehicle_request_inspection.upon_return);
  const [
    updateBooking,
    { isLoading: updating, error: errorUpdating, isSuccess },
  ] = useUpdateBookingMutation();

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  const handleDepartureChange = (field: string, value: string) => {
    setUponDeparture((prev: any) => ({ ...prev, [field]: value }));
  };

  const handleReturnChange = (field: string, value: string) => {
    setUponReturn((prev: any) => ({ ...prev, [field]: value }));
  };

  const onSubmit = handleSubmit(async (data) => {
    const formData = new FormData();
    formData.append("vehicle", `${detail.car.id}`);
    formData.append("request", `${detail.id}`);
    formData.append("status", data.status);
    formData.append("inspection_date", new Date().toISOString().split("T")[0]);
    formData.append("upon_departure", JSON.stringify(uponDeparture));
    formData.append("upon_return", JSON.stringify(uponReturn));
    formData.append("comments", data.comments);
    formData.append("checked_by", data.checked_by);
    formData.append("client_name", data.client_name);

    const bookingData = {
      // car: detail.car.id,
      // client: detail.client.id,
      // company: detail.company.id,
      days: detail.days,
      hiring_reason: detail.hiring_reason,
      phone_number: detail.phone_number,
      rental_price: detail.rental_price,
      status: data.status,
      vehicle_request_inspection: {
        vehicle: detail.car.id,
        request: detail.id,
        inspection_date: new Date().toISOString().split("T")[0],
        upon_departure: JSON.stringify(uponDeparture),
        upon_return: JSON.stringify(uponReturn),
        comments: data.comments,
        checked_by: data.checked_by,
        client_name: data.client_name,
      },
    };

    

    updateBooking({
      id: detail.id,
      ...bookingData,
    })
      .unwrap()
      .then((res) => {
        reset();
        setShow(false);
        toast.success("Updated Successfully");
      })
      .catch((err) => {
        toast.error("Error: " + err.data["detail"]);
      });
  });

  return (
    <>
      <center>
      <button onClick={handleShow} className="btn btn-submit me-2">
        Status: {detail.status} <img src={editPencil} alt="edit" />
      </button>
      </center>
      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        scrollable
        size="xl"
        keyboard={false}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Update</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={onSubmit}>
            <div className="row">
              <div className="col-md-6">
                <InspectionSection
                  title="Upon Departure"
                  data={uponDeparture}
                  onChange={handleDepartureChange}
                />
              </div>
              <div className="col-md-6">
                <InspectionSection
                  title="Upon Return"
                  data={uponReturn}
                  onChange={handleReturnChange}
                />
              </div>
            </div>

            <div>
              <h3>Comments</h3>
              <textarea
                rows={4}
                cols={50}
                {...register("comments", { required: false })}
                className={`form-control ${
                  errors.comments ? "border-danger" : ""
                }`}
              />
              {errors.comments && (
                <p className="text-danger">This field is required</p>
              )}
            </div>
            <div className="row">
              <h3>Signatures</h3>
              <div className="col-md-6">
                <div className="form-group">
                  <label>
                    Checked By:
                    <input
                      type="text"
                      {...register("checked_by", { required: true })}
                      className={`form-control ${
                        errors.checked_by ? "border-danger" : ""
                      }`}
                      defaultValue={`${detail.company.first_name} ${detail.company.last_name}`}
                    />
                  </label>
                  {errors.checked_by && (
                    <p className="text-danger">This field is required</p>
                  )}
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <label>
                    Client Name:
                    <input
                      type="text"
                      {...register("client_name", { required: true })}
                      className={`form-control ${
                        errors.client_name ? "border-danger" : ""
                      }`}
                      defaultValue={`${detail.client.first_name} ${detail.client.last_name}`}
                    />
                  </label>
                  {errors.client_name && (
                    <p className="text-danger">This field is required</p>
                  )}
                </div>
              </div>
              <div className="form-group">
                <label htmlFor="order-status">Select Status</label>
                <select
                  {...register("status", { required: true })}
                  className={`form-control ${
                    errors.status ? "border-danger" : ""
                  }`}
                  defaultValue={detail.status}
                  name="status"
                  id="status"
                >
                  <option value="">Click to Select Status</option>
                  {orderStatus.map((item, index) => {
                    return (
                      <option key={index} value={item}>
                        {item}
                      </option>
                    );
                  })}
                </select>
                {errors.status && (
                  <p className="text-danger">This field is required</p>
                )}
              </div>
            </div>
            <Modal.Footer>
              <Button
                variant="secondary"
                onClick={handleClose}>
                Close
              </Button>
              <Button disabled={updating} type="submit" variant="primary">
              {updating && <Spinner size="sm" />} Submit Inspection and Update
              Status
            </Button>
            </Modal.Footer>
          </form>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default CarRentalActionModal;
