import React, {RefObject} from "react";
import html2canvas from 'html2canvas';
import * as XLSX from 'xlsx';
import jsPDF from 'jspdf';
import pdf from "../../../assets/img/icons/pdf.svg";
import excel from "../../../assets/img/icons/excel.svg";
import printer from "../../../assets/img/icons/printer.svg";
import filter from "../../../assets/img/icons/filter.svg";
import close from "../../../assets/img/icons/closes.svg";
import search from "../../../assets/img/icons/search-white.svg";
import searchIcon from "../../../assets/img/icons/search-whites.svg";

type IFilterProps = {
  showSearch?: boolean;
  onSearchChange: (query: any) => void;
  query: string;
  divRef: RefObject<HTMLDivElement>;
};

const Filters: React.FC<IFilterProps> = ({ showSearch, onSearchChange, query, divRef }) => {
  const handlePrint = () => {
    const printContents = divRef.current?.innerHTML;
    const printWindow = window.open('', '_blank');
    if (printWindow && printContents) {
      printWindow.document.write('<html><head><title>Print</title></head><body>');
      printWindow.document.write(printContents);
      printWindow.document.write('</body></html>');
      printWindow.document.close();
      printWindow.print();
    }
  };
  const handleExportPDF = async () => {
    if (divRef.current) {
      const canvas = await html2canvas(divRef.current);
      const imgData = canvas.toDataURL('image/png');
      const pdf = new jsPDF('p', 'mm', 'a4');
      const imgWidth = 190;
      const imgHeight = (canvas.height * imgWidth) / canvas.width;
      pdf.addImage(imgData, 'PNG', 10, 10, imgWidth, imgHeight);
      pdf.save('content.pdf');
    }
  };
  const handleExportExcel = () => {
    if (divRef.current) {
      const data = divRef.current.innerText;
      const worksheet = XLSX.utils.aoa_to_sheet([[data]]);
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
      XLSX.writeFile(workbook, 'content.xlsx');
    }
  };
  return (
    <>
      <div className="table-top">
        {showSearch !== null && showSearch === false ? <div></div> : <div className="search-set">
          <div className="search-path">
            <a className="btn btn-filter" id="filter_search">
              <img src={filter} alt="img" />
              <span>
                <img src={close} alt="img" />
              </span>
            </a>
          </div>
          <div className="search-input">
            <a className="btn btn-searchset">
              <img src={search} alt="img" />
            </a>
          </div>
        </div>}
        
        <div className="wordset">
          <ul>
            <li>
              <a onClick={handleExportPDF} data-bs-toggle="tooltip" data-bs-placement="top" title="pdf">
                <img src={pdf} alt="img" />
              </a>
            </li>
            <li>
              <a onClick={handleExportExcel} data-bs-toggle="tooltip" data-bs-placement="top" title="excel">
                <img src={excel} alt="img" />
              </a>
            </li>
            <li>
              <a onClick={handlePrint} data-bs-toggle="tooltip" data-bs-placement="top" title="print">
                <img src={printer} alt="img" />
              </a>
            </li>
          </ul>
        </div>
      </div>

        <div className="card" id="filter_inputs">
          <div className="card-body pb-0">
            <div className="row">
              <div className="col-lg-4 col-sm-4 col-4">
                <div className="form-group">
                  <label htmlFor="search">Search</label>
                  <input
                    type="text"
                    value={query}
                    onChange={onSearchChange}
                    className="form-control"
                    placeholder="Search Anything..."
                  />
                </div>
              </div>
              <div className="col-lg-3 col-sm-4 col-4">
                <div className="form-group">
                  <label htmlFor="from-date">From Date</label>
                  <input
                    type="date"
                    className="form-control"
                    placeholder="Search Anything..."
                  />
                </div>
              </div>
              <div className="col-lg-3 col-sm-4 col-4">
                <div className="form-group">
                  <label htmlFor="to-date">To Date</label>
                  <input
                    type="date"
                    className="form-control"
                    placeholder="Search Anything..."
                  />
                </div>
              </div>
              <div className="col-lg-2 col-sm-3 col-2">
                <div className="form-group">
                  <a className="btn btn-filters ms-auto">
                    <img src={searchIcon} alt="img" />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      
    </>
  );
};

export default Filters;
