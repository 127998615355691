import { useState, ChangeEvent, FormEvent } from "react";
import { toast } from "react-toastify";
import { useAppDispatch } from "./redux-hooks";
import { setAuth } from "../redux/features/authSlice";
import { useLoginMutation } from "../redux/slices/authApiSlice";
import { useNavigate } from "react-router-dom";

export default function useLogin() {
  const router = useNavigate();
  const dispatch = useAppDispatch();
  const [login, { isLoading }] = useLoginMutation();

  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });

  const { email, password } = formData;

  const onChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;

    setFormData({ ...formData, [name]: value });
  };

  const onSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    login({ email, password })
      .unwrap()
      .then((res) => {
        dispatch(setAuth());
        toast.success("Logged in");
        localStorage.setItem("access", res.access);
        localStorage.setItem("refresh", res.refresh);
        router("/zm/authenticating");
      })
      .catch((error) => {
        toast.error("Error " + error.data['detail']);
      });
  };

  // useEffect(() => {
  // 	if (isSuccess && user) {
  // 	// dispatch(userProfile(user));
  // 	const profile = user
  // 	console.log(user)
  // 	console.log("Hello word")
  // 	}
  //   }, [isSuccess, user]);

  return {
    email,
    password,
    isLoading,
    onChange,
    onSubmit,
  };
}
