import React from "react";
import { useGetApprovedRequestsQuery } from "../../../redux/slices/property/property_requests_slice";
import { RequestsModel } from "../../../models/requests_model";
import BreadCrumbs from "../../widgets/bread_crumbs";

const BookMarks: React.FC = () => {
  const {
    data: requests,
    isLoading,
    isError,
    error,
  }: RequestsModel | any = useGetApprovedRequestsQuery();
  if (isLoading) {
    return (
      <div style={{ marginTop: "20%" }} className="text-center">
        <i className="fa fa-spinner fa-spin fa-5x text-danger"></i>
      </div>
    );
  }
  if (isError) {
    console.log(error);
    return (
      <div
        style={{ marginTop: "20%" }}
        className="alert alert-danger"
        role="alert"
      >
        {error.message}
      </div>
    );
  }
  return (
    <div className="content">
      <BreadCrumbs
        title={"Rented Properties"}
        subTitle={"Manage Rented properties"}
      />
      {/* <!-- ============================ User Dashboard ================================== --> */}
      <section className="gray pt-0 pb-5">
        <div className="col-xl-12 col-lg-12 col-md-12">
          <div className="dashboard-body">
            <div className="dashboard-wraper">
              {requests.length === 0 ? (
                <center>
                  <div
                    style={{ width: "fit-content" }}
                    className="alert alert-info mt-5 text-center"
                    role="alert"
                  >
                    <h1>No Properties Found</h1>
                  </div>
                </center>
              ) : (
                <table className="property-table-wrap responsive-table bkmark">
                  <tbody>
                    <tr>
                      <th>
                        <i className="fa fa-file-text"></i> Properties
                      </th>
                      <th></th>
                    </tr>

                    {requests.map((item: RequestsModel, index: number) => {
                      return (
                        <tr key={index}>
                          <td className="dashboard_propert_wrapper">
                            <img
                              src={`${process.env.REACT_APP_API_URL}${item.property.image}`}
                              alt=""
                            />
                            <div className="title">
                              <h4 className="capitalize">
                                <a href="#">{item.property.propertyName}</a>
                              </h4>
                              <span className="capitalize">
                                {item.property.propertyAddress}
                              </span>
                              <span className="capitalize">
                                Tenant: {item.user.first_name}{" "}
                                {item.user.last_name}
                              </span>
                              <span className="table-property-price">
                                ZMW
                                {Number(
                                  item.property.propertyPrice
                                ).toLocaleString()}{" "}
                                / Monthly
                              </span>
                            </div>
                          </td>
                          <td className="action">
                            <a
                              href={`/zm/accounts/realestate/rented/property_details?property=${item.id}`}
                              className="delete"
                            >
                              <i className="fa-solid fa-eye me-1"></i>Details
                            </a>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              )}
            </div>
          </div>
        </div>
      </section>
      {/* <!-- ============================ User Dashboard End ================================== --> */}
    </div>
  );
};

export default BookMarks;
