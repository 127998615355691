import { apiSlice } from "../services/apiSlice";
import { TDealSecureTransactionModel } from "../types/deal_secure.type";

export const dealSecureApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    createDealSecure: builder.mutation<TDealSecureTransactionModel, Partial<TDealSecureTransactionModel>>({
      query(data) {
        return {
          url: `/administrator/v1/everyday/admin_deal_secure/tech`,
          method: "POST",
          body: data,
        };
      },
      invalidatesTags: ["TDealSecureTransactionModel"],
      transformResponse: (result: { agri: TDealSecureTransactionModel }) =>
        result.agri,
    }),
    updateDealSecure: builder.mutation<TDealSecureTransactionModel, Partial<TDealSecureTransactionModel>& Pick<TDealSecureTransactionModel, 'id'>>({
      query: ({ id, ...patch }) => ({
        url: `/administrator/v1/everyday/admin_deal_secure/tech/${id}/`,
        method: "PATCH",
        body: patch,
      }),
      invalidatesTags: ["TDealSecureTransactionModel"],
      transformResponse: (response: { agri: TDealSecureTransactionModel }) =>
        response.agri,
    }),
    getDealSecure: builder.query<TDealSecureTransactionModel, number>({
      query: (id) => ({
        url: `/administrator/v1/everyday/admin_deal_secure/tech/${id}/`,
      }),
      providesTags: ["TDealSecureTransactionModel"],
    }),
    getDealSecureList: builder.query<TDealSecureTransactionModel[], any>({
      query: ({status, searchQuery, page}) => `/administrator/v1/everyday/admin_deal_secure/tech/?status=${status}&search=${searchQuery}&page=${page}`,
      // transformResponse: (res: TDealSecureTransactionModel[]) =>
      //   res.sort((a, b) => b.id - a.id),
      //   providesTags: (result) =>
      //   result ? result.map(({ id }) => ({ type: 'TDealSecureTransactionModel', id })) : ['TDealSecureTransactionModel'],
      // keepUnusedDataFor: 5,
    }),
    deleteDealSecure: builder.mutation<TDealSecureTransactionModel, number>({
      query: (id) => ({
        url: `/administrator/v1/everyday/admin_deal_secure/tech/${id}/`,
        method: "DELETE",
      }),
      invalidatesTags: ["TDealSecureTransactionModel"],
    }),
  }),
});
export const {
  useCreateDealSecureMutation,
  useUpdateDealSecureMutation,
  useGetDealSecureQuery,
  useGetDealSecureListQuery,
  useDeleteDealSecureMutation,
} = dealSecureApiSlice;
