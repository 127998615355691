import http from "./http.common";

class DashbordServices {
// CREATE CAR RENTAL
async createCarRental(data: any){
  return await http.post(
    `/cars/v1/everyday/car_rental/tech/`,
    data
  );
}
// UPLOAD PRODUCT IMAGES
async uploadProductImages(data: any){
  return await http.post(
    `/cars/v1/everyday/car_image_gallery/tech/`,
    data
  );
}

}

export default new DashbordServices();
