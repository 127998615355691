import React from "react";
import bell from "../../../../assets/img/icons/notification-bing.svg";
import { useGetAdminPendingCarsQuery } from "../../../../redux/slices/admin_hire_slice";
import { ICarRental } from "../../../../redux/types/car_rental.type";
import moment from "moment";

const CarDealsNoticationWidget: React.FC = () => {
  const {
    data: carList,
    isLoading,
    isFetching,
    error,
  }: ICarRental | any = useGetAdminPendingCarsQuery({searchQuery: "", page: 1, status: false, label: 'SALE'});
  return (
    <li className="nav-item dropdown">
      <a
        href="javascript:void(0);"
        className="dropdown-toggle nav-link"
        data-bs-toggle="dropdown"
      >
        <img src={bell} alt="img" />
        <span className="badge rounded-pill bg-primary">{isFetching||isFetching ? "0" : error ? "0":  carList.results.length}</span>
      </a>
      <div className="dropdown-menu notifications">
        <div className="topnav-dropdown-header">
          <span className="notification-title">Car Deals Notifications</span>
          <a href="javascript:void(0)" className="clear-noti">
            Clear All
          </a>
        </div>
        <div className="noti-content">
          <ul className="notification-list">
            
            {isFetching || isLoading ? <center>
              <i className="fa fa-spinner fa-spin fa-5x text-danger"></i>
            </center>: error ? (
              <div className="alert alert-danger text-center mt-5" role="alert">
                {error.status}
              </div>
            ) : carList.results.length === 0 ? (
              <div className="text-center">
                <i style={{ fontSize: "50px" }} className="ti-home"></i>
                <h5 className="text-center">No Notifications</h5>
              </div>
            ) : carList.results.map((item: ICarRental, index: number)=>{
              return <li key={index} className="notification-message">
              <a href={`/zm/dashboard/car_details/?car_id=${item.id}`}>
                <div className="media d-flex">
                  <span className="avatar flex-shrink-0">
                    <img
                      src={`${item.car_images[0].image}`}
                      alt={item.car_brand.brand_name}
                    />
                  </span>
                  <div className="media-body flex-grow-1">
                    <p className="noti-details">
                      New Car Pending Listing request From: <br /><span className="noti-title text-capitalize">{item.user.company_name}</span>
                    </p>
                    <p className="noti-time">
                      <span className="notification-time">
                        {moment(item.created_at).fromNow()}
                      </span>
                    </p>
                  </div>
                </div>
              </a>
            </li>
            })}
            
          </ul>
        </div>
        <div className="topnav-dropdown-footer">
          <a href="#">View all Notifications</a>
        </div>
      </div>
    </li>
  );
};


export default CarDealsNoticationWidget;
