import React, { useRef } from "react";
import moment from "moment";
import { useGetFuelQuery } from "../../../../redux/slices/fuel_slice";
import {
  TFuelModel,
  TFuelOrderModel,
} from "../../../../redux/types/fuel_mode.type";
import BreadCrumbs from "../../../widgets/bread_crumbs";
import Filters from "../../widgets/filters";
import LoadingSpinner from "../../../../components/widgets/spinner";
import { ErrorWidget } from "../../../utils/error_widget";
import EditFuelStatus from "./edit_fuel_status";
import FuelOrderChart from "./stats";

const FuelDeals: React.FC = () => {
  const divRef = useRef<HTMLDivElement>(null);
  const queryParams = new URLSearchParams(window.location.search);
  const fuel_id = queryParams.get("fuel_id");
  const [query, setQuery] = React.useState("")
  const [currentPage, setCurrentPage] = React.useState(1);
  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value;
    setQuery(newValue);
  }
  const [show, setShow] = React.useState(false);
  const [fuelItem, setFuelItem] = React.useState<TFuelModel | any>();
  const handleClose = () => {
    setShow(false);
  };

  const handleShow = (fuel: TFuelModel) => {
    setFuelItem(fuel);
    setShow(true);
  };
  const {
    data: fuel,
    isLoading,
    error,
  }: TFuelModel | any = useGetFuelQuery(Number(fuel_id));

  if (isLoading) {
    return (
      <div style={{ marginTop: "20%" }} className="text-center">
        <LoadingSpinner />
      </div>
    );
  }

  return (
    <div className="content">
      <BreadCrumbs
        title={"Fuel Details"}
        subTitle={"View/Manage Fuel Details"}
      />
      <div className="card">
        <div className="card-body">
        <Filters divRef={divRef} query={query} onSearchChange={onChange} />
          {error ? <ErrorWidget error={error}/>: <> 
          <button onClick={()=> handleShow(fuel)} className="btn btn-sm btn-info text-white">
          {fuel?.status} <i className="fa fa-edit"></i>
        </button>
          <EditFuelStatus
            fuel={fuelItem}
            show={show}
            setShow={setShow}
            handleClose={handleClose}
          />
          <div className="mb-2">
        <h3 className="mt-3 mb-2 text-center text-capitalize">
          <span className="text-primary">{fuel?.fuel_name}</span>
        </h3>
        <div className="text-center text-capitalize">
          <h4><b>{fuel?.supplier.company_name}</b></h4>
        </div>
        <FuelOrderChart transaction_id={fuel_id} />
        <h1>Transactions ({fuel?.fuel_order_product.length}) </h1>
      </div>
      {fuel?.fuel_order_product.length === 0 ? (
        <div className="alert alert-info text-center">
          <h3>No Transactions Found</h3>
        </div>
      ) : (
        <div ref={divRef} className="table-responsive mt-4">
          <table className="table datanew">
            <thead>
              <tr>
                <th>#</th>
                <th>Customer</th>
                <th>Amount</th>
                <th>Quantity</th>
                <th>Status</th>
                <th>Payment</th>
                <th>Date</th>
              </tr>
            </thead>
            <tbody>
              {fuel.fuel_order_product.map(
                (item: TFuelOrderModel, index: number) => {
                  return (
                    <tr>
                      <td>{index + 1}</td>
                      <td className="text-capitalize">
                        {item.customer.first_name} {item.customer.last_name}
                      </td>
                      <td>ZMW {Number(item.productPrice).toLocaleString()}</td>
                      <td>{item.quantity}/{item.unit}</td>
                      <td>
                      <span className={`badges ${item.status !== "CONFIRMED" ? "bg-lightred" : "bg-lightgreen"}`}>
                        {item.status}
                      </span>
                    </td>
                    <td>
                      <span className="badges bg-lightgreen">
                        Paid
                      </span>
                    </td>
                      <td>{moment(item.created_at).format("Do MMMM, YYYY")}</td>
                    </tr>
                  );
                }
              )}
            </tbody>
          </table>
        </div>
      )}</>}
        </div>
      </div>
    </div>
  );
};

export default FuelDeals;
