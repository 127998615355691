import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { Spinner } from "react-bootstrap";
import { FormInput } from "../../../../components/molecules/form_input";
import { useAppSelector } from "../../../../hooks/redux-hooks";
import { useUpdateFuelMutation } from "../../../../redux/slices/fuel_slice";
import { TFuelModel } from "../../../../redux/types/fuel_mode.type";

export type DataFormFields = {
  supplier: string;
  category: string;
  productName: string;
  unit: string;
  productPrice: number;
  description: string;
  image: File;
};

type TFuelProps = {
  product: TFuelModel;
  show: boolean;
  handleClose: () => void;
  setShow: (show: boolean) => void;
};

const EditFuel: React.FC<TFuelProps> = ({
  product,
  show,
  handleClose,
  setShow,
}) => {
  const user = useAppSelector((state) => state.auth.user);
  const [file, setFile] = useState<File | any>();

  const [updateFuel, { isLoading }] = useUpdateFuelMutation();

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  const onSubmit = handleSubmit(async (data) => {
    updateFuel({
      id: product.id,
      ...data,
    })
      .unwrap()
      .then((res) => {
        reset();
        setShow(false);
        toast.success("Product Updated Successfully");
      })
      .catch((err) => {
        toast.error("Error: " + err.data["detail"]);
      });
  });

  return (
    <>
      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        centered
        scrollable
        size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title>Edit Fuel Product</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={onSubmit} encType="multipart/form-data">
            <div className="row">
              <div className="col-md-4">
                <label htmlFor="name">Product Name</label>
                <FormInput
                  id="fuel_name"
                  type="text"
                  name="fuel_name"
                  disabled={isLoading}
                  defaultValue={product?.fuel_name}
                  label="Product Name"
                  placeholder="Product Name"
                  className="mb-2"
                  register={register}
                  rules={{ required: "Product Name is required." }}
                  errors={errors}
                />
              </div>
              <div className="col-md-4">
                <label htmlFor="price">Product Price</label>
                <FormInput
                  id="productPrice"
                  type="text"
                  step="0.01"
                  name="productPrice"
                  disabled={isLoading}
                  defaultValue={product?.fuel_price}
                  label="Product Price"
                  placeholder="Product Price"
                  className="mb-2"
                  register={register}
                  rules={{ required: "Product Price is required." }}
                  errors={errors}
                />
              </div>
              <div className="col-md-4">
                <label htmlFor="price">Product Unit</label>
                <FormInput
                  id="productUnit"
                  type="text"
                  step="0.01"
                  name="unit"
                  disabled={isLoading}
                  defaultValue={product?.unit}
                  label="Product Unit"
                  placeholder="Product Unit E.G grams, kg etc"
                  className="mb-2"
                  register={register}
                  rules={{ required: "Product Unit is required." }}
                  errors={errors}
                />
              </div>
              <div className="col-md-6">
                <label htmlFor="image">Product Image</label>
                <FormInput
                  id="thumbnail"
                  type="file"
                  name="image"
                  label="Image"
                  disabled={isLoading}
                  // defaultValue={product?.image}
                  accept="image/*"
                  placeholder="Image"
                  className="mb-2"
                  register={register}
                  rules={{
                    required: false,
                    onChange: (event: React.ChangeEvent<HTMLInputElement>) => {
                      if (event.target.files && event.target.files[0]) {
                        setFile(event.target.files[0]);
                      }
                    },
                  }}
                  errors={errors}
                />
              </div>
            </div>

            <Modal.Footer>
              <Button variant="secondary" onClick={handleClose}>
                Cancel
              </Button>
              <Button disabled={isLoading} variant="primary" type="submit">
                {isLoading && <Spinner size="sm" />}{" "}
                <i className="fa fa-plus"></i> Create
              </Button>
            </Modal.Footer>
          </form>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default EditFuel;
