import React, { useRef } from "react";
import deleteIcon from "../../../../assets/img/icons/delete.svg";
import editIcon from "../../../../assets/img/icons/edit.svg";
import moment from "moment";
import Filters from "../../../admin/widgets/filters";
import { useAppSelector } from "../../../../hooks/redux-hooks";
import { TAgriculture } from "../../../../redux/types/agriculture.type";
import DeleteAgroModal from "./delete_agro_modal";
import { useGetAgricultureListQuery } from "../../../../redux/slices/agriculture_slice";
import AddAgriculture from "./add_agriculture";
import EditAgro from "./edit_agro";
import Pagination from "../../../pagination/pagination_items";

const AgroPage: React.FC = () => {
  const divRef = useRef<HTMLDivElement>(null);
  const user = useAppSelector((state) => state.auth.user);
  const [show, setShow] = React.useState(false);
  const [showDelete, setShowDelete] = React.useState(false);
  const [agro, setAgro] = React.useState<TAgriculture | any>();
  const [deleteAgro, setDeleteAgro] = React.useState<TAgriculture | any>();
  const [query, setQuery] = React.useState("")
  const [currentPage, setCurrentPage] = React.useState(1);
  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value;
    setQuery(newValue);
  }

  const handleCloseDelete = () => {
    setShowDelete(false);
  };
  const handleClose = () => {
    setShow(false);
  };

  const handleShow = (agro: TAgriculture) => {
    setAgro(agro);
    setShow(true);
  };

  const handleShowDelete = (agro: TAgriculture) => {
    setDeleteAgro(agro);
    setShowDelete(true);
  };

  const {
    data: agricList,
    isLoading,
    isFetching,
    error,
  }: TAgriculture | any = useGetAgricultureListQuery({supplier: user?.id, status: "", searchQuery: query, page: currentPage});

  if (isLoading || isFetching) {
    return (
      <div style={{ marginTop: "20%" }} className="text-center">
        <i className="fa fa-spinner fa-spin fa-5x text-danger"></i>
      </div>
    );
  }
  return (
    <>
      <div className="content">
        <div className="page-header">
          <div className="page-title">
            <h4>Agriculture List</h4>
            <h6>Manage your Agricultures</h6>
          </div>
          <div className="page-btn">
            <AddAgriculture />
          </div>
        </div>

        <div className="card">
          <div className="card-body">
            <Filters divRef={divRef} query={query} onSearchChange={onChange}/>

            <div className="card" id="filter_inputs">
              <div className="card-body pb-0">
                <div className="row">
                  <div className="col-lg-2 col-sm-6 col-12">
                    <div className="form-group">
                      <input type="text" placeholder="Enter Customer Code" />
                    </div>
                  </div>
                  <div className="col-lg-1 col-sm-6 col-12 ms-auto">
                    <div className="form-group">
                      <a className="btn btn-filters ms-auto">
                        <img
                          src="assets/img/icons/search-whites.svg"
                          alt="img"
                        />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {error ? (
              <div className="alert alert-danger text-center mt-5" role="alert">
                {error.status}
              </div>
            ) : agricList.results.length === 0 ? (
              <div className="text-center">
                <i style={{ fontSize: "50px" }} className="ti-home"></i>
                <h2>No Data Found</h2>
              </div>
            ) : (
              <div ref={divRef} className="table-responsive">
                <table className="table datanew">
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>Product</th>
                      <th>Quantity</th>
                      <th>Sales</th>
                      <th>Reference</th>
                      <th>Unit</th>
                      <th>Product Price</th>
                      <th>Status</th>
                      <th>Created On</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {agricList.results.map((item: TAgriculture, index: number) => {
                      return (
                        <tr key={index}>
                          <td>{index + 1}</td>
                          <td className="text-capitalize">
                            <div className="row">
                              <div className="col-md-4 mr-0 pr-0">
                                <a href="">
                                  <img
                                    className="product-imgs rounded"
                                    src={`${item.thumbnail}`}
                                    alt={item.productName}
                                  />
                                </a>
                              </div>
                              <div className="col-md-8 ml-0 pl-0">
                                  <b>{item.productName.substring(0, 10)}</b>
                              </div>
                            </div>
                          </td>
                          <td>{item.quantity}</td>
                          <td>{item.agri_order_product.length}</td>
                          <td>{item.product_unique_id}</td>
                          <td className="text-capitalize">{item.unit}</td>
                          <td>{Number(item.productPrice).toLocaleString()}</td>
                          <td className="text-capitalize">{item.status}</td>
                          <td>
                            {moment(item.created_at).format("Do MMMM, YYYY")}
                          </td>
                          <td>
                            <a
                              className="me-3"
                              onClick={() => handleShow(item)}
                            >
                              <img src={editIcon} alt="img" />
                            </a>
                            <a
                              className="me-3 confirm-text"
                              onClick={() => handleShowDelete(item)}
                            >
                              <img src={deleteIcon} alt="img" />
                            </a>
                          </td>
                          <EditAgro
                            agro={agro === null ? item : agro}
                            show={show}
                            setShow={setShow}
                            handleClose={handleClose}
                          />
                          <DeleteAgroModal
                            agro={
                              deleteAgro === null ? item : deleteAgro
                            }
                            show={showDelete}
                            handleClose={handleCloseDelete}
                            setShow={setShowDelete}
                          />
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
                <Pagination
                  currentPage={currentPage}
                  lastPage={agricList.total_pages}
                  maxLength={7}
                  setCurrentPage={setCurrentPage}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default AgroPage;
