import { apiSlice } from "../services/apiSlice";
import { TInvestmentDonation } from "../types/investment_donations.type";

export const investmentDonationAPISlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    createInvestmentDontation: builder.mutation<TInvestmentDonation, Partial<TInvestmentDonation>>({
      query(data) {
        return {
          url: `/investments/v1/everyday/admin_investments_donations/tech/`,
          method: "POST",
          body: data,
        };
      },
      invalidatesTags: ["TInvestmentDonation"],
      transformResponse: (result: { room: TInvestmentDonation }) =>
        result.room,
    }),
    updateInvestmentDonation: builder.mutation<TInvestmentDonation, Partial<TInvestmentDonation>& Pick<TInvestmentDonation, 'id'>>({
      query: ({ id, ...patch }) => ({
        url: `/investments/v1/everyday/admin_investments_donations/tech/${id}/`,
        method: "PATCH",
        body: patch,
      }),
      invalidatesTags: ["TInvestmentDonation"],
      transformResponse: (response: { room: TInvestmentDonation }) =>
        response.room,
    }),
    getInvestmentDonation: builder.query<TInvestmentDonation, number>({
      query: (id) => ({
        url: `/investments/v1/everyday/admin_investments_donations/tech/${id}/`,
      }),
      providesTags: ["TInvestmentDonation"],
    }),
    getInvestmentDonationList: builder.query<TInvestmentDonation[], any>({
      query: ({user}) => `/investments/v1/everyday/admin_investments_donations/tech/?user__id=${user}`,
      transformResponse: (res: TInvestmentDonation[]) =>
        res.sort((a, b) => b.id - a.id),
        providesTags: (result) =>
        result ? result.map(({ id }) => ({ type: 'TInvestmentDonation', id })) : ['TInvestmentDonation'],
      // keepUnusedDataFor: 5,
    }),
    deleteDonation: builder.mutation<TInvestmentDonation, number>({
      query: (id) => ({
        url: `/investments/v1/everyday/admin_investments_donations/tech/${id}/`,
        method: "DELETE",
      }),
      invalidatesTags: ["TInvestmentDonation"],
    }),
  }),
});
export const {
  useCreateInvestmentDontationMutation,
  useDeleteDonationMutation,
  useGetInvestmentDonationListQuery,
  useGetInvestmentDonationQuery,
  useUpdateInvestmentDonationMutation,
} = investmentDonationAPISlice;
