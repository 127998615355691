import { apiSlice } from "../services/apiSlice";
import { ICarViewRequest } from "../types/car_booking.type";

export const carViewingRequestSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    createViewRequest: builder.mutation<ICarViewRequest, Partial<ICarViewRequest>>({
      query(data) {
        return {
          url: `/cars/v1/everyday/admin_car_view_requests/tech`,
          method: "POST",
          body: data,
        };
      },
      invalidatesTags: ["ICarViewRequest"],
      transformResponse: (result: { agri: ICarViewRequest }) =>
        result.agri,
    }),
    updateViewRequest: builder.mutation<ICarViewRequest, Partial<ICarViewRequest>& Pick<ICarViewRequest, 'id'>>({
      query: ({ id, ...patch }) => ({
        url: `/cars/v1/everyday/admin_car_view_requests/tech/${id}/`,
        method: "PATCH",
        body: patch,
      }),
      invalidatesTags: ["ICarViewRequest"],
      transformResponse: (response: { agri: ICarViewRequest }) =>
        response.agri,
    }),
    getViewRequest: builder.query<ICarViewRequest, number>({
      query: (id) => ({
        url: `/cars/v1/everyday/admin_car_view_requests/tech/${id}/`,
      }),
      providesTags: ["ICarViewRequest"],
    }),
    getViewRequestList: builder.query<ICarViewRequest[], any>({
      query: ({supplier, status, searchQuery, page}) => `/cars/v1/everyday/admin_car_view_requests/tech/?company__id=${supplier}&status=${status}&search=${searchQuery}&page=${page}`,
      // transformResponse: (res: ICarViewRequest[]) =>
      //   res.sort((a, b) => b.id - a.id),
      //   providesTags: (result) =>
      //   result ? result.map(({ id }) => ({ type: 'ICarViewRequest', id })) : ['ICarViewRequest'],
      // keepUnusedDataFor: 5,
    }),
    deleteViewRequest: builder.mutation<ICarViewRequest, number>({
      query: (id) => ({
        url: `/cars/v1/everyday/admin_car_view_requests/tech/${id}/`,
        method: "DELETE",
      }),
      invalidatesTags: ["ICarViewRequest"],
    }),
  }),
});
export const {
  useCreateViewRequestMutation,
  useDeleteViewRequestMutation,
  useGetViewRequestListQuery,
  useGetViewRequestQuery,
  useUpdateViewRequestMutation
} = carViewingRequestSlice;
