import React, { useRef } from "react";
import Filters from "../../widgets/filters";
import editIcon from "../../../../assets/img/icons/edit.svg";
import { useGetMineralListQuery } from "../../../../redux/slices/mineral_slice";
import { TMineral } from "../../../../redux/types/mineral.type";
import moment from "moment";
import { useAppSelector } from "../../../../hooks/redux-hooks";
import BreadCrumbs from "../../../widgets/bread_crumbs";
import EditMineralStatus from "./edit_mineral_status";
import LoadingSpinner from "../../../../components/widgets/spinner";
import Pagination from "../../../pagination/pagination_items";

const PendingMineralRequestPage: React.FC = () => {
  const divRef = useRef<HTMLDivElement>(null);
  const [show, setShow] = React.useState(false);
  const [mineral, setMineral] = React.useState<TMineral | any>();

  const [query, setQuery] = React.useState("")
  const [currentPage, setCurrentPage] = React.useState(1);
  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value;
    setQuery(newValue);
  }
  
  const handleClose = () => {
    setShow(false);
  };

  const handleShow = (mineral: TMineral) => {
    setMineral(mineral);
    setShow(true);
  };
  const {
    data: minerals,
    isLoading,
    isFetching,
    error,
  }: TMineral | any = useGetMineralListQuery({supplier: "", status: "PENDING",page: currentPage, searchQuery: query});

  if (isLoading || isFetching) {
    return (
      <div style={{ marginTop: "20%" }} className="text-center">
        <LoadingSpinner />
      </div>
    );
  }
  
  return (
    <>
      <div className="content">
      <BreadCrumbs
        title={"Pending Mineral List"}
        subTitle={"View/Manage Pending Mineral List"}
      />

        <div className="card">
          <div className="card-body">
            <Filters divRef={divRef} query={query} onSearchChange={onChange} />

            <div className="card" id="filter_inputs">
              <div className="card-body pb-0">
                <div className="row">
                  <div className="col-lg-2 col-sm-6 col-12">
                    <div className="form-group">
                      <input type="text" placeholder="Enter Customer Code" />
                    </div>
                  </div>
                  <div className="col-lg-1 col-sm-6 col-12 ms-auto">
                    <div className="form-group">
                      <a className="btn btn-filters ms-auto">
                        <img
                          src="assets/img/icons/search-whites.svg"
                          alt="img"
                        />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {error ? (
              <div className="alert alert-danger text-center mt-5" role="alert">
                <i className="fa fa-exclamation-triangle fa-5x"></i>
                <h5>{error.message}</h5>
              </div>
            ) : minerals.results.length === 0 ? (
              <div className="text-center">
                <i style={{ fontSize: "50px" }} className="ti-home"></i>
                <h2>No Data Found</h2>
              </div>
            ) : (
              <div ref={divRef} className="table-responsive">
                <table className="table datanew">
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>Product</th>
                      <th>Supplier Name</th>
                      <th>Type</th>
                      <th>Unit</th>
                      <th>Product Price</th>
                      <th>Status</th>
                      <th>Created On</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {minerals.results.map((item: TMineral, index: number) => {
                      var firstName = item.supplier.first_name.match(
                        /^([a-zA-Z])[^\s]*(.*)$/
                      );
                      var lastName = item.supplier.last_name.match(
                        /^([a-zA-Z])[^\s]*(.*)$/
                      );
                      var abbr_name = firstName![1] + " " + lastName![1];
                      return (
                        <tr key={index}>
                          <td>{index + 1}</td>
                          <td className="text-capitalize">
                            <div className="row">
                              <div className="col-md-4 mr-0 pr-0">
                                <a href="">
                                  <img
                                    className="product-imgs rounded"
                                    src={`${item.image}`}
                                    alt={item.productName}
                                  />
                                </a>
                              </div>
                              <div className="col-md-8 ml-0 pl-0">
                                <a href="">
                                  {item.productName.substring(0, 10)}
                                </a>
                              </div>
                            </div>
                          </td>
                          <td className="productimgname text-capitalize">
                            <a
                              href="javascript:void(0);"
                              className="product-imgs rounded"
                            >
                              {abbr_name}
                            </a>
                            <a href="javascript:void(0);">
                              {item.supplier.first_name}{" "}
                              {item.supplier.last_name}
                            </a>
                          </td>
                          <td className="text-capitalize">
                            {item.category.categoryName}
                          </td>
                          <td className="text-capitalize">{item.unit}</td>
                          <td>{Number(item.productPrice).toLocaleString()}</td>
                          <td className="text-capitalize">{item.status}</td>
                          <td>
                            {moment(item.created_at).format("Do MMMM, YYYY")}
                          </td>
                          <td>
                              <span>
                                <a className="me-3" onClick={() => handleShow(item)}>
                                  <img src={editIcon} alt="edit-mineral"/>
                                </a>
                              </span>
                          </td>
                          <EditMineralStatus
                            mineral={mineral === null ? item : mineral}
                            show={show}
                            setShow={setShow}
                            handleClose={handleClose}
                          />
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
                <Pagination
                  currentPage={currentPage}
                  lastPage={minerals.total_pages}
                  maxLength={7}
                  setCurrentPage={setCurrentPage}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default PendingMineralRequestPage;
