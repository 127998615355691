export const initialInspectionData = {
    tyres: "OK",
    jack_and_tools: "OK",
    triangles: "OK",
    head_lamps: "OK",
    interior_radio: "OK",
    exterior: "OK",
    engine_check: "OK",
    spare_wheel: "OK",
    gps_map: "OK",
    windscreen_front: "OK",
    windscreen_rear: "OK",
    wipers_front: "OK",
    wipers_rear: "OK",
    fender: "OK",
    side_mirrors: "OK",
    window_blinders: "OK",
    road_tax: "Valid",
    insurance: "Valid",
    fitness: "Valid",
    roof_top: "OK",
    dents_and_scratches: "None",
    mileage: "0",
  };