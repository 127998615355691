import React from "react";
import Button from "react-bootstrap/Button";
import { ICarBooking } from "../../../../redux/types/car_booking.type";
import { toast } from "react-toastify";
import moment from "moment";
import { useForm } from "react-hook-form";
import { ErrorWidget } from "../../../utils/error_widget";
import LoadingSpinner from "../../../../components/widgets/spinner";
import { useGetBookingQuery, useUpdateBookingMutation } from "../../../../redux/slices/car_booking_slice";
import BreadCrumbs from "../../../widgets/bread_crumbs";
import CarRentalActionModal from "./request_action_modal";
import CommentsAndSignatures from "./widgets/CommentsAndSignatures";
import Header from "./widgets/inspection_header";
import InspectionSection from "./widgets/InspectionSection";
import { initialInspectionData } from "../../../utils/car_inspection";
import InitialInspectionSection from "./widgets/initial_inspection";

const RentalDetails: React.FC = () => {
  const queryParams = new URLSearchParams(window.location.search);
  const rental_id = queryParams.get("rental_id");
 
 
  const {
    data: details,
    isLoading: isFetching,
    error,
  }: ICarBooking | any = useGetBookingQuery(Number(rental_id));

  if (isFetching) {
    return (
      <div style={{ marginTop: "20%" }} className="text-center">
        <LoadingSpinner />
      </div>
    );
  }

  // const [uponDeparture, setUponDeparture] = React.useState(initialInspectionData);
  // const [uponReturn, setUponReturn] = React.useState(initialInspectionData);

console.log(details.upon_departure);

  // let returnDate = new Date(item.client_pickup_date_time);

  // returnDate.setDate(returnDate.getDate() + item.days);
  return (
    <>
      <div className="content">
        <BreadCrumbs
          title={"Car Rental Request Details"}
          subTitle={"View/Manage Car Rental Request Details"}
        />
        {error ? (
          <ErrorWidget error={error} />
        ) : (
          <>
          <Header detail={details} />
            <div className="row">
              <div className="col-md-5">
                <h4 className="text-center">Car Details</h4>
                <h6 className="text-capitalize">Car: <b>{details.car.car_model}</b></h6><br />
                <h6>Number Plate: <b>{details.car.number_plate?.toLocaleUpperCase()}</b></h6><br />
                <h6 className="text-capitalize">
                  Transmission: <b>{details.car.gear_type}</b>
                </h6><br />
                <h6>Seats: <b>{details.car.seats}</b></h6><br />
                <h6>Speedometer: <b>{details.car.speedometer}</b></h6><br />
                <h6>Fuel Tank Litres: <b>{details.car.fuel_litres}</b>L</h6><br />
                <h6>
                  Local Rental Price: ZMW <b>{details.car.local_price.toLocaleString()}</b> Per
                  Day
                </h6><br />
                <h6>
                  Outside Town Rental Price: ZMW <b>{details.car.outside_town.toLocaleString()}</b> Per
                  Day
                </h6><br />
                STATUS: {details.car.is_available ? (
                  <span className="badges bg-lightgreen text-white">
                    AVAILABLE
                  </span>
                ) : (
                  <span className="badges bg-lightred text-white">
                    NOT AVAILABLE
                  </span>
                )}
              </div>
              <div className="col-md-7">
                <h4 className="text-center mb-4">Client Details</h4>
                <h6 className="text-capitalize">
                  Name:{" "}
                  <b>
                    {details.client.first_name} {details.client.last_name}{" "}
                  </b>
                </h6>
                <h6>
                  E-mail: <b>{details.client.email}</b>
                </h6>
                <h6>Phone Number: <b>{details.phone_number}</b></h6>
                <div className="row">
                    <div className="col-md-6">
                      <h5>Client NRC</h5>
                      <img style={{width: '100vw', height: '20vh'}} 
                      src={`${process.env.REACT_APP_API_URL}${details.client?.user_profile?.client_selfie_nrc}`} alt="nrc" />
                    </div>
                    <div className="col-md-6">
                      <h5>Driving License</h5>
                      <img style={{width: '100vw', height: '20vh'}} 
                      src={`${process.env.REACT_APP_API_URL}${details.client?.user_profile?.client_selfie_license}`} alt="license" />
                    </div>
                  </div>
                <h4 className="text-center mb-4 mt-4">Booking Details</h4>
                <div className="row">
                  <div className="col-md-6">
                    <h6>Paid: <b>ZMW{details.rental_price.toLocaleString()}</b></h6>
                    <h6>Days: <b>{details.days}</b></h6>
                  
                <h6 className="">
                  Status:{" "}
                  <span
                    className={`badges ${
                      details.status === "PENDING"
                        ? "bg-lightred"
                        : "bg-lightgreen"
                    } text-white`}>
                    {details.status}
                  </span>{" "}
                </h6>
                  </div>
                  <div className="col-md-6">
                      <div className="row mb-5">
                        <div className="col-md-6">
                          <h6>Pick Up Date</h6>
                          <small className="text-capitalize"><b>{moment(details.client_pickup_date_time).format("Do MMM YYYY HH:mm a")}</b></small>
                        </div>
                        <div className="col-md-6">
                          <h6>Return Date</h6>
                          <small className="text-capitalize"><b>{moment(details.client_return_date_time).format("Do MMM YYYY HH:mm a")}</b></small>
                        </div>
                        <div className="col-md-6">
                          <h6>Pick Up Location</h6>
                          <small className="text-capitalize"><b>{details.client_pickup_location}</b></small>
                        </div>
                        <div className="col-md-6">
                          <h6>Return Location</h6>
                          <small className="text-capitalize"><b>{details.client_return_location}</b></small>
                        </div>
                      </div>
                    
                  </div>
                </div>
              </div>
            </div>
            {details.vehicle_request_inspection === null ? null : <div className="row mb-3">
              <div className="col-md-6">
                <InitialInspectionSection
                  title="Upon Departure"
                  data={details.vehicle_request_inspection?.upon_departure}
                />
              </div>
              <div className="col-md-6">
                <InitialInspectionSection
                  title="Upon Return"
                  data={details.vehicle_request_inspection?.upon_return}
                />
              </div>
            </div>}
            
            
            <CarRentalActionModal detail={details} /> 
          </>
        )}
      </div>
    </>
  );
};

export default RentalDetails;
