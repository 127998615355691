import moment from "moment";
import React from "react";
import IPropertyPayments from "../../../models/payments_model";
import PaymentActionModal from "./action_modal";

interface IPayment{
  payments: IPropertyPayments[]
}

const RentalHistory: React.FC<IPayment> = ({payments}) => {
  return (
    <div className="content">
      <div className="table-responsive mt-3">
        <h3>Payment History</h3>
      <table className="table">
        <thead>
          <th>#</th>
          <th>Tenant</th>
          <th>Rent Amount</th>
          <th>Amount Paid</th>
          <th>Balance</th>
          <th>Date Paid</th>
          <th>Status</th>
          <th>Action</th>
        </thead>
        <tbody>
          {payments.map((item, index) =>{
            return <tr key={index}>
            <td>{index + 1}</td>
            <td className="capitalize">{item.client.first_name} {item.client.last_name} </td>
            <td>ZMW {Number(item.property.rent_amount).toLocaleString()}</td>
            <td>ZMW {Number(item.paid_amount).toLocaleString()}</td>
            <td>ZMW {Number(item.property.rent_amount - item.paid_amount).toLocaleString()}</td>
            <td>{moment(item.created_at).format("MMMM Do, YYYY")}</td>
            <td className="capitalize">{item.status}</td>
            <td className="text-center">
            {item.status !== "PENDING" ? <span>
              No Action
            </span> : <PaymentActionModal payment={item} />}  
            </td>
          </tr>
          })}
          
        </tbody>
      </table>
    </div>
    </div>
  );
};

export default RentalHistory;
