import { apiSlice } from "../services/apiSlice";
import { TMineral } from "../types/mineral.type";

export const mineralSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    createMineral: builder.mutation<TMineral, Partial<TMineral>>({
      query(data) {
        return {
          url: `/administrator/v1/everyday/admin_minerals/tech`,
          method: "POST",
          body: data,
        };
      },
      invalidatesTags: ["TMineral"],
      transformResponse: (result: { agri: TMineral }) =>
        result.agri,
    }),
    updateMineral: builder.mutation<TMineral, Partial<TMineral>& Pick<TMineral, 'id'>>({
      query: ({ id, ...patch }) => ({
        url: `/administrator/v1/everyday/admin_minerals/tech/${id}/`,
        method: "PATCH",
        body: patch,
      }),
      invalidatesTags: ["TMineral"],
      transformResponse: (response: { agri: TMineral }) =>
        response.agri,
    }),
    getMineral: builder.query<TMineral, number>({
      query: (id) => ({
        url: `/administrator/v1/everyday/admin_minerals/tech/${id}/`,
      }),
      providesTags: ["TMineral"],
    }),
    getMineralList: builder.query<TMineral[], any>({
      query: ({supplier, status, page, searchQuery}) => `/administrator/v1/everyday/admin_minerals/tech/?supplier__id=${supplier}&status=${status}&page=${page}&search=${searchQuery}`,
      // transformResponse: (res: TMineral[]) =>
      //   res.sort((a, b) => b.id - a.id),
      //   providesTags: (result) =>
      //   result ? result.map(({ id }) => ({ type: 'TMineral', id })) : ['TMineral'],
      // keepUnusedDataFor: 5,
    }),
    deleteMineral: builder.mutation<TMineral, number>({
      query: (id) => ({
        url: `/administrator/v1/everyday/admin_minerals/tech/${id}/`,
        method: "DELETE",
      }),
      invalidatesTags: ["TMineral"],
    }),
  }),
});
export const {
  useCreateMineralMutation,
  useDeleteMineralMutation,
  useGetMineralListQuery,
  useGetMineralQuery,
  useUpdateMineralMutation,
} = mineralSlice;
