import React from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { ICarBooking } from "../../../../redux/types/car_booking.type";
import moment from "moment";

interface ITransaction {
  item: ICarBooking;
  show: boolean;
  handleClose: () => void;
  // handleShow: (booking: ICarBooking) => void;
}

const TransactionModalDetails: React.FC<ITransaction> = ({
  item,
  show,
  handleClose,
}) => {
  return (
    <>
      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        scrollable
        size="xl"
        centered={true}
        keyboard={false}>
        <Modal.Header closeButton>
          <Modal.Title className="text-capitalize">
            {item.car.car_model}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <>
            <div className="row">
              <div className="col-md-5 shadow rounded">
                <h4 className="text-center">Car Details</h4>
                <h6 className="text-capitalize">Car: <b>{item.car.car_model}</b></h6><br />
                <h6>Number Plate: <b>{item.car.number_plate?.toLocaleUpperCase()}</b></h6><br />
                <h6 className="text-capitalize">
                  Transmission: <b>{item.car.gear_type}</b>
                </h6><br />
                <h6>Seats: <b>{item.car.seats}</b></h6><br />
                <h6>Speedometer: <b>{item.car.speedometer}</b></h6><br />
                <h6>Fuel Tank Litres: <b>{item.car.fuel_litres}</b>L</h6><br />
                <h6>
                  Local Rental Price: ZMW <b>{item.car.local_price.toLocaleString()}</b> Per
                  Day
                </h6><br />
                <h6>
                  Outside Town Rental Price: ZMW <b>{item.car.outside_town.toLocaleString()}</b> Per
                  Day
                </h6><br />
                STATUS: {item.car.is_available ? (
                  <span className="badges bg-lightgreen text-white">
                    AVAILABLE
                  </span>
                ) : (
                  <span className="badges bg-lightred text-white">
                    NOT AVAILABLE
                  </span>
                )}
              </div>
              <div className="col-md-7 shadow rounded">
                <h4 className="text-center mb-4">Client Details</h4>
                <h6 className="text-capitalize">
                  Name:{" "}
                  <b>
                    {item.client.first_name} {item.client.last_name}{" "}
                  </b>
                </h6>
                <h6>
                  E-mail: <b>{item.client.email}</b>
                </h6>
                <h6>Phone Number: <b>{item.phone_number}</b></h6>
                <div className="row">
                    <div className="col-md-6">
                      <h5>Client NRC</h5>
                      <img style={{width: '100vw', height: '20vh'}} src={item.client?.user_profile?.client_selfie_nrc} alt="nrc" />
                    </div>
                    <div className="col-md-6">
                      <h5>Driving License</h5>
                      <img style={{width: '100vw', height: '20vh'}} src={item.client?.user_profile?.client_selfie_license} alt="license" />
                    </div>
                  </div>
                <h4 className="text-center mb-4 mt-4">Booking Details</h4>
                <div className="row">
                  <div className="col-md-6">
                    <h6>Paid: <b>ZMW{item.rental_price.toLocaleString()}</b></h6>
                    <h6>Days: <b>{item.days}</b></h6>
                  
                <h6 className="">
                  Status:{" "}
                  <span
                    className={`badges ${
                      item.status === "PENDING"
                        ? "bg-lightred"
                        : "bg-lightgreen"
                    } text-white`}>
                    {item.status}
                  </span>{" "}
                </h6>
                  </div>
                  <div className="col-md-6">
                      <div className="row mb-5">
                        <div className="col-md-6">
                          <h6>Pick Up Date</h6>
                          <small className="text-capitalize"><b>{moment(item.client_pickup_date_time).format("Do MMM YYYY HH:mm a")}</b></small>
                        </div>
                        <div className="col-md-6">
                          <h6>Return Date</h6>
                          <small className="text-capitalize"><b>{moment(item.client_return_date_time).format("Do MMM YYYY HH:mm a")}</b></small>
                        </div>
                        <div className="col-md-6">
                          <h6>Pick Up Location</h6>
                          <small className="text-capitalize"><b>{item.client_pickup_location}</b></small>
                        </div>
                        <div className="col-md-6">
                          <h6>Return Location</h6>
                          <small className="text-capitalize"><b>{item.client_return_location}</b></small>
                        </div>
                      </div>
                    
                  </div>
                </div>

                
              </div>
            </div>
            <Modal.Footer>
              <Button
              
                variant="secondary"
                onClick={handleClose}>
                Close
              </Button>
              <Button type="submit" variant="primary">
                <i className="fa fa-print"></i> Print
              </Button>
            </Modal.Footer>
          </>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default TransactionModalDetails;
