import React, { useState } from "react";
import plusIcon from "../../../../../assets/img/icons/plus.svg";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { useRegisterMutation } from "../../../../../redux/slices/authApiSlice";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { FormInput } from "../../../../../components/molecules/form_input";
import { Spinner } from "react-bootstrap";
import { useRegister } from "../../../../../hooks";

type TProps = {
  title: string;
  accountType: string;
};

export type DataFormFields = {
  first_name: string;
  last_name: string;
  email: string;
  nationality: string;
  company_name: string;
  phoneNumber: string;
  user_type: string;
  password: string;
};

const CreateAccount: React.FC<TProps> = ({ title, accountType }) => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);



  const {
    isLoading,
    register,
    errors,
    onSubmit,
  } = useRegister({setShow: setShow, accountType: accountType});
  return (
    <>
      <Button className="btn btn-added" onClick={handleShow}>
        <img src={plusIcon} alt="img" /> {title}
      </Button>
      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        size="lg"
        scrollable={true}
        centered={true}
      >
        <Modal.Header closeButton>
          <Modal.Title>{title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={onSubmit}>
            <div className="row">
              <div className="col-md-4">
                <label htmlFor="name">First Name</label>
                <FormInput<DataFormFields>
                  id="first_name"
                  type="text"
                  name="first_name"
                  disabled={isLoading}
                  label="First Name"
                  placeholder="First Name"
                  className="mb-2"
                  register={register}
                  rules={{ required: "First Name is required." }}
                  errors={errors}
                />
              </div>
              <div className="col-md-4">
                <label htmlFor="price">Last Name</label>
                <FormInput<DataFormFields>
                  id="last_name"
                  type="text"
                  name="last_name"
                  disabled={isLoading}
                  label="Last Name"
                  placeholder="Last Name"
                  className="mb-2"
                  register={register}
                  rules={{ required: "Last Name is required." }}
                  errors={errors}
                />
              </div>
              <div className="col-md-4">
                <label htmlFor="price">Email</label>
                <FormInput<DataFormFields>
                  id="email"
                  type="text"
                  name="email"
                  disabled={isLoading}
                  label="Email"
                  placeholder="Email"
                  className="mb-2"
                  register={register}
                  rules={{ required: "Email is required." }}
                  errors={errors}
                />
              </div>
              <div className="col-md-4">
                <label htmlFor="phone_number">Phone Number</label>
                <FormInput<DataFormFields>
                  id="phoneNumber"
                  type="text"
                  name="phoneNumber"
                  label="Phone Number"
                  disabled={isLoading}
                  placeholder="Phone Number"
                  className="mb-2"
                  register={register}
                  rules={{ required: "Phone Number is required." }}
                  errors={errors}
                />
              </div>
              <div className="col-md-4">
                <label htmlFor="phone_number">Nationality</label>
                <FormInput<DataFormFields>
                  id="nationality"
                  type="text"
                  name="nationality"
                  label="Nationality"
                  disabled={isLoading}
                  placeholder="Nationality"
                  className="mb-2"
                  register={register}
                  rules={{ required: "Nationality is required." }}
                  errors={errors}
                />
              </div>
              <div className="col-md-4">
                <label htmlFor="phone_number">Company Name</label>
                <FormInput<DataFormFields>
                  id="company_name"
                  type="text"
                  name="company_name"
                  label="Company Name"
                  disabled={isLoading}
                  placeholder="Company Name"
                  className="mb-2"
                  register={register}
                  rules={{ required: "Company Name is required." }}
                  errors={errors}
                />
              </div>
            </div>

            <Modal.Footer>
              <Button variant="secondary" onClick={handleClose}>
                Cancel
              </Button>
              <Button disabled={isLoading} variant="primary" type="submit">
                {isLoading && <Spinner size="sm" />}{" "}
                <i className="fa fa-plus"></i> Create
              </Button>
            </Modal.Footer>
          </form>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default CreateAccount;
