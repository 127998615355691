import React from "react";
import {  Outlet } from "react-router-dom";
import { Navigate } from "react-router-dom";
import { useAppSelector } from "../hooks/redux-hooks";

const DefaultLayout = () => {
  const isAuthenticated = useAppSelector((state) => state.auth.isAuthenticated);

  React.useEffect(() => {

  }, [isAuthenticated, localStorage.getItem("access")])
  

  if (localStorage.getItem("access") !== null || isAuthenticated) {
    return <Navigate replace to={"/zm/authenticating"} />;
  }

  return (
    <>
      <Outlet />
    </>
  );
};

export default DefaultLayout;