import React from "react";
import editIcon from "../../../../assets/img/icons/edit-set.svg";
import { useAppSelector } from "../../../../hooks/redux-hooks";
import BreadCrumbs from "../../../widgets/bread_crumbs";

const Profile: React.FC = () => {
  const user = useAppSelector((state) => state.auth.user);
  return (
    <>
      <div className="content">
      <BreadCrumbs
        title={"Profile"}
        subTitle={"Manage Your Profile"}
      />
        <div className="card">
          <div className="card-body">
            <div className="profile-set">
              <div className="profile-head"></div>
              <div className="profile-top">
                <div className="profile-content">
                  <div className="profile-contentimg">
                    <img
                      src={require("../../../../assets/img/logo.jpg")}
                      alt="img"
                      id="blah"
                    />
                    <div className="profileupload">
                      <input type="file" id="imgInp" />
                      <a href="javascript:void(0);">
                        <img src={editIcon} alt="img" />
                      </a>
                    </div>
                  </div>
                  <div className="profile-contentname">
                    <h2 className="text-capitalize">{user?.first_name} {user?.last_name}</h2>
                    <h4>Updates Your Photo and Personal Details.</h4>
                  </div>
                </div>
                <div className="ms-auto">
                  <a href="javascript:void(0);" className="btn btn-submit me-2">
                    Save
                  </a>
                  <a href="javascript:void(0);" className="btn btn-cancel">
                    Cancel
                  </a>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-6 col-sm-12">
                <div className="form-group">
                  <label>First Name</label>
                  <input type="text" defaultValue={user?.first_name} placeholder="First Name" />
                </div>
              </div>
              <div className="col-lg-6 col-sm-12">
                <div className="form-group">
                  <label>Last Name</label>
                  <input type="text" defaultValue={user?.last_name} placeholder="Last Name" />
                </div>
              </div>
              <div className="col-lg-6 col-sm-12">
                <div className="form-group">
                  <label>Email</label>
                  <input type="text" defaultValue={user?.email} placeholder="E-mail" />
                </div>
              </div>
              <div className="col-lg-6 col-sm-12">
                <div className="form-group">
                  <label>Phone</label>
                  <input type="text" defaultValue={user?.user_profile?.phone_number} placeholder="Phone Number" />
                </div>
              </div>
              <div className="col-lg-12 col-sm-12">
                <div className="form-group">
                  <label>Password</label>
                  <div className="pass-group">
                    <input type="password" className="pass-input" />
                    <span className="fas toggle-password fa-eye-slash"></span>
                  </div>
                </div>
              </div>
              {/* <div className="col-12">
                <a href="javascript:void(0);" className="btn btn-submit me-2">
                  Submit
                </a>
                <a href="javascript:void(0);" className="btn btn-cancel">
                  Cancel
                </a>
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Profile;
