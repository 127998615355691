import React from "react";
import user from "../../../assets/img/icons/users1.svg";

const UserMessage: React.FC = () => {
  return (
    <>
      <div className="dash-msg-inbox">
        <ul>
          <li>
            <a href="#">
              <div className="dash-msg-avatar">
                <img src={`${user}`} alt="" />
                <span className="_user_status online"></span>
              </div>

              <div className="message-by">
                <div className="message-by-headline">
                  <h5>John Doe</h5>
                  <span>36 min ago</span>
                </div>
                <p>Hello, I am a web designer with 5 year.. </p>
              </div>
            </a>
          </li>
        </ul>
      </div>
    </>
  );
};

export default UserMessage;
