import React, { useRef } from "react";
import { TInvestment } from "../../../../redux/types/investment.type";
import moment from "moment";
import { useGetInvestmentListQuery } from "../../../../redux/slices/investment_slice";
import LoadingSpinner from "../../../../components/widgets/spinner";
import BreadCrumbs from "../../../widgets/bread_crumbs";
import Pagination from "../../../pagination/pagination_items";
import { ErrorWidget } from "../../../utils/error_widget";
import Filters from "../../widgets/filters";

const InvestmentList: React.FC = () => {
  const divRef = useRef<HTMLDivElement>(null);
  const [query, setQuery] = React.useState("")
  const [currentPage, setCurrentPage] = React.useState(1);
  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value;
    setQuery(newValue);
  }
  const {
    data: projects,
    isLoading,
    error,
  }: TInvestment | any = useGetInvestmentListQuery({user: '', status: '',page: currentPage, searchQuery: query});

  if (isLoading) {
    return (
      <div style={{ marginTop: "20%" }} className="text-center">
        <LoadingSpinner />
      </div>
    );
  }
  const findDayDifference = (date1: any, date2: any) => {
    // always round down
    return Math.floor(Math.abs(date1 - date2) / (1000 * 60 * 60 * 24));
  };
  return (
    <div className="content">
      <BreadCrumbs
        title={"Investment List"}
        subTitle={"View/Manage Investment List"}
      />
      <div className="card">
      <div className="row">
        <div className="col-lg-6 col-sm-6 col-12 px-3 pt-3">
          <div className="form-group">
            <input
              className="form-control"
              value={query}
              onChange={onChange}
              type="search"
              name="search_project"
              id="search_project"
              placeholder="Search..."
            />
          </div>
        </div>
        <div className="col-lg-6 col-sm-6 col-12 px-3 pt-3">
          <Filters divRef={divRef} showSearch={false} query={query} onSearchChange={onChange} />
        </div>
        </div>
        <div className="card-body">
          {error ? (
           <ErrorWidget error={error}/>
          ) : projects.results.length === 0 ? (
            <center className="p-3">
              <i className="fa fa-calendar-day fa-5x"></i>
              <h4>
                <b>No Data Found</b>
              </h4>
            </center>
          ) : (
            <div ref={divRef} className="table-responsive">
              <table id="example" className="table table-striped data-table">
                <thead>
                  <tr>
                    <th>#</th>
                    <th>Project Name</th>
                    <th>Project Category</th>
                    <th>Goal Amount</th>
                    <th>Raised</th>
                    <th>Deadline</th>
                    <th>Days Remaining</th>
                    <th>Created On</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {projects.results.map((item: TInvestment, index: number) => (
                    <tr key={index}>
                      <td>{index+1}</td>
                      <td className="text-capitalized">{item.project_title.substring(0, 20)}...</td>
                      <td className="text-uppercase">
                        {item.project_category}
                      </td>
                      <td>ZMW {Number(item.project_amount).toLocaleString()}</td>
                      <td>ZMW {Number(item.total_donated_avg).toLocaleString()}</td>
                      <td>{moment(item.project_days).format("Do MMMM, YYYY")}</td>
                      <td>
                        {moment().format("YYYY-MM-D") === item.project_days ||
                        moment().format("YYYY-MM-D") > item.project_days ? (
                          <h6 className="text-warning">
                            <i className="fa fa-heart"></i> Closed
                          </h6>
                        ) : (
                          <small className="text-capitalize text-dark">
                            {findDayDifference(
                              new Date(moment().format(item.project_days)),
                              new Date(moment().format("YYYY-MM-D"))
                            )}{" "}
                            day(s) remaining
                          </small>
                        )}{" "}
                      </td>
                      <td>{moment(item.created_at).format("Do MMMM, YYYY")}</td>
                      <td>
                        <a
                          href={`/zm/dashboard/project_details/?project_id=${item.id}`}
                        >
                          <i className="fa fa-arrow-right"></i>
                        </a>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
              <Pagination
                currentPage={currentPage}
                lastPage={projects.total_pages}
                maxLength={7}
                setCurrentPage={setCurrentPage}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default InvestmentList;
