import React, { useEffect, useState } from 'react';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import axios from 'axios';

interface DataPoint {
  date: string;
  total_sales: number;
  sales: number;
  total_profit: number;
}

interface IProps{
    transaction_id?: any;
}

const MiningConstructionOrderChart: React.FC<IProps> = ({transaction_id}) => {
  const [data, setData] = useState<DataPoint[]>([]);
  const [timeFilter, setTimeFilter] = useState<string>('daily');
  const [statusFilter, setStatusFilter] = useState<string | null>(null);

  useEffect(() => {
    fetchData();
  }, [timeFilter, statusFilter]);

  const fetchData = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/administrator/v1/everyday/mining_construction_orders_aggregated_stats/`, {
        params: { time_filter: timeFilter, status: statusFilter, transaction_id: transaction_id}
      });
      setData(response.data);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  return (
    <div>
        <label>
          Time Filter:
          <select value={timeFilter} onChange={(e) => setTimeFilter(e.target.value)}>
            <option value="daily">Daily</option>
            <option value="weekly">Weekly</option>
            <option value="monthly">Monthly</option>
            <option value="yearly">Yearly</option>
          </select>
        </label>

        <label>
          Status Filter:
          <select value={statusFilter || ''} onChange={(e) => setStatusFilter(e.target.value || null)}>
            <option value="">All</option>
            <option value="PENDING">Pending</option>
            <option value="CONFIRMED">Confirmed</option>
            <option value="REJECTED">Rejected</option>
            <option value="CANCELLED">Cancelled</option>
          </select>
        </label>

      <ResponsiveContainer width="100%" height={300}>
        <BarChart data={data} margin={{ top: 20, right: 30, left: 20, bottom: 5 }}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="date" />
          <YAxis />
          <Tooltip formatter={(value) => value.toLocaleString()} />
          <Legend />
          <Bar dataKey="sales" name="Sales" fill="#2bff00" />
          <Bar dataKey="total_sales" name="Profit" fill="#8A2BE2" />
          <Bar dataKey="total_profit" name="Profit" fill="#FF9933" />
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
};

export default MiningConstructionOrderChart;
