import { PropertyModel } from "../../../models/product_model";
import { RequestsModel } from "../../../models/requests_model";
import { apiSlice } from "../../services/apiSlice";

export const realEstateAPISlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    createRealEstate: builder.mutation<PropertyModel, Partial<PropertyModel>>({
      query(data) {
        return {
          url: `/realestate/v1/everyday/admin_properties/tech`,
          method: "POST",
          body: data,
        };
      },
      invalidatesTags: ["PropertyModel"],
      transformResponse: (result: { agri: PropertyModel }) =>
        result.agri,
    }),
    updateRealEstate: builder.mutation<PropertyModel, Partial<PropertyModel>& Pick<PropertyModel, 'id'>>({
      query: ({ id, ...patch }) => ({
        url: `/realestate/v1/everyday/admin_properties/tech/${id}/`,
        method: "PATCH",
        body: patch,
      }),
      invalidatesTags: ["PropertyModel"],
      transformResponse: (response: { agri: PropertyModel }) =>
        response.agri,
    }),
    getRealEstate: builder.query<PropertyModel, number>({
      query: (id) => ({
        url: `/realestate/v1/everyday/admin_properties/tech/${id}/`,
      }),
      providesTags: ["PropertyModel"],
    }),
    getRealEstateList: builder.query<PropertyModel[], any>({
      query: ({supplier, status, searchQuery, page}) => `/realestate/v1/everyday/admin_properties/tech/?status=${status}&search=${searchQuery}&page=${page}&user__id=${supplier}`,
      // transformResponse: (res: PropertyModel[]) =>
      //   res.sort((a, b) => b.id - a.id),
      //   providesTags: (result) =>
      //   result ? result.map(({ id }) => ({ type: 'PropertyModel', id })) : ['PropertyModel'],
      // keepUnusedDataFor: 5,
    }),
    getEstateCustomersList: builder.query<RequestsModel[], any>({
      query: ({supplier, page, searchQuery}) => `/realestate/v1/everyday/estate_customer_order/tech/?user__id=${supplier}&page=${page}&search=${searchQuery}`,
      // transformResponse: (res: RequestsModel[]) =>
      //   res.sort((a, b) => b.id - a.id),
      // providesTags: ["RequestsModel"],
      // keepUnusedDataFor: 5,
    }),
    deleteRealEstate: builder.mutation<PropertyModel, number>({
      query: (id) => ({
        url: `/realestate/v1/everyday/admin_properties/tech/${id}/`,
        method: "DELETE",
      }),
      invalidatesTags: ["PropertyModel"],
    }),
  }),
});
export const {
  useCreateRealEstateMutation,
  useDeleteRealEstateMutation,
  useGetRealEstateListQuery,
  useGetRealEstateQuery,
  useUpdateRealEstateMutation,
  useGetEstateCustomersListQuery,
} = realEstateAPISlice;
