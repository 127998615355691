import React, { useRef } from "react";
import Filters from "../../widgets/filters";
import OrderDetailIcon from "../../../../assets/img/icons/eye1.svg";
import editIcon from "../../../../assets/img/icons/edit.svg";
import dollarIcon from "../../../../assets/img/icons/dollar-square.svg";
import plusIcon from "../../../../assets/img/icons/plus-circle.svg";
import downloadIcon from "../../../../assets/img/icons/download.svg";
import deleteIcon from "../../../../assets/img/icons/delete1.svg";
import { useAppSelector } from "../../../../hooks/redux-hooks";

const ConstructionDeals: React.FC = () => {
  const divRef = useRef<HTMLDivElement>(null);
  const user = useAppSelector((state) => state.auth.user);
  const [query, setQuery] = React.useState("")
  const [currentPage, setCurrentPage] = React.useState(1);
  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value;
    setQuery(newValue);
  }
  return (
    <>
      <div className="content">
        <div className="page-header">
          <div className="page-title">
            <h4>Construction Orders List</h4>
            <h6>Manage your orders</h6>
          </div>
        </div>

        <div className="card">
          <div className="card-body">
            <Filters divRef={divRef} query={query} onSearchChange={onChange} />

            <div className="table-responsive">
              <table className="table datanew">
                <thead>
                  <tr>
                    <th>#</th>
                    <th>Product</th>
                    <th>Customer</th>
                    <th>Supplier</th>
                    <th>Date</th>
                    <th>Reference</th>
                    <th>Status</th>
                    <th>Qty</th>
                    <th>Total</th>
                    <th>Paid</th>
                    <th className="text-center">Action</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>1</td>
                    <td>Tractor</td>
                    <td>John Doe</td>
                    <td>RMF Constructions</td>
                    <td>19 Aug 2024</td>
                    <td>SL0103</td>
                    <td>
                      <span className="badges bg-lightgreen">Completed</span>
                    </td>
                    <td>1</td>
                    <td className="text-green">{Number(2800).toLocaleString()}</td>
                    <td>{Number(2800).toLocaleString()}</td>
                    <td className="text-center">
                      {user?.user_type === "ADMIN" ? (
                        <span className="badges bg-lightred">No Action</span>
                      ) : (
                        <a
                          className="action-set"
                          href="javascript:void(0);"
                          data-bs-toggle="dropdown"
                          aria-expanded="true"
                        >
                          <i
                            className="fa fa-ellipsis-v"
                            aria-hidden="true"
                          ></i>
                        </a>
                      )}

                      <ul className="dropdown-menu">
                        <li>
                          <a
                            href="/zm/dashboard/order_details"
                            className="dropdown-item"
                          >
                            <img
                              src={OrderDetailIcon}
                              className="me-2"
                              alt="img"
                            />
                            Order Detail
                          </a>
                        </li>
                        <li>
                          <a href="#" className="dropdown-item">
                            <img src={editIcon} className="me-2" alt="img" />
                            Edit Sale
                          </a>
                        </li>
                        <li>
                          <a
                            href="javascript:void(0);"
                            className="dropdown-item"
                            data-bs-toggle="modal"
                            data-bs-target="#showpayment"
                          >
                            <img src={dollarIcon} className="me-2" alt="img" />
                            Show Payments
                          </a>
                        </li>
                        <li>
                          <a
                            href="javascript:void(0);"
                            className="dropdown-item"
                            data-bs-toggle="modal"
                            data-bs-target="#createpayment"
                          >
                            <img src={plusIcon} className="me-2" alt="img" />
                            Create Payment
                          </a>
                        </li>
                        <li>
                          <a
                            href="javascript:void(0);"
                            className="dropdown-item"
                          >
                            <img
                              src={downloadIcon}
                              className="me-2"
                              alt="img"
                            />
                            Download pdf
                          </a>
                        </li>
                        <li>
                          <a
                            href="javascript:void(0);"
                            className="dropdown-item confirm-text"
                          >
                            <img src={deleteIcon} className="me-2" alt="img" />
                            Delete Sale
                          </a>
                        </li>
                      </ul>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ConstructionDeals;
