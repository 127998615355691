import React, { useRef } from "react";
import deleteIcon from "../../../../assets/img/icons/delete.svg";
import editIcon from "../../../../assets/img/icons/edit.svg";
import moment from "moment";
import Filters from "../../../admin/widgets/filters";
import { useAppSelector } from "../../../../hooks/redux-hooks";
import DeleteInvestmentProjectModal from "./delete_project_modal";
import AddProject from "./add_project";
import { TInvestment } from "../../../../redux/types/investment.type";
import { useGetInvestmentListQuery } from "../../../../redux/slices/investment_slice";
import Pagination from "../../../pagination/pagination_items";
import LoadingSpinner from "../../../../components/widgets/spinner";
import { ErrorWidget } from "../../../utils/error_widget";

const InvestmetHomePage: React.FC = () => {
  const divRef = useRef<HTMLDivElement>(null);
  const user = useAppSelector((state) => state.auth.user);
  const [showDelete, setShowDelete] = React.useState(false);
  const [deleteProject, setDeleteProject] = React.useState<TInvestment | any>();
  const [query, setQuery] = React.useState("")
  const [currentPage, setCurrentPage] = React.useState(1);
  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value;
    setQuery(newValue);
  }

  const handleCloseDelete = () => {
    setShowDelete(false);
  };

  const handleShowDelete = (project: TInvestment) => {
    setDeleteProject(project);
    setShowDelete(true);
  };

  const {
    data: projects,
    isLoading,
    error,
  }: TInvestment | any = useGetInvestmentListQuery({
    user: user?.id,
    status: "ACTIVE",
    page: currentPage, searchQuery: query
  });

  if (isLoading) {
    return (
      <div style={{ marginTop: "20%" }} className="text-center">
        <LoadingSpinner />
      </div>
    );
  }
  const findDayDifference = (date1: any, date2: any) => {
    // always round down
    return Math.floor(Math.abs(date1 - date2) / (1000 * 60 * 60 * 24));
  };
  return (
    <>
      <div className="content">
        <div className="page-header">
          <div className="page-title">
            <h4>Project List</h4>
            <h6>Manage your Projects</h6>
          </div>
          <div className="page-btn">
            <AddProject />
          </div>
        </div>

        <div className="card">
          <div className="card-body">
            <Filters divRef={divRef} query={query} onSearchChange={onChange}/>

            {error ? (
              <ErrorWidget error={error}/>
            ) : projects.results.length === 0 ? (
              <div className="text-center">
                <i style={{ fontSize: "50px" }} className="ti-home"></i>
                <h2>No Data Found</h2>
              </div>
            ) : (
              <div ref={divRef} className="table-responsive">
                <table className="table datanew">
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>Project Name</th>
                      <th>Project Category</th>
                      <th>Goal Amount</th>
                      <th>Raised</th>
                      <th>Days Remaining</th>
                      <th>Created On</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {projects.results.map((item: TInvestment, index: number) => {
                      return (
                        <>
                          <tr key={index}>
                            <td>{index + 1}</td>
                            <td className="text-capitalized">
                              {item.project_title.substring(0, 20)}...
                            </td>
                            <td className="text-uppercase">
                              <i className="fa fa-bookmark"></i>{" "}
                              {item.project_category}
                            </td>
                            <td>
                              ZMW {Number(item.project_amount).toLocaleString()}
                            </td>
                            <td>
                              ZMW{" "}
                              {Number(item.total_donated_avg).toLocaleString()}
                            </td>
                            <td>
                              {moment().format("YYYY-MM-D") ===
                                item.project_days ||
                              moment().format("YYYY-MM-D") >
                                item.project_days ? (
                                <h6 className="text-warning">
                                  <i className="fa fa-heart"></i> Closed
                                </h6>
                              ) : (
                                <small className="text-capitalize text-dark">
                                  {findDayDifference(
                                    new Date(
                                      moment().format(item.project_days)
                                    ),
                                    new Date(moment().format("YYYY-MM-D"))
                                  )}{" "}
                                  day(s) remaining
                                </small>
                              )}{" "}
                            </td>
                            <td>
                              {moment(item.created_at).format("Do MMMM, YYYY")}
                            </td>
                            <td>
                              <a
                                className="me-3"
                                href={`/zm/investment/project_details/?project_id=${item.id}`}
                              >
                                <img src={editIcon} alt="img" />
                              </a>
                              <a
                                className="me-3 confirm-text"
                                onClick={() => handleShowDelete(item)}
                              >
                                <img src={deleteIcon} alt="img" />
                              </a>
                            </td>
                          </tr>

                          <DeleteInvestmentProjectModal
                            project={
                              deleteProject === null ? item : deleteProject
                            }
                            show={showDelete}
                            handleClose={handleCloseDelete}
                            setShow={setShowDelete}
                          />
                        </>
                      );
                    })}
                  </tbody>
                </table>
                <Pagination
                currentPage={currentPage}
                lastPage={projects.total_pages}
                maxLength={7}
                setCurrentPage={setCurrentPage}
              />
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default InvestmetHomePage;
