import React from 'react'

function LockScreen() {
  return (
    <div className='lock-screen'>
      
      <div className="div-center">
      <h2 className='text-center text-white'>
        Lock Screen <br />
        <i className='fa fa-lock'></i>
        </h2>
      <form action="">
        <div className='form-group'>
          <input type='password' className='form-control ' placeholder='Password' />
        </div>
        
        <center>
        <div className='form-group'>
          <button type='submit' className='btn btn-primary'>
           <i className='fa fa-lock-open'></i> Unlock
          </button>
        </div>
        </center>
      </form>
      </div>
    </div>
  )
}

export default LockScreen
