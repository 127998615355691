import React from "react";
import RentalHistory from "./rental_history";
import { RequestsModel } from "../../../models/requests_model";
import { useGetApprovedRequestQuery } from "../../../redux/slices/property/property_requests_slice";
import IPropertyPayments from "../../../models/payments_model";
import { useGetPropertyPaymentsQuery } from "../../../redux/slices/property/property_payments_slice";

const RentDetails: React.FC = () => {
  const queryParams = new URLSearchParams(window.location.search);
  const requestID = queryParams.get("property");
  const {
    data: request,
    isLoading,
    isError,
    error,
  }: RequestsModel | any = useGetApprovedRequestQuery(Number(requestID));

  const {
    data: payments,
    isLoading: fetchingPayments,
  }: IPropertyPayments | any = useGetPropertyPaymentsQuery(requestID);

  if (isLoading) {
    return (
      <div style={{ marginTop: "20%" }} className="text-center">
        <i className="fa fa-spinner fa-spin fa-5x text-danger"></i>
      </div>
    );
  }
  if (isError) {
    console.log(error);
    return (
      <center>
        <div
          style={{ marginTop: "20%", width: "fit-content" }}
          className="alert alert-danger text-center"
          role="alert">
          {error.message}
        </div>
      </center>
    );
  }
  return (
    <div className="content">
      <div className="row">
        <div className="col-sm-12 col-md-6 col-xl-6">
          <div className="">
            <img
              className="img-responsive img-fluid rounded"
              src={`${process.env.REACT_APP_API_URL}${request.property.image}`}
              alt={request.property.propertyName}
            />
          </div>
          <div className="">
            <div className="title">
              <h4 className="capitalize">
                <a href="#">{request.property.propertyName}</a>
              </h4>
              <span className="capitalize">
                {request.property.propertyAddress}{" "}
              </span>
              <span className="table-property-price">
                ZMW{Number(request.property.propertyPrice).toLocaleString()} /
                Monthly
              </span>
            </div>
          </div>
        </div>
        <div className="col-sm-12 col-md-6 col-xl-6">
          <h3>Tenant Details</h3>
          <h6 className="capitalize">
            Name: {request.user.first_name} {request.user.last_name}{" "}
          </h6>
          <h6>E-mail: {request.user.email} </h6>
          <h6 className="mb-5">Phone Number: {request.phoneNumber}</h6>
          <blockquote>{request.notification}</blockquote>
        </div>
      </div>

      {fetchingPayments ? (
        <center>
          <i className="fa fa-spin fa-fast"></i>
        </center>
      ) : payments.length === 0 ? (
        <div className="alert alert-info text-center mt-5 mb-5" role="alert">
          <i className="fa fa-info-circle"></i> No Data Found
        </div>
      ) : (
        <RentalHistory payments={payments} />
      )}
    </div>
  );
};

export default RentDetails;
