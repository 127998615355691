import { TClientModel } from "./clients_model.type";

export type TConstruction = {
    id: number;
    supplier: TClientModel;
    construction_product_order: TConstructionMiningOrders[];
    productPrice: number;
    productName: string;
    productLabel: string;
    productUnit: string;
    product_type: string;
    status: string;
    description: string;
    thumbnail: string,
    category: string;
    quantity: number;
    product_unique_id: string,
    frequency: string,
    created_at: Date;
}


export type TConstructionMiningOrders = {
    id: number;
    customer: TClientModel;
    supplier: TClientModel;
    product: TConstruction;
    productPrice: number;
    deliveryFee: number;
    quantity: number;
    orderType: string;
    deliveryAddress: string;
    order_unique_id: string;
    orderid: string;
    status: string;
    delivered: boolean;
    created_at: Date;
}

export const frequency = [
    "HOURLY",
    "ONCE",
    "DAILY",
    "WEEKLY",
    "MONTHLY",
    "YEARLY",
    "SPECIAL",
]

export const productLabel = [
    "SALE",
    "RENT",
]