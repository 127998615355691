import React, { useRef } from 'react'
import deleteIcon from "../../../../../assets/img/icons/delete.svg";
import editIcon from "../../../../../assets/img/icons/edit.svg";
import moment from 'moment';
import Filters from '../../../../admin/widgets/filters';
import LoadingSpinner from '../../../../../components/widgets/spinner';
import BreadCrumbs from '../../../../widgets/bread_crumbs';
import { ErrorWidget } from '../../../../utils/error_widget';
import { useAppSelector } from '../../../../../hooks/redux-hooks';
import { useGetConstructionOrdersListQuery } from '../../../../../redux/slices/construction_orders_slice';
import { TConstructionMiningOrders } from '../../../../../redux/types/construction.type';
import Pagination from '../../../../pagination/pagination_items';

const CancelledConstructionOrdersList: React.FC = () => {
  const divRef = useRef<HTMLDivElement>(null);
  const user = useAppSelector((state) => state.auth.user);
  const [query, setQuery] = React.useState("")
  const [currentPage, setCurrentPage] = React.useState(1);
  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value;
    setQuery(newValue);
  }
    const {
      data: ordersList,
      isLoading,
      error,
    }: TConstructionMiningOrders | any = useGetConstructionOrdersListQuery({supplier: user?.id, status: "CONFIRMED", searchQuery: query,page: currentPage});
  
    if (isLoading) {
      return (
        <div style={{ marginTop: "20%" }} className="text-center">
          <LoadingSpinner />
        </div>
      );
    }
  return (
    <>
      <div className="content">
      <BreadCrumbs
        title={"Confirmed Sales"}
        subTitle={"View/Manage Confirmed Sales"}
      />
          <div className="card">
            <div className="card-body">
            <Filters divRef={divRef} query={query} onSearchChange={onChange} />
              {error ? (
              <ErrorWidget error={error}/>
            ) : ordersList.results.length === 0 ? (
              <div className="text-center">
                <i style={{ fontSize: "50px" }} className="ti-home"></i>
                <h2>No Data Found</h2>
              </div>
            ) :
              <div ref={divRef} className="table-responsive">
                <table className="table datanew">
                  <thead>
                    <tr>
                      <th>
                        #
                      </th>
                      <th>Customer Name</th>
                      <th>Reference</th>
                      <th>Date</th>
                      <th>Quantity</th>
                      <th>Paid</th>
                      <th>Status</th>
                      <th>Product</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    
                    {ordersList.results.map((item: TConstructionMiningOrders, index: number)=>{
                      return <tr key={index}>
                      <td>
                        {index+1}
                      </td>
                      <td className="text-bolds text-capitalize">{item.customer.first_name} {item.customer.last_name}</td>
                      <td>{item.order_unique_id}</td>
                      <td>{moment(item.created_at).format("Do MMM, YYYY")}</td>
                      <td>{item.quantity}</td>
                      <td>{Number(item.productPrice).toLocaleString()}</td>
                      <td>
                        <span className="badges bg-lightgreen text-capitalize">{item.status}</span>
                      </td>
                      <td><span className="text-capitalize">{item.product.productName.substring(0, 10)}</span></td>
                      <td>
                        <a className="me-3" href={`/zm/dashboard/${user?.user_type}/order_details/?order_id=${item.id}`}>
                          <img src={editIcon} alt="img" />
                        </a>
                        <a className="me-3 confirm-text" href="javascript:void(0);">
                          <img src={deleteIcon} alt="img" />
                        </a>
                      </td>
                    </tr>
                    })}
                    
                  </tbody>
                </table>
                <Pagination
                  currentPage={currentPage}
                  lastPage={ordersList.total_pages}
                  maxLength={7}
                  setCurrentPage={setCurrentPage}
                />
              </div>
}
            </div>
          </div>
        </div>
    </>
  )
}

export default CancelledConstructionOrdersList
