import React from "react";
import plusIcon from "../../../../assets/img/icons/plus.svg";
import searchIcon from "../../../../assets/img/icons/search.svg";
import pdf from "../../../../assets/img/icons/pdf.svg";
import excel from "../../../../assets/img/icons/excel.svg";
import printer from "../../../../assets/img/icons/printer.svg";
import deleteIcon from "../../../../assets/img/icons/delete.svg";
import editIcon from "../../../../assets/img/icons/edit.svg";

const CurrencySettings: React.FC = () => {
  return (
    <div className="content">
      <div className="page-header">
        <div className="page-title">
          <h4>Currency Settings</h4>
          <h6>Manage Currency Settings</h6>
        </div>
        <div className="page-btn">
          <a
            className="btn btn-added"
            data-bs-toggle="modal"
            data-bs-target="#addpayment"
          >
            <img src={plusIcon} alt="img" className="me-1" />
            Add New Currency
          </a>
        </div>
      </div>

      <div className="card">
        <div className="card-body">
          <div className="table-top">
            <div className="search-set">
              <div className="search-input">
                <a className="btn btn-searchset">
                  <img src={searchIcon} alt="img" />
                </a>
              </div>
            </div>
            <div className="wordset">
                <ul>
                  <li>
                    <a
                      data-bs-toggle="tooltip"
                      data-bs-placement="top"
                      title="pdf"
                    >
                      <img src={pdf} alt="img" />
                    </a>
                  </li>
                  <li>
                    <a
                      data-bs-toggle="tooltip"
                      data-bs-placement="top"
                      title="excel"
                    >
                      <img src={excel} alt="img" />
                    </a>
                  </li>
                  <li>
                    <a
                      data-bs-toggle="tooltip"
                      data-bs-placement="top"
                      title="print"
                    >
                      <img src={printer} alt="img" />
                    </a>
                  </li>
                </ul>
              </div>
          </div>
          <div className="table-responsive">
            <table className="table datanew">
              <thead>
                <tr>
                  <th>
                   #
                  </th>
                  <th>Currency name</th>
                  <th>Currency code</th>
                  <th>Currency symbol</th>
                  <th>Status</th>
                  <th className="text-end">Action</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    1
                  </td>
                  <td>Zambian Kwacha</td>
                  <td>ZMW</td>
                  <td>ZMW</td>
                  <td>
                    <div className="status-toggle d-flex justify-content-between align-items-center">
                      <input
                        type="checkbox"
                        id="user1"
                        className="check"
                        checked={true}
                      />
                      <label htmlFor="user1" className="checktoggle">
                        checkbox
                      </label>
                    </div>
                  </td>
                  <td className="text-end">
                   <span>
                        <a className="me-3" href="#">
                          <img src={editIcon} alt="img" />
                        </a>
                        <a
                          className="me-3 confirm-text"
                          href="javascript:void(0);"
                        >
                          <img src={deleteIcon} alt="img" />
                        </a>
                      </span>
                  </td>
                </tr>
                <tr>
                  <td>
                    2
                  </td>
                  <td>US dollar</td>
                  <td>USD</td>
                  <td>$</td>
                  <td>
                    <div className="status-toggle d-flex justify-content-between align-items-center">
                      <input
                        type="checkbox"
                        id="user2"
                        className="check"
                        checked={false}
                      />
                      <label htmlFor="user2" className="checktoggle">
                        checkbox
                      </label>
                    </div>
                  </td>
                  <td className="text-end">
                   <span>
                        <a className="me-3" href="#">
                          <img src={editIcon} alt="img" />
                        </a>
                        <a
                          className="me-3 confirm-text"
                          href="javascript:void(0);"
                        >
                          <img src={deleteIcon} alt="img" />
                        </a>
                      </span>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CurrencySettings;
