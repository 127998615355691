import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { User } from '../types/user';

export interface AuthState {
	isAuthenticated: boolean;
	token: string | null;
	isLoading: boolean;
	user?: User | null;
}

const initialState = {
	isAuthenticated: false,
	token: localStorage.getItem("access"),
	isLoading: true,
	user: localStorage.getItem("userInfo")
    ? JSON.parse(localStorage.getItem("userInfo") as string)
    : null,
} as AuthState;

const authSlice = createSlice({
	name: 'auth',
	initialState,
	reducers: {
		setAuth: state => {
			state.isAuthenticated = true;
			state.token = localStorage.getItem("access");
		},
		logout: state => {
			state.isAuthenticated = false;
			state.token = null;
			localStorage.removeItem("userInfo");
			localStorage.removeItem("access");
			localStorage.removeItem("refresh");
		},
		finishInitialLoad: state => {
			state.isLoading = false;
		},
		profile: (state, action: PayloadAction<User>) => {
			state.user = action.payload;
		},
	},
});

export const { setAuth, profile, logout, finishInitialLoad} = authSlice.actions;
export default authSlice.reducer;