import React from "react";
import { ICarRental } from "../../../../redux/types/car_rental.type";
import CreateCar from "./create_car";
import { useAppSelector } from "../../../../hooks/redux-hooks";
import CarDetailsModal from "./car_details_modal";
import LoadingSpinner from "../../../../components/widgets/spinner";
import { useGetAdminCarsQuery } from "../../../../redux/slices/admin_hire_slice";
import Pagination from "../../../pagination/pagination_items";
import { ErrorWidget } from "../../../utils/error_widget";

function CarSection() {
  const [showDetailsModal, setShowDetailsModal] = React.useState(false);
  const [details, setDetails] = React.useState<ICarRental | null>(null);
  const [status, setStatus] = React.useState("")
  const [query, setQuery] = React.useState("")
  const [currentPage, setCurrentPage] = React.useState(1);
  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value;
    setQuery(newValue);
  }
  // DETAILS MODAL
  const handleShow = (item: ICarRental) => {
    setDetails(item);
    setShowDetailsModal(true);
  };
  const handleCloseDetailsModel = () => {
    setShowDetailsModal(false);
  };
  const user = useAppSelector((state) => state.auth.user);
  const {
    data: cars,
    isLoading,
    error,
  }: ICarRental | any = useGetAdminCarsQuery({
    company: user?.id,
    status: status,
    searchQuery: query, 
    page: currentPage,
    label: ""
  });

  if (isLoading) {
    return (
      <div style={{ marginTop: "10%" }} className="text-center">
        <LoadingSpinner />
      </div>
    );
  }

  return (
    <>
      <div className="content">
        <div className="page-header">
          <div className="page-title">
            <h4>Car Rentals List</h4>
            <h6>Manage your Car Rentals</h6>
          </div>
          <div className="page-btn">
          <CreateCar user_id={user?.id} />
          </div>
        </div>
        <div className="row">
          {error ? (
            <ErrorWidget error={error} />
          ) : cars.results.length === 0 ? (
            <center>
              <div className="alert alert-info custom-length" role="alert">
                <i className="fa fa-car fa-5x"></i>
                <h1>No Cars Found</h1>
              </div>
            </center>
          ) : (
            <>
            {cars.results.map((car: ICarRental, index: number) => (
              <div key={index} className="col-md-4 mb-3 shadow">
                <div className="img">
                  <img
                  style={{height: '200px'}}
                    className="img-fluid rounded"
                    src={`${car.car_images[0]?.image}`}
                    alt=""
                  />
                </div>
                <div className="about-info">
                  <h6>
                    <span className="text-capitalize"> {car.car_model} </span>
                  </h6>
                  <p className="about-text">
                    {car.description.substring(0, 100)}...
                  </p>
                  <div className="row">
                    <div className="col-md-4">
                      <p>Seats:</p>
                      <p>Music:</p>
                      <p>Brand:</p>
                    </div>
                    <div className="col-md-8">
                      <p className="text-capitalize">{car.seats} seats</p>
                      <p>{car.music_types}</p>
                      <p className="text-uppercase">
                        {car.car_brand.brand_name}
                      </p>
                    </div>
                  </div>
                  <div className="pull-right mb-3">
                    <button
                      onClick={() => handleShow(car)}
                      className="btn btn-sm btn-info">
                      <i className="fa fa-eye"></i> Details
                    </button>
                    <a
                      href={`/zm/car_dashboard/edit_car/?car_id=${car.id}`}
                      className="btn btn-sm btn-primary">
                      <i className="fa fa-pencil"></i> Edit
                    </a>
                  </div>
                </div>
                <CarDetailsModal
                  show={showDetailsModal}
                  item={details === null ? car : details}
                  handleClose={handleCloseDetailsModel}
                />
              </div>
            ))
          }
            <Pagination
              currentPage={currentPage}
              lastPage={cars.total_pages}
              maxLength={7}
              setCurrentPage={setCurrentPage}
            />
            </>
            
          )}
        </div>
      </div>
      
    </>
  );
}

export default CarSection;
