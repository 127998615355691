import React from "react";

type ErrorProps = {
  error: any;
};

// Error widget to display error messages
export const ErrorWidget: React.FC<ErrorProps> = ({ error }) => {
  if (error) {
      if ("data" in error) {
        return (
          <>
            <center>
              <div className="alert alert-danger text-center mt-5" role="alert">
                <i className="fa fa-exclamation-triangle fa-5x"></i>
                <h5>{error.data?.detail}</h5>
              </div>
            </center>
          </>
        );
      } else if ("error" in error) {
        return (
          <>
            <center>
              <div className="alert alert-danger text-center mt-5" role="alert">
                <i className="fa fa-exclamation-triangle fa-5x"></i>
                <h5>{error.error}</h5>
              </div>
            </center>
          </>
        );
      }
    }
      return (
        <>
          <center>
            <div className="alert alert-danger text-center mt-5" role="alert">
              <i className="fa fa-exclamation-triangle fa-5x"></i>
              <h5>{error.message}</h5>
            </div>
          </center>
        </>
      );
  
};
