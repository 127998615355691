import React from "react";
import HouseForm from "./house_form";
import FormLandForm from "./land_farm_";
import BreadCrumbs from "../../widgets/bread_crumbs";

const AddProperty: React.FC = () => {
  const [propertyType, setPropertyType] = React.useState("house")

  const filterPropertyType = (type: string) =>{
    setPropertyType(type);
  }

  return (
    <div className="content">
      <BreadCrumbs title={"Property Listing"} subTitle={"Add new property listing"} />
      {/* <!-- ============================ User Dashboard ================================== --> */}
      <section className="gray pt-0 pb-5">
        <div className="col-xl-12 col-lg-12 col-md-12">
          <div className="dashboard-body">
            <div className="dashboard-wraper">
              <div className="row">
                <div onClick={() => filterPropertyType("house")} className="col-md-2 btn btn-primary m-2">
                  House
                </div>
                <div onClick={() => filterPropertyType("land_farm")} className="col-md-2 btn btn-info m-2">
                  Farm/Land
                </div>
                {/* <!-- Submit Form --> */}
                {propertyType === "house" ? <HouseForm /> : <FormLandForm />}
              </div>
            </div>
            {/* <!-- row --> */}
          </div>
        </div>
      </section>
      {/* <!-- ============================ User Dashboard End ================================== --> */}
    </div>
  );
};

export default AddProperty;
