import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { Form, Spinner } from "react-bootstrap";
import { FormInput } from "../../../../components/molecules/form_input";
import { FormTextarea } from "../../../../components/molecules/form_textarea";
import { frequency, productLabel, TConstruction } from "../../../../redux/types/construction.type";
import { useUpdateConstructionMutation } from "../../../../redux/slices/construction_slice";

export type DataFormFields = {
  supplier: string;
  productName: string;
  productPrice: string;
  thumbnail: File;
  description: string;
  category: string;
  product_type: string;
  quantity: number
};

type TProps = {
  construction: TConstruction;
  show: boolean;
  handleClose: () => void;
  setShow: (show: boolean) => void;
};

const EditConstruction: React.FC<TProps> = ({
  construction,
  show,
  handleClose,
  setShow,
}) => {
  const [file, setFile] = useState<File | any>();

  const [updateAgriculture, { isLoading }] = useUpdateConstructionMutation();

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  const onSubmit = handleSubmit(async (data) => {
    updateAgriculture({
      id: construction.id,
      ...data,
    })
      .unwrap()
      .then((res) => {
        reset();
        setShow(false);
        toast.success("Product Updated Successfully");
      })
      .catch((err) => {
        toast.error("Error: " + err.data["detail"]);
      });
  });

  return (
    <>
      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        centered
        scrollable
        size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title>Edit Product</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={onSubmit} encType="multipart/form-data">
          <div className="row">
              <div className="col-md-4">
                <label htmlFor="name">Product Name</label>
                <FormInput
                  id="productName"
                  type="text"
                  name="productName"
                  disabled={isLoading}
                  defaultValue={construction?.productName}
                  label="Product Name"
                  placeholder="Product Name"
                  className="mb-2"
                  register={register}
                  rules={{ required: "Product Name is required." }}
                  errors={errors}
                />
              </div>
              <div className="col-md-4">
                <label htmlFor="image">Product Image</label>
                <FormInput
                  id="thumbnail"
                  type="file"
                  name="thumbnail"
                  label="Image"
                  disabled={isLoading}
                  accept="image/*"
                  placeholder="Image"
                  className="mb-2"
                  register={register}
                  rules={{
                    required: "Image is required.",
                    onChange: (event: React.ChangeEvent<HTMLInputElement>) => {
                      if (event.target.files && event.target.files[0]) {
                        setFile(event.target.files[0]);
                      }
                    },
                  }}
                  errors={errors}
                />
              </div>
              <div className="col-md-4">
                <label htmlFor="quantity">Quantity</label>
                <FormInput
                  id="quantity"
                  type="text"
                  name="quantity"
                  disabled={isLoading}
                  defaultValue={construction?.quantity}
                  label="quantity"
                  placeholder="quantity"
                  className="mb-2"
                  register={register}
                  rules={{ required: "quantity is required." }}
                  errors={errors}
                />
              </div>
              <div className="col-md-4">
                <label htmlFor="price">Product Price</label>
                <FormInput
                  id="productPrice"
                  type="text"
                  step="0.01"
                  name="productPrice"
                  disabled={isLoading}
                  defaultValue={construction?.productPrice}
                  label="Product Price"
                  placeholder="Product Price"
                  className="mb-2"
                  register={register}
                  rules={{ required: "Product Price is required." }}
                  errors={errors}
                />
              </div>
              
              <div className="col-md-4">
                <label htmlFor="productLabel">Product Label</label>
                <Form.Select {...register("productLabel", { required: true })} name="productLabel" defaultValue={construction?.productLabel} className="form-control">
                    {productLabel.map((item, index)=>{
                        return <option key={index} value={item}>{item}</option>
                    })}
                </Form.Select>
              </div>
              <div className="col-md-4">
                <label htmlFor="Frequncy">Frequency</label>
                <Form.Select {...register("frequency", { required: true })} name="frequency" defaultValue={construction?.frequency} className="form-control">
                    {frequency.map((item, index)=>{
                        return <option key={index} value={item}>{item}</option>
                    })}
                </Form.Select>
              </div>

              <div className="col-md-12">
                <label htmlFor="description">Description</label>
                <FormTextarea
                  id="description"
                  name="description"
                  disabled={isLoading}
                  defaultValue={construction?.description}
                  label="description"
                  placeholder="Enter Description"
                  rows={5}
                  cols={12}
                  className="form-control"
                  register={register}
                  rules={{ required: "Description is required." }}
                  errors={errors}
                />
              </div>
            </div>

            <Modal.Footer>
              <Button variant="secondary" onClick={handleClose}>
                Cancel
              </Button>
              <Button disabled={isLoading} variant="primary" type="submit">
                {isLoading && <Spinner size="sm" />}{" "}
                <i className="fa fa-plus"></i> Update
              </Button>
            </Modal.Footer>
          </form>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default EditConstruction;
