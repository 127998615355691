import { CreateRequestsModel, RequestsModel } from "../../../models/requests_model";
import { apiSlice } from "../../services/apiSlice";


export const propertyRequests = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    sendBuyRentRequest: builder.mutation<
      CreateRequestsModel, Partial<CreateRequestsModel>>({
      query(data) {
        return {
          url: `/realestate/v1/everyday/send_request/tech/`,
          method: "POST",
          body: data,
        };
      },
      invalidatesTags: ["CreateRequestsModel"],
      transformResponse: (result: { branch: CreateRequestsModel }) =>
        result.branch,
    }),
    updateRequest: builder.mutation<RequestsModel, Partial<RequestsModel>& Pick<RequestsModel, 'id'>>({
        query: ({ id, ...patch }) =>({
            url: `/realestate/v1/everyday/send_request/tech/${id}/`,
            method: "PATCH",
            body: patch,
        }),
        invalidatesTags: ["RequestsModel"],
        transformResponse: (response: { data: RequestsModel }) => response.data,
        transformErrorResponse: (
          response: { status: string | number },
        ) => response.status,
        
      }),
    getRequest: builder.query<RequestsModel, any>({
      query: (id) => ({
        url: `/realestate/v1/everyday/send_request/tech/${id}/`,
      }),
      providesTags: ["RequestsModel"],
    }),
    getRequests: builder.query<RequestsModel[], void>({
      query: (uid) => `/realestate/v1/everyday/send_request/tech/`,
      transformResponse: (res: RequestsModel[]) =>
        res.sort((a, b) => b.id - a.id),
      providesTags: ["RequestsModel"],
      // keepUnusedDataFor: 5,
    }),
    getApprovedRequests: builder.query<RequestsModel[], void>({
      query: (uid) => `/realestate/v1/everyday/rented_properties/tech/`,
      transformResponse: (res: RequestsModel[]) =>
        res.sort((a, b) => b.id - a.id),
      providesTags: ["RequestsModel"],
      // keepUnusedDataFor: 5,
    }),
    getApprovedRequest: builder.query<RequestsModel[], any>({
      query: (id) => `/realestate/v1/everyday/rented_properties/tech/${id}/`,
      providesTags: ["RequestsModel"],
      // keepUnusedDataFor: 5,
    }),
    deleteRequest: builder.mutation<RequestsModel, number>({
      query: (id) => ({
        url: `/realestate/v1/everyday/send_request/tech/${id}/`,
        method: "DELETE",
      }),
      invalidatesTags: ["RequestsModel"],
    }),
  }),
});
export const {
  useDeleteRequestMutation,
  useGetRequestQuery,
  useGetRequestsQuery,
  useSendBuyRentRequestMutation,
  useUpdateRequestMutation,
  useGetApprovedRequestsQuery,
  useGetApprovedRequestQuery,
} = propertyRequests;
