import React from 'react'
import dash1 from "../../../assets/img/icons/dash1.svg";
import dash2 from "../../../assets/img/icons/dash2.svg";
import dash3 from "../../../assets/img/icons/dash3.svg";
import dash4 from "../../../assets/img/icons/dash4.svg";
import { ICarBooking } from '../../../redux/types/car_booking.type';
import { ICarRental } from '../../../redux/types/car_rental.type';
import { TMineralOrder } from '../../../redux/types/mineral_order.type';
import { TMineral } from '../../../redux/types/mineral.type';

interface IWelcomeDisplay{
  totalAmount: number;
  totalConfirmed: number;
  reservations: TMineralOrder[];
  minerals: TMineral[];
  customers: TMineralOrder[];
  confirmedReservations: TMineralOrder[]
}

const WelcomeDisplay: React.FC<IWelcomeDisplay> = ({totalAmount, reservations, minerals, customers, confirmedReservations, totalConfirmed}) => {
  return (
    <div className='container mt-4'>
      <div className="row">
          <div className="col-lg-4 col-sm-6 col-12 shadow border-0 rounded-3 mb-4">
            <div className="dash-widget">
              <div className="dash-widgetimg">
                <span>
                  <img src={dash1} alt="img" />
                </span>
              </div>
              <div className="dash-widgetcontent">
                <h5>
                  <span className="counters" data-count={reservations.length}>
                    {reservations.length}
                  </span>
                </h5>
                <h6>Pending Sales</h6>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-sm-6 col-12 shadow border-0 rounded-3 mb-4">
            <div className="dash-widget dash1">
              <div className="dash-widgetimg">
                <span>
                  <img src={dash2} alt="img" />
                </span>
              </div>
              <div className="dash-widgetcontent">
                <h5> 
                  <span className="counters" data-count={totalAmount}>
                   ZMW {totalAmount.toLocaleString()}
                  </span>
                </h5>
                <h6>Total Pending Sales</h6>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-sm-6 col-12 shadow border-0 rounded-3 mb-4">
            <div className="dash-widget dash3">
              <div className="dash-widgetimg">
                <span>
                  <img src={dash4} alt="img" />
                </span>
              </div>
              <div className="dash-widgetcontent">
                <h5>
                  <span className="counters" data-count={totalConfirmed.toLocaleString()}>
                  ZMW {totalConfirmed.toLocaleString()} 
                  </span>
                </h5>
                <h6>Total Sale Amount</h6>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-sm-6 col-12 d-flex">
            <div className="dash-count">
              <div className="dash-counts">
                <h4>{minerals.length}</h4>
                <h5>Minerals</h5>
              </div>
              <div className="dash-imgs">
                <i className="fa fa-gem"></i>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-sm-6 col-12 d-flex">
            <div className="dash-count das2">
              <div className="dash-counts">
                <h4>{customers.length}</h4>
                <h5>Customers</h5>
              </div>
              <div className="dash-imgs">
                <i className="fa fa-users"></i>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-sm-6 col-12 d-flex">
            <div className="dash-count das3">
              <div className="dash-counts">
                <h4>{confirmedReservations.length}</h4>
                <h5>Total Confirmed Sales</h5>
              </div>
              <div className="dash-imgs">
                <i className="fa fa-check"></i>
              </div>
            </div>
          </div>
        </div>
    </div>
  )
}

export default WelcomeDisplay
