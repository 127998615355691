import React from "react";
import { ICarBooking } from "../../../../../redux/types/car_booking.type";

type TDetailProps = {
  detail: ICarBooking;
};

const Header: React.FC<TDetailProps> = ({ detail }) => {
  return (
    <header className="mb-3">
      <h1 className="text-capitalize text-center"><b>{detail.company.company_name}</b></h1>
      <div className="row">
        <div className="col-md-4"><h6>Vehicle Reg. No: <b>{detail.car.number_plate}</b></h6></div>
        <div className="col-md-4"><h6>Vehicle Type: <b>{detail.car.car_model}</b></h6></div>
        <div className="col-md-4">Date: _______________</div>
      </div>
    </header>
  );
};

export default Header;
