import React from 'react';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from 'recharts';


interface IBranchCharts{
    width: number,
    height: number,
    data: any,
}
const BranchCharts: React.FC<IBranchCharts> = ({width, height, data}) => {
  return (
    <ResponsiveContainer width="100%" height={250}>
    <BarChart
      data={data}
      margin={{
        top: 20,
        right: 30,
        left: 20,
        bottom: 5,
      }}
    >
      <CartesianGrid strokeDasharray="3 3" />
      <XAxis dataKey="name" />
      <YAxis />
      <Tooltip />
      <Legend />
      <Bar dataKey="sales" fill="#8884d8" /> {/* Sales Bar in Purple */}
      <Bar dataKey="profit" fill="#FFA500" /> {/* Profit Bar in Orange */}
    </BarChart>
    </ResponsiveContainer>
  );
};

export default BranchCharts;