import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import plusIcon from "../../../../../assets/img/icons/plus.svg";
import imageUpload from "../../../../../assets/img/icons/upload.svg";
import httpDashboardServices from "../../../../services/http.category.services";


interface FormValues {
  fetchData: any;
}

function AddMainCategory() {
  const [file, setFile] = useState<File>();
  const [show, setShow] = useState(false);
  const [isLoading, setIsLoading] = useState(false)
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  // const [createMineralCategory, { isLoading }] = useCreateMainCategoryMutation();

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  const onSubmit = async (data: any) => {
    setIsLoading(true);
    await httpDashboardServices.createMineralCategory({'image': file, 'categoryName': data.categoryName})
      .then((res: any) => {
        setIsLoading(false);
        reset();
        setShow(false);
        toast.success("Added Successfully");
      })
      .catch((err: any) => {
        setIsLoading(false);
        console.error(err.message);
        toast.error("Failed to add: " + err.error);
      });
  };

  return (
    <>
      <div className="page-btn">
        <button onClick={handleShow} className="btn btn-added">
          <img src={plusIcon} className="me-1" alt="img" />
          Add Category
        </button>
      </div>

      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        centered={true}
        keyboard={false}>
        <Modal.Header closeButton>
          <Modal.Title>Add Main Category</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="card">
              <div className="card-body">
                <div className="row">
                  <div className="col-lg-12 col-sm-12 col-12">
                    <div className="form-group">
                      <label>Category Name</label>
                      <input
                        type="text"
                        disabled={isLoading}
                        {...register("categoryName", { required: true })}
                        name="categoryName"
                        placeholder="Category Name"
                        className={`form-control ${
                          errors.categoryName ? "border-danger" : ""
                        }`}
                      />
                      {errors.categoryName && (
                        <p className="text-danger">
                          Category Name is required.
                        </p>
                      )}
                    </div>
                  </div>
                  <div className="col-lg-12">
                    <div className="form-group">
                      <label> Product Image</label>
                      <div
                        className={`form-control image-upload ${
                          errors.categoryName ? "border-danger" : ""
                        }`}>
                        <input
                          type="file"
                          accept="image/*"
                          disabled={isLoading}
                          {...register("image", {
                            required: true,
                            onChange: (
                              event: React.ChangeEvent<HTMLInputElement>
                            ) => {
                              if (event.target.files && event.target.files[0]) {
                                setFile(event.target.files[0]);
                              }
                            },
                          })}
                          name="image"
                        />
                        <div className="image-uploads">
                          <img
                            style={{ width: "100%", height: "100px" }}
                            src={
                              file !== undefined
                                ? URL.createObjectURL(file)
                                : imageUpload
                            }
                            alt="img"
                          />
                          <h4 className="mt-4">Drag and drop a file to upload</h4>
                        </div>
                        {errors.image && (
                          <p className="text-danger">
                            Image is required.
                          </p>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <Modal.Footer>
              <Button
                disabled={isLoading}
                variant="secondary"
                onClick={handleClose}>
                Close
              </Button>
              <Button disabled={isLoading} type="submit" variant="primary">
                {isLoading && (
                  <i className="fa fa-spinner fa-spin text-danger"></i>
                )}{" "}
                Submit
              </Button>
            </Modal.Footer>
          </form>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default AddMainCategory;
